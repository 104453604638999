import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { MutationHookOptions } from "@apollo/client";
import Form from "../Forms/Form";
import {
  useCreateVariableMutation,
  useDeleteVariablesMutation,
  useUpdateVariableMutation,
  Variable,
  VariableWhereInput,
} from "../../generated/graphql";
import { JSONSchema7 } from "json-schema";

interface IRowData {
  id: string;
}
declare type StringOnly<T> = Extract<keyof T, string>;
// declare type RowId = IRowData["id"];

export type RowData = {
  [key in StringOnly<IRowData>]: any;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface Label<T> {
  id: StringOnly<T>;
  label: string;
  renderField?: (value: any, rowId: string) => React.ReactNode;
  disableSort?: boolean;
  onTableCellClick?: (rowId: string, tableCellValue: any) => void;
}

interface HeadCell<T> {
  disablePadding: boolean;
  id: StringOnly<T>;
  label: string;
  numeric: boolean;
  disableSort?: boolean;
}

function genHeadCells<T>(labels: Label<T>[], select?: boolean): HeadCell<T>[] {
  let firstCell = true;
  let result: HeadCell<T>[] = [];
  labels.forEach(({ id, label, disableSort }) => {
    if (firstCell && select) {
      firstCell = false;
      result.push({
        id,
        numeric: false,
        disablePadding: true,
        label,
        disableSort,
      });
    } else {
      result.push({
        id,
        numeric: false,
        disablePadding: false,
        label,
        disableSort,
      });
    }
  });
  return result;
}

interface EnhancedTableProps<T> {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof T;
  rowCount: number;
  labels: Label<T>[];
}
function SimpleTableHead<T>(
  props: Pick<
    EnhancedTableProps<T>,
    "classes" | "onRequestSort" | "order" | "orderBy" | "rowCount" | "labels"
  >
) {
  const { classes, order, orderBy, rowCount, onRequestSort, labels } = props;
  const createSortHandler = (property: keyof T & string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };
  const headCells: HeadCell<T>[] = genHeadCells(labels);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={"th"}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={
              headCell.disableSort
                ? undefined
                : orderBy === headCell.id
                ? order
                : false
            }
          >
            {headCell.disableSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    labels,
  } = props;
  const createSortHandler = (property: keyof T & string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };
  const headCells: HeadCell<T>[] = genHeadCells(labels, true);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

interface Actions {
  onEdit: () => void;
  onDelete: () => void;
  onCreate?: () => void;
}

interface EnhancedTableToolbarProps extends Actions {
  numSelected: number;
  title?: string;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title || ""}
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          <Tooltip title="Delete" onClick={props.onDelete}>
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {numSelected === 1 && (
            <Tooltip title="Edit" onClick={props.onEdit}>
              <IconButton aria-label="edit">
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          {props.onCreate && (
            <Tooltip title="Create new" onClick={props.onCreate}>
              <IconButton aria-label="create new">
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};
const hoverColor = "rgba(151,150,150,0.35)";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableRow: {
      "&:hover": {
        backgroundColor: `${hoverColor} !important`,
      },
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export function GenericTable<T extends RowData>(props: {
  sortOnKey: StringOnly<T>;
  onRowClick?: (id: string) => void;
  rows: T[];
  labels: Label<T>[];
  title?: string;
  getTableRowBackground?: (row: T) => string;
  sort?: "asc" | "desc";
  backgroundColor?: string;
}) {
  const classes = useStyles();
  const { rows, labels, sortOnKey, sort } = props;
  const [order, setOrder] = React.useState<Order>(sort ?? "asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>(sortOnKey);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={
          props.backgroundColor
            ? { backgroundColor: props.backgroundColor }
            : undefined
        }
      >
        <Typography
          // className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title || ""}
        </Typography>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <SimpleTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              labels={labels}
            />
            <TableBody>
              {
                // @ts-ignore TODO Get comparator does not work, but it is functional so probably type error
                stableSort<T>(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`table-row-${row.id}`}
                        className={classes.tableRow}
                        style={
                          props.getTableRowBackground
                            ? {
                                backgroundColor: props.getTableRowBackground(
                                  row
                                ),
                              }
                            : undefined
                        }
                        onClick={
                          props.onRowClick
                            ? () => props.onRowClick!(row.id)
                            : undefined
                        }
                      >
                        {labels.map(
                          ({ id, renderField, onTableCellClick }, index) => {
                            let body: any = renderField
                              ? renderField(row[id], row.id)
                              : row[id];

                            if (Array.isArray(body)) {
                              // Its a list!
                              body = body.join("; ");
                            }
                            return (
                              <TableCell
                                key={`table-cell-${row.id}-${index}`}
                                style={
                                  onTableCellClick
                                    ? { cursor: "pointer" }
                                    : undefined
                                }
                                onClick={(e) => {
                                  if (onTableCellClick) {
                                    onTableCellClick(row.id, row[id]);
                                  }
                                }}
                              >
                                {body}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

interface TableProps<T> {
  sortOnKey: StringOnly<T>;
  rows: T[];
  labels: Label<T>[];
  onDelete: (id: T[]) => boolean;
  onEdit: (id: T) => boolean;
  onCreate?: () => void;
  title?: string;
}

export default function GenericSelectTable<T extends RowData>(
  props: TableProps<T>
) {
  const classes = useStyles();
  const { rows, labels, sortOnKey } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>(sortOnKey);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          onDelete={() =>
            props.onDelete(rows.filter((row) => selected.includes(row.id)))
          }
          onEdit={() => {
            if (selected.length > 0)
              props.onEdit(rows.find((row) => row.id === selected[0])!);
          }}
          onCreate={props.onCreate}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              labels={labels}
            />
            <TableBody>
              {
                // @ts-ignore TODO Get comparator does not work, but it is functional so probably type error
                stableSort<T>(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`table-row-${row.id}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        {labels.map(({ id }, index) => {
                          let body: any = row[id];
                          if (Array.isArray(body)) {
                            // Its a list!
                            body = body.join("; ");
                          }
                          return (
                            <TableCell
                              padding={index === 0 ? "none" : undefined}
                              key={`table-cell-${row.id}-${index}`}
                            >
                              {body.toString()}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

export const GenericDialog = <
  T extends any,
  M extends MutationHookOptions
>(props: {
  handleClose: () => void;
  open: boolean;
  hookMutation: any;
  isCreateMutation?: boolean;
  isDeleteMutation?: boolean;
  mapFormDataToMutationArgs?: (formData: T) => M;
  formData?: T;
  schema: JSONSchema7;
  uiSchema?: JSONSchema7;
  children?: React.ReactNode;
}) => {
  const { hookMutation, open, isCreateMutation, isDeleteMutation } = props;
  const [
    editOrCreate,
    { data, loading, error, called, client },
  ] = hookMutation();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (!open && submitted && data) {
      setSubmitted(false);
    }
  }, [open, submitted]);
  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isCreateMutation
          ? "Maak nieuwe aan"
          : isDeleteMutation
          ? "Verwijder"
          : "Pas gegevens aan"}
      </DialogTitle>
      <DialogContent>
        {error && <p>Oeps er ging iets mis</p>}
        {submitted && !error && <p>Gelukt!</p>}
        {!loading && !error && !submitted && (
          <>
            {props.children}
            <Form
              formData={props.formData}
              schema={props.schema}
              uiSchema={props.uiSchema}
              onSubmit={async ({ formData }) => {
                setSubmitted(true);
                await editOrCreate(
                  props.mapFormDataToMutationArgs
                    ? props.mapFormDataToMutationArgs(formData)
                    : { variables: formData }
                );
                props.handleClose();
              }}
            />
          </>
        )}
        {loading && <CircularProgress />}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Sluit</Button>
      </DialogActions>
    </Dialog>
  );
};
