import React, { Component, useEffect, useState } from "react";
import {
  Calendar as Cal,
  momentLocalizer,
  ToolbarProps,
} from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { debounce } from "lodash";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import NewAppointmentFromTimeDialog, {
  RawAppointment,
} from "./Appointments/NewAppointmentFromTimeDialog";
import {
  GetAppointmentsBetweenTwoDatesQuery,
  useGetAppointmentsBetweenTwoDatesQuery,
  useGetCalendarsQuery,
  useUpdateAppointmentByIdMutation,
} from "../generated/graphql";
import { ArrayElement } from "../BasicTypes/basics";
import "moment/locale/nl-be";
import { CalendarEventModal } from "./Appointments/CalendarEventModal";
import { undefinedOrSetInput } from "./Forms/FormSchemas/PatientSchema";
import Toolbar from "@material-ui/core/Toolbar";
import { IconButton, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button/Button";

const messages = {
  // new
  allDay: "Hele dag",
  previous: "Vorige",
  next: "Volgende",
  today: "Vandaag",
  month: "Maand",
  week: "Week",
  day: "Dag",
  agenda: "Agenda",
  date: "Datum",
  time: "Tijd",
  event: "Afspraak",
};

type Events = GetAppointmentsBetweenTwoDatesQuery["appointmentsBetweenTwoDates"];
type Event = ArrayElement<Events>;

const localizer = momentLocalizer(moment);
// @ts-ignore version error? Types of property 'titleAccessor' are incompatible.
const DnDCalendar = withDragAndDrop<Event, any>(Cal);

export const startDate = moment();
startDate.subtract("2", "weeks");
export const endDate = moment();
endDate.add("1", "year");

const Calendar = () => {
  const [privateModeEnabled, setPrivateModeEnabled] = useState(false);
  const [events, setEvents] = useState<Events>([]);
  const [updateAppointment, updateResult] = useUpdateAppointmentByIdMutation();
  const { data: calendarQry } = useGetCalendarsQuery();
  const { data } = useGetAppointmentsBetweenTwoDatesQuery({
    variables: { startDate: startDate.toDate(), endDate: endDate.toDate() },
  });
  const [draggedEvent, setDraggedEvent] = useState<any>();
  const [newAppointmentModal, setNewAppointmentModalOpen] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState<undefined | Event>();
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [draft, setDraft] = useState<RawAppointment>({
    allDay: false,
    start: "",
    end: "",
  });

  function getEventColor(event: Event): { bg: string; fg: string } {
    const calendars = calendarQry?.calendars;
    if (!calendars) {
      return { bg: "grey", fg: "black" };
    }
    const linkedTo = calendars.find((o) => o.id === event.calendar?.id);
    if (linkedTo) {
      return { bg: linkedTo.bgColor, fg: linkedTo.fgColor };
    }
    return { bg: "grey", fg: "black" };
  }

  // function handleDragStart(event: Event) {
  //   setDraggedEvent(event);
  // }
  //
  // function dragFromOutsideItem() {
  //   return draggedEvent;
  // }
  //
  // function onDropFromOutside({ start, end, allDay }: any) {
  //   const event = {
  //     id: draggedEvent!.id,
  //     title: draggedEvent!.title,
  //     start,
  //     end,
  //     allDay,
  //   };
  //
  //   setDraggedEvent(null);
  //   moveEvent({ event, start, end } as any);
  // }

  async function moveEvent({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }: any) {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const nextEvents = events.map((existingEvent: any) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setEvents(nextEvents);

    await updateAppointment({
      variables: {
        id: event.id,
        data: {
          end: undefinedOrSetInput(end),
          start: undefinedOrSetInput(start),
        },
      },
    });

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  async function resizeEvent({ event, start, end }: any) {
    const nextEvents = events.map((existingEvent: any) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    setEvents(nextEvents);
    await updateAppointment({
      variables: {
        id: event.id,
        data: {
          end: undefinedOrSetInput(end),
          start: undefinedOrSetInput(start),
        },
      },
    });
  }

  function newEvent(event: any) {
    setNewAppointmentModalOpen(true);
    setDraft({
      allDay: event.slots.length == 1,
      start: event.start,
      end: event.end,
    });
  }

  useEffect(() => {
    if (data) {
      setEvents(
        data.appointmentsBetweenTwoDates
          .filter((item) => !item.cancelled)
          .map((item) => ({
            ...item,
            //calendarTitle: privateModeEnabled ? "Afspraak" : item.calendarTitle,
            // Important! dates must be Date object to prevent other errors!
            start: new Date(item.start),
            end: new Date(item.end),
          }))
      );
    }
    // privateModeEnabled
  }, [data]);

  return (
    <>
      <CalendarEventModal
        open={!!appointmentModal}
        event={appointmentModal}
        handleClose={async () => {
          setAppointmentModal(undefined);
        }}
      />
      <NewAppointmentFromTimeDialog
        open={newAppointmentModal}
        handleClose={async (event) => {
          if (event) {
            setNewAppointmentModalOpen(false);
          } else {
            setNewAppointmentModalOpen(false);
          }
        }}
        appointment={draft!}
      />
      <Button
        variant={"outlined"}
        onClick={() => setPrivateModeEnabled(!privateModeEnabled)}
      >{`Zet privé modus ${privateModeEnabled ? "uit" : "aan"}`}</Button>
      <DnDCalendar
        selectable
        culture={"nl-BE"}
        messages={messages}
        events={events}
        eventPropGetter={(event: Event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "lightgrey",
            color: "black",
            borderRadius: "0px",
            border: "none",
          };

          const { fg, bg } = getEventColor(event);
          newStyle.backgroundColor = bg;
          newStyle.color = fg;

          return {
            className: "",
            style: newStyle,
          };
        }}
        onEventDrop={moveEvent}
        resizable
        // TODO DISABLE UNTIL BUG IS FIXED!
        // onEventResize={resizeEvent}
        onSelectEvent={
          (event) => setAppointmentModal(event)
          // alert(
          //   `Actie uitvoeren bij klik op event: ${event.calendarDescription}`
          // )
        }
        // onDragStart={({ event }) => {
        //   handleDragStart(event);
        // }} // instead of log
        defaultView={"week"}
        defaultDate={new Date()}
        popup={true}
        // dragFromOutsideItem={
        //   displayDragItemInCell ? (dragFromOutsideItem as any) : null
        // }
        // onDropFromOutside={onDropFromOutside}
        localizer={localizer}
        formats={{ timeGutterFormat: "HH:mm" }}
        onSelectSlot={newEvent}
        step={15}
        startAccessor={(event) => event.start}
        endAccessor={(event) => event.end}
        allDayAccessor={(event) => event.start === event.end}
        titleAccessor={(event) =>
          `${privateModeEnabled ? "Afspraak" : event.calendarTitle}`
        }
        style={{ height: "80vh" }}
        min={new Date(0, 0, 0, 7, 0, 0)}
        max={new Date(0, 0, 0, 23, 0, 0)}
        // components={{ toolbar: CalendarToolbar }}
      />
    </>
  );
};

// const CalendarToolbar: React.Component<ToolbarProps> = ({
//   onNavigate,
//   views,
//   view,
//   label,
//   onView,
// }: ToolbarProps) => {
//   return (
//     <Toolbar>
//       <Typography style={{ textTransform: "capitalize", width: "100%" }}>
//         {label}
//       </Typography>
//       <div style={{ width: "100%", textAlign: "right" }}>
//         <IconButton onClick={() => onNavigate("PREV")}>
//           <button>prev</button>
//         </IconButton>
//         <IconButton>
//           <button onClick={() => onNavigate("NEXT")}>next</button>
//         </IconButton>
//         <FormControl style={{ marginLeft: 16 }}>
//           <Select value={view} onChange={console.log}>
//             {views.map((value) => (
//               <MenuItem value={value}>
//                 {JSON.stringify(messages[value])}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </div>
//     </Toolbar>
//   );
// };
export default Calendar;
