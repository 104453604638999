import React, { ElementType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { isLoggedIn } from "./config/accessToken";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {CircularProgress} from "@material-ui/core";




const LoadingFallback = () => {
    return <div>Loading fallback<br/><CircularProgress/></div>
}


const PrivateRoute = ({ component, ...args }: any ) => {

    const CastedComponent = component as ElementType;

    return <Route
        component={withAuthenticationRequired((args) => <Header><CastedComponent {...args}/></Header>, {
            onRedirecting: () => <LoadingFallback/>,
        })}
        {...args}
    />
}

interface Props extends RouteProps {}
const PrivateRouteOld = ({ component: Component, ...rest }: Props) => {
  const CastedComponent = Component as ElementType;
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <Header>
            <CastedComponent {...props} />
          </Header>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
