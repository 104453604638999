import {
  useCreateDiagnosisMutation,
  useCreateTreatmentMutation,
  useDiagnosisQuery,
  useTreatmentQuery,
  useUpdateDiagnosisMutation,
  useUpdateTreatmentMutation,
  useVariablesQuery,
  Variable,
} from "../../generated/graphql";
import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import Form from "../Forms/Form";
import { WidgetProps } from "@rjsf/core";
import { AutoCompleteEditableSelectInputWidget } from "../Forms/JsonSchemaFormWidgets/AutoCompleteEditableSelectInputWidget";
import { undefinedOrSetInput } from "../Forms/FormSchemas/PatientSchema";
import { Link } from "react-router-dom";
import {TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX} from "../../pages/variables/Variables";

interface Props {
  patientId: string;
}

const genOption = (id: string, name: string) => [
  id,
  { type: "string", title: name },
];

const genUiOption = (id: string, values?: string[]) => [
  id,
  {
    "ui:widget": (props: WidgetProps) => (
      <AutoCompleteEditableSelectInputWidget
        widgetProps={props}
        options={values || []}
        multilineInput={true}
      />
    ),
  },
];

function generateSchema(variables: any[], title: string, bigTextFields?: boolean) {
  const optionsRaw = variables.map(({ id, name }) => genOption(id, name));
  const uiRaw = variables.map(({ id, values }) => genUiOption(id, values));

  let options = {};
  let uiOptions = {};
  if (optionsRaw && uiRaw) {
    options = Object.fromEntries(optionsRaw);
    uiOptions = Object.fromEntries(uiRaw);
  }

  return {
    schema: {
      title,
      type: "object",
      properties: {
        ...options,
      },
    },
    uiSchema: uiOptions,
  };
}

interface DiagnosisTreatmentProps {
  patientId: string;
  variables: Variable[];
}
export const DiagnosisFields = (props: DiagnosisTreatmentProps) => {
  const { patientId, variables } = props;
  const [formData, setFormData] = useState<any>();
  const [createDiagnosis, res] = useCreateDiagnosisMutation();
  const diagnosisResult = useDiagnosisQuery({ variables: { patientId } });

  const [saveDiagnosis, diagnosisSaveResult] = useUpdateDiagnosisMutation();
  const updateDiagnosis = () => {
    diagnosisResult.refetch();
  };

  useEffect(() => {
    // Prevent double loading at beginning
    if (!formData) updateDiagnosis();
  }, [patientId]);

  useEffect(() => {
    console.log("Saved some data! ==> refetch");
    if (diagnosisSaveResult.data || res.data) {
      console.log("refetching...");
      updateDiagnosis();
    }
  }, [diagnosisSaveResult.data, res.data]);

  useEffect(() => {
    console.log("Diagnosis data changed");
    console.log(diagnosisResult.data?.diagnosis);

    if (diagnosisResult.data?.diagnosis) {
      // store in formdata
      console.log("Setting new data");
      const { diagnosis } = diagnosisResult.data.diagnosis;
      if (diagnosis) setFormData(JSON.parse(diagnosis));
    }
  }, [diagnosisResult.data]);

  if (diagnosisResult.loading || res.loading || diagnosisSaveResult.loading) {
    return <CircularProgress />;
  }

  const { schema, uiSchema } = generateSchema(variables || [], "Diagnose");

  return (
    <Form
      formData={formData}
      buttonName="Opslaan"
      schema={schema as any}
      uiSchema={uiSchema}
      onSubmit={async ({ formData }) => {
        if (diagnosisResult.data?.diagnosis) {
          await saveDiagnosis({
            variables: {
              data: {
                diagnosis: undefinedOrSetInput(JSON.stringify(formData)),
              },
              patientId,
            },
          }).then(console.log);
        } else {
          await createDiagnosis({
            variables: {
              data: {
                diagnosis: JSON.stringify(formData),
                patient: { connect: { id: patientId } },
              },
            },
          }).then(console.log);
        }
        updateDiagnosis();
      }}
    />
  );
};
export const TreatmentFields = (props: DiagnosisTreatmentProps) => {
  const { patientId, variables } = props;
  const [formData, setFormData] = useState<any>();
  const [createTreatment, res] = useCreateTreatmentMutation();
  const treatmentResult = useTreatmentQuery({ variables: { patientId } });

  const [saveTreatment, treatmentSaveResult] = useUpdateTreatmentMutation();
  const updateTreatment = () => {
    treatmentResult.refetch();
  };

  useEffect(() => {
    // Prevent double loading at beginning
    if (!formData) updateTreatment();
  }, [patientId]);

  useEffect(() => {
    console.log("Saved some data! ==> refetch");
    if (treatmentSaveResult.data || res.data) {
      console.log("refetching...");
      updateTreatment();
    }
  }, [treatmentSaveResult.data, res.data]);

  useEffect(() => {
    console.log("Treatment data changed");
    console.log(treatmentResult.data?.treatment);

    if (treatmentResult.data?.treatment) {
      // store in formdata
      console.log("Setting new data");
      const { treatment } = treatmentResult.data.treatment;
      console.log(treatment);
      setFormData(JSON.parse(treatment ?? "{}"));
    }
  }, [treatmentResult.data]);

  if (treatmentResult.loading || res.loading || treatmentSaveResult.loading) {
    return <CircularProgress />;
  }

  const { schema, uiSchema } = generateSchema(
    variables || [],
    "Behandelingsplan"
  );

  return (
    <Form
      buttonName="Opslaan"
      formData={formData}
      schema={schema as any}
      uiSchema={uiSchema}
      onSubmit={async ({ formData }) => {
        if (treatmentResult.data?.treatment) {
          await saveTreatment({
            variables: {
              data: {
                treatment: undefinedOrSetInput(JSON.stringify(formData)),
              },
              patientId,
            },
          }).then(console.log);
        } else {
          await createTreatment({
            variables: {
              data: {
                treatment: JSON.stringify(formData),
                patient: { connect: { id: patientId } },
              },
            },
          }).then(console.log);
        }
        updateTreatment();
      }}
    />
  );
};

export function sortVariables(partOf: string, data: any[]|undefined): any[]{

  const orderingOfIds = data?.find(o=>o.name.startsWith(TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX.concat(partOf)))?.values || []
  const variables = data?.filter((item) => item.partOf === partOf) || []
  const result = orderingOfIds.map((id: string)=>variables.find(v=>v.id == id))

  return result
}

export const DiagnosisTreatment = (props: Props) => {
  const { data } = useVariablesQuery();

  return (
    <>
      <Grid container spacing={5}>
        <Grid item sm={12}>
          <Link to={`/patient/report/${props.patientId}`}>
            Genereer verslag
          </Link>
        </Grid>
        <Grid item sm>
          <Paper style={{ padding: "3em" }}>
            <DiagnosisFields
              variables={
                sortVariables("Diagnose", data?.variables)
              }
              patientId={props.patientId}
            />
          </Paper>
        </Grid>
        <Grid item sm>
          <Paper style={{ padding: "3em" }}>
            <TreatmentFields
              patientId={props.patientId}
              variables={
                sortVariables("Behandelingsplan", data?.variables)
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
