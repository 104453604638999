import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Gender: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};

export type CalendarToken = {
  __typename?: 'CalendarToken';
  id: Scalars['String'];
  tokens: Scalars['String'];
  userId: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  options: Array<Scalars['String']>;
  paid?: Maybe<Scalars['DateTime']>;
  paymentId?: Maybe<Scalars['String']>;
  prices: Array<Scalars['Int']>;
  appointment?: Maybe<Appointment>;
};

export type FinancialPlan = {
  __typename?: 'FinancialPlan';
  options?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  patient: Patient;
};

export type City = {
  __typename?: 'City';
  id: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type School = {
  __typename?: 'School';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Dentist = {
  __typename?: 'Dentist';
  id: Scalars['String'];
  person: Person;
};

export type SearchPatient = {
  __typename?: 'SearchPatient';
  patientId: Scalars['Int'];
  name: Scalars['String'];
  fname: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
};

export type EmailPayload = {
  __typename?: 'EmailPayload';
  sent: Scalars['Boolean'];
};

export type Diagnosis = {
  __typename?: 'Diagnosis';
  patient: Patient;
  id: Scalars['String'];
  diagnosis?: Maybe<Scalars['String']>;
};

export type Treatment = {
  __typename?: 'Treatment';
  id: Scalars['String'];
  patient: Patient;
  treatment?: Maybe<Scalars['String']>;
};

export type Variable = {
  __typename?: 'Variable';
  name: Scalars['String'];
  id: Scalars['String'];
  partOf: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type PublicAppointment = {
  __typename?: 'PublicAppointment';
  date: Scalars['DateTime'];
  id: Scalars['String'];
};

export type PublicAppointmentProposal = {
  __typename?: 'PublicAppointmentProposal';
  id: Scalars['String'];
  fname: Scalars['String'];
  name: Scalars['String'];
  proposedAppointments: Array<PublicAppointment>;
};

export type CustomAppointmentCreateInput = {
  patientId?: Maybe<Scalars['String']>;
  proposal?: Maybe<Scalars['Boolean']>;
  proposalId?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  dateForAppointmentStart: Scalars['DateTime'];
  dateForAppointmentEnd: Scalars['DateTime'];
  allDay?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AppointmentProposal = {
  __typename?: 'AppointmentProposal';
  appointments: Array<Appointment>;
  id: Scalars['String'];
  publicNote: Scalars['String'];
};


export type AppointmentProposalAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<AppointmentWhereUniqueInput>;
  after?: Maybe<AppointmentWhereUniqueInput>;
};

export type Calendar = {
  __typename?: 'Calendar';
  name: Scalars['String'];
  appointments: Array<Appointment>;
  googleCalId: Scalars['String'];
  bgColor: Scalars['String'];
  fgColor: Scalars['String'];
  id: Scalars['String'];
};


export type CalendarAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<AppointmentWhereUniqueInput>;
  after?: Maybe<AppointmentWhereUniqueInput>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  id: Scalars['String'];
  verified: Scalars['Boolean'];
};

export enum AuthType {
  Email = 'Email',
  Facebook = 'Facebook',
  Google = 'Google',
  Apple = 'Apple'
}




export type Patient = {
  __typename?: 'Patient';
  id: Scalars['String'];
  insRefund: Scalars['Boolean'];
  dentist?: Maybe<Dentist>;
  person: Person;
  disease?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
  boxId?: Maybe<Scalars['String']>;
  financialPlan?: Maybe<FinancialPlan>;
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['String'];
  fname: Scalars['String'];
  name: Scalars['String'];
  gender?: Maybe<Scalars['Gender']>;
  address: Address;
  bdate: Scalars['DateTime'];
  language?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  phones: Array<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  counter: Scalars['Int'];
  patient: Patient;
  id: Scalars['String'];
  reoccurringData?: Maybe<Scalars['String']>;
  privateNote?: Maybe<Scalars['String']>;
  ignore: Scalars['Boolean'];
  calendarEventId?: Maybe<Scalars['String']>;
  cancelled: Scalars['Boolean'];
  publicNote?: Maybe<Scalars['String']>;
  calendar?: Maybe<Calendar>;
  appointmentProposal?: Maybe<AppointmentProposal>;
  calendarDescription?: Maybe<Scalars['String']>;
  calendarTitle: Scalars['String'];
  invoice?: Maybe<Invoice>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  country?: Maybe<Country>;
  houseNumbering?: Maybe<Scalars['String']>;
  sndLine?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  phone?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Gender']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOnePerson: Person;
  updateOnePerson?: Maybe<Person>;
  deleteOnePerson?: Maybe<Person>;
  createOnePatient: Patient;
  updateOnePatient?: Maybe<Patient>;
  deleteOnePatient?: Maybe<Patient>;
  createOneDentist: Dentist;
  deleteOneDentist?: Maybe<Dentist>;
  updateOneDentist?: Maybe<Dentist>;
  createOneSchool: School;
  deleteOneSchool?: Maybe<School>;
  createOneCity: City;
  deleteOneCity?: Maybe<City>;
  upsertOneCity: City;
  createOneCountry: Country;
  deleteOneCountry?: Maybe<Country>;
  createOneVariable: Variable;
  deleteOneVariable?: Maybe<Variable>;
  deleteManyVariable: AffectedRowsOutput;
  updateOneVariable?: Maybe<Variable>;
  upsertOneVariable: Variable;
  createOneTreatment: Treatment;
  createOneDiagnosis: Diagnosis;
  updateOneDiagnosis?: Maybe<Diagnosis>;
  updateOneTreatment?: Maybe<Treatment>;
  updateOneFinancialPlan?: Maybe<FinancialPlan>;
  createOneFinancialPlan: FinancialPlan;
  createBoxId: Scalars['String'];
  createOneAppointmentProposal: AppointmentProposal;
  deleteOneAppointmentProposal?: Maybe<AppointmentProposal>;
  updateOneAppointmentProposal?: Maybe<AppointmentProposal>;
  deleteOneAppointment?: Maybe<Appointment>;
  updateOneAppointment?: Maybe<Appointment>;
  selectAppointmentFromProposal: Scalars['Boolean'];
  createNewAppointmentForPatient: Appointment;
  cancelAppointment: Scalars['Boolean'];
  createGoogleCalendars: Scalars['Boolean'];
  sendEmail: Scalars['Boolean'];
  sendAppointmentConfirmation: Scalars['Boolean'];
  sendAppointmentProposal: Scalars['Boolean'];
};


export type MutationCreateOnePersonArgs = {
  data: PersonCreateInput;
};


export type MutationUpdateOnePersonArgs = {
  data: PersonUpdateInput;
  where: PersonWhereUniqueInput;
};


export type MutationDeleteOnePersonArgs = {
  where: PersonWhereUniqueInput;
};


export type MutationCreateOnePatientArgs = {
  data: PatientCreateInput;
};


export type MutationUpdateOnePatientArgs = {
  data: PatientUpdateInput;
  where: PatientWhereUniqueInput;
};


export type MutationDeleteOnePatientArgs = {
  where: PatientWhereUniqueInput;
};


export type MutationCreateOneDentistArgs = {
  data: DentistCreateInput;
};


export type MutationDeleteOneDentistArgs = {
  where: DentistWhereUniqueInput;
};


export type MutationUpdateOneDentistArgs = {
  data: DentistUpdateInput;
  where: DentistWhereUniqueInput;
};


export type MutationCreateOneSchoolArgs = {
  data: SchoolCreateInput;
};


export type MutationDeleteOneSchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type MutationCreateOneCityArgs = {
  data: CityCreateInput;
};


export type MutationDeleteOneCityArgs = {
  where: CityWhereUniqueInput;
};


export type MutationUpsertOneCityArgs = {
  where: CityWhereUniqueInput;
  create: CityCreateInput;
  update: CityUpdateInput;
};


export type MutationCreateOneCountryArgs = {
  data: CountryCreateInput;
};


export type MutationDeleteOneCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type MutationCreateOneVariableArgs = {
  data: VariableCreateInput;
};


export type MutationDeleteOneVariableArgs = {
  where: VariableWhereUniqueInput;
};


export type MutationDeleteManyVariableArgs = {
  where?: Maybe<VariableWhereInput>;
};


export type MutationUpdateOneVariableArgs = {
  data: VariableUpdateInput;
  where: VariableWhereUniqueInput;
};


export type MutationUpsertOneVariableArgs = {
  where: VariableWhereUniqueInput;
  create: VariableCreateInput;
  update: VariableUpdateInput;
};


export type MutationCreateOneTreatmentArgs = {
  data: TreatmentCreateInput;
};


export type MutationCreateOneDiagnosisArgs = {
  data: DiagnosisCreateInput;
};


export type MutationUpdateOneDiagnosisArgs = {
  data: DiagnosisUpdateInput;
  where: DiagnosisWhereUniqueInput;
};


export type MutationUpdateOneTreatmentArgs = {
  data: TreatmentUpdateInput;
  where: TreatmentWhereUniqueInput;
};


export type MutationUpdateOneFinancialPlanArgs = {
  data: FinancialPlanUpdateInput;
  where: FinancialPlanWhereUniqueInput;
};


export type MutationCreateOneFinancialPlanArgs = {
  data: FinancialPlanCreateInput;
};


export type MutationCreateBoxIdArgs = {
  patientId?: Maybe<Scalars['String']>;
};


export type MutationCreateOneAppointmentProposalArgs = {
  data: AppointmentProposalCreateInput;
};


export type MutationDeleteOneAppointmentProposalArgs = {
  where: AppointmentProposalWhereUniqueInput;
};


export type MutationUpdateOneAppointmentProposalArgs = {
  data: AppointmentProposalUpdateInput;
  where: AppointmentProposalWhereUniqueInput;
};


export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};


export type MutationSelectAppointmentFromProposalArgs = {
  appointmentId?: Maybe<Scalars['String']>;
};


export type MutationCreateNewAppointmentForPatientArgs = {
  input?: Maybe<CustomAppointmentCreateInput>;
};


export type MutationCancelAppointmentArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationSendEmailArgs = {
  mail?: Maybe<SendEmailInput>;
};


export type MutationSendAppointmentConfirmationArgs = {
  appointmentId?: Maybe<Scalars['String']>;
};


export type MutationSendAppointmentProposalArgs = {
  proposalId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  person?: Maybe<Person>;
  people: Array<Person>;
  patients: Array<Patient>;
  patient?: Maybe<Patient>;
  dentist?: Maybe<Dentist>;
  dentists: Array<Dentist>;
  schools: Array<School>;
  school?: Maybe<School>;
  country?: Maybe<Country>;
  countries: Array<Country>;
  cities: Array<City>;
  city?: Maybe<City>;
  variablesForPartOf: Array<Variable>;
  diagnosis?: Maybe<Diagnosis>;
  diagnoses: Array<Diagnosis>;
  treatment?: Maybe<Treatment>;
  treatments: Array<Treatment>;
  variables: Array<Variable>;
  variable?: Maybe<Variable>;
  calendars: Array<Calendar>;
  financialPlan?: Maybe<FinancialPlan>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  getAppointmentInfoPublic?: Maybe<PublicAppointmentProposal>;
  appointmentsBetweenTwoDates: Array<Appointment>;
  appointmentsOnDate: Array<Appointment>;
  appointments: Array<Appointment>;
  appointmentsForPatient: Array<Appointment>;
  appointment?: Maybe<Appointment>;
  appointmentProposal?: Maybe<AppointmentProposal>;
  appointmentProposals: Array<AppointmentProposal>;
  calendarToken?: Maybe<CalendarToken>;
  generateGoogleCalendarUrl: Scalars['String'];
};


export type QueryPersonArgs = {
  where: PersonWhereUniqueInput;
};


export type QueryPatientArgs = {
  where: PatientWhereUniqueInput;
};


export type QueryDentistArgs = {
  where: DentistWhereUniqueInput;
};


export type QuerySchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type QueryCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryCityArgs = {
  where: CityWhereUniqueInput;
};


export type QueryVariablesForPartOfArgs = {
  partOf?: Maybe<Scalars['String']>;
};


export type QueryDiagnosisArgs = {
  where: DiagnosisWhereUniqueInput;
};


export type QueryDiagnosesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<DiagnosisWhereUniqueInput>;
  after?: Maybe<DiagnosisWhereUniqueInput>;
};


export type QueryTreatmentArgs = {
  where: TreatmentWhereUniqueInput;
};


export type QueryTreatmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<TreatmentWhereUniqueInput>;
  after?: Maybe<TreatmentWhereUniqueInput>;
};


export type QueryVariablesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<VariableWhereUniqueInput>;
  after?: Maybe<VariableWhereUniqueInput>;
};


export type QueryVariableArgs = {
  where: VariableWhereUniqueInput;
};


export type QueryCalendarsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<CalendarWhereUniqueInput>;
  after?: Maybe<CalendarWhereUniqueInput>;
};


export type QueryFinancialPlanArgs = {
  where: FinancialPlanWhereUniqueInput;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<InvoiceWhereUniqueInput>;
  after?: Maybe<InvoiceWhereUniqueInput>;
};


export type QueryGetAppointmentInfoPublicArgs = {
  proposalId?: Maybe<Scalars['String']>;
};


export type QueryAppointmentsBetweenTwoDatesArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAppointmentsOnDateArgs = {
  date?: Maybe<Scalars['Date']>;
};


export type QueryAppointmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<AppointmentWhereUniqueInput>;
  after?: Maybe<AppointmentWhereUniqueInput>;
};


export type QueryAppointmentsForPatientArgs = {
  patientId?: Maybe<Scalars['String']>;
};


export type QueryAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};


export type QueryAppointmentProposalArgs = {
  where: AppointmentProposalWhereUniqueInput;
};


export type QueryAppointmentProposalsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<AppointmentProposalWhereUniqueInput>;
  after?: Maybe<AppointmentProposalWhereUniqueInput>;
};


export type QueryCalendarTokenArgs = {
  where: CalendarTokenWhereUniqueInput;
};

export type SendEmailInput = {
  from: Scalars['String'];
  subject: Scalars['String'];
  text: Scalars['String'];
  captchaTokenV3: Scalars['String'];
};

export type AppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
};

export type PersonCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fname: Scalars['String'];
  gender?: Maybe<Scalars['Gender']>;
  bdate: Scalars['DateTime'];
  language?: Maybe<Scalars['String']>;
  emails?: Maybe<PersonCreateemailsInput>;
  phones?: Maybe<PersonCreatephonesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  nationalRegisterNb?: Maybe<Scalars['String']>;
  address: AddressCreateNestedOneWithoutPersonInput;
  dentist?: Maybe<DentistCreateNestedOneWithoutPersonInput>;
  patient?: Maybe<PatientCreateNestedOneWithoutPersonInput>;
};

export type PersonUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  fname?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  bdate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emails?: Maybe<PersonUpdateemailsInput>;
  phones?: Maybe<PersonUpdatephonesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  nationalRegisterNb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneRequiredWithoutPersonInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPersonInput>;
  patient?: Maybe<PatientUpdateOneWithoutPersonInput>;
};

export type PersonWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
};

export type PatientCreateInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  person: PersonCreateNestedOneWithoutPatientInput;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPatientInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisUpdateOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanUpdateOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentUpdateOneWithoutPatientInput>;
};

export type PatientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
};

export type DentistCreateInput = {
  id?: Maybe<Scalars['String']>;
  person: PersonCreateNestedOneWithoutDentistInput;
  patient?: Maybe<PatientCreateNestedManyWithoutDentistInput>;
};

export type DentistWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
};

export type DentistUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutDentistInput>;
  patient?: Maybe<PatientUpdateManyWithoutDentistInput>;
};

export type SchoolCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  patient?: Maybe<PatientCreateNestedManyWithoutSchoolInput>;
};

export type SchoolWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CityCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  address?: Maybe<AddressCreateNestedManyWithoutCityInput>;
};

export type CityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CityUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateManyWithoutCityInput>;
};

export type CountryCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address?: Maybe<AddressCreateNestedManyWithoutCountryInput>;
};

export type CountryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VariableCreateInput = {
  id?: Maybe<Scalars['String']>;
  partOf: Scalars['String'];
  name: Scalars['String'];
  values?: Maybe<VariableCreatevaluesInput>;
};

export type VariableWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type VariableWhereInput = {
  AND?: Maybe<Array<VariableWhereInput>>;
  OR?: Maybe<Array<VariableWhereInput>>;
  NOT?: Maybe<Array<VariableWhereInput>>;
  id?: Maybe<StringFilter>;
  partOf?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  values?: Maybe<StringNullableListFilter>;
};

export type VariableUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  partOf?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  values?: Maybe<VariableUpdatevaluesInput>;
};

export type TreatmentCreateInput = {
  id?: Maybe<Scalars['String']>;
  treatment?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutTreatmentInput;
};

export type DiagnosisCreateInput = {
  id?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutDiagnosisInput;
};

export type DiagnosisUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  diagnosis?: Maybe<NullableStringFieldUpdateOperationsInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutDiagnosisInput>;
};

export type DiagnosisWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
};

export type TreatmentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  treatment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutTreatmentInput>;
};

export type TreatmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
};

export type FinancialPlanUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  options?: Maybe<NullableStringFieldUpdateOperationsInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutFinancialPlanInput>;
};

export type FinancialPlanWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
};

export type FinancialPlanCreateInput = {
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutFinancialPlanInput;
};

export type CalendarWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  googleCalId?: Maybe<Scalars['String']>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AppointmentProposalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AppointmentProposalCreateInput = {
  id?: Maybe<Scalars['String']>;
  publicNote?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutAppointmentProposalInput>;
};

export type AppointmentProposalUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  publicNote?: Maybe<StringFieldUpdateOperationsInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutAppointmentProposalInput>;
};

export type AppointmentUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  calendarTitle?: Maybe<StringFieldUpdateOperationsInput>;
  calendarDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  publicNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelled?: Maybe<BoolFieldUpdateOperationsInput>;
  ignore?: Maybe<BoolFieldUpdateOperationsInput>;
  reoccurringData?: Maybe<NullableStringFieldUpdateOperationsInput>;
  counter?: Maybe<IntFieldUpdateOperationsInput>;
  calendarEventId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appointmentProposal?: Maybe<AppointmentProposalUpdateOneWithoutAppointmentsInput>;
  calendar?: Maybe<CalendarUpdateOneWithoutAppointmentsInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutAppointmentInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
};

export type CalendarTokenWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PersonCreateemailsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type PersonCreatephonesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type AddressCreateNestedOneWithoutPersonInput = {
  create?: Maybe<AddressCreateWithoutPersonInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutPersonInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type DentistCreateNestedOneWithoutPersonInput = {
  create?: Maybe<DentistCreateWithoutPersonInput>;
  connectOrCreate?: Maybe<DentistCreateOrConnectWithoutPersonInput>;
  connect?: Maybe<DentistWhereUniqueInput>;
};

export type PatientCreateNestedOneWithoutPersonInput = {
  create?: Maybe<PatientCreateWithoutPersonInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPersonInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NullableEnumGenderFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Gender']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type PersonUpdateemailsInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Scalars['String']>;
};

export type PersonUpdatephonesInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type AddressUpdateOneRequiredWithoutPersonInput = {
  create?: Maybe<AddressCreateWithoutPersonInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutPersonInput>;
  upsert?: Maybe<AddressUpsertWithoutPersonInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  update?: Maybe<AddressUpdateWithoutPersonInput>;
};

export type DentistUpdateOneWithoutPersonInput = {
  create?: Maybe<DentistCreateWithoutPersonInput>;
  connectOrCreate?: Maybe<DentistCreateOrConnectWithoutPersonInput>;
  upsert?: Maybe<DentistUpsertWithoutPersonInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DentistWhereUniqueInput>;
  update?: Maybe<DentistUpdateWithoutPersonInput>;
};

export type PatientUpdateOneWithoutPersonInput = {
  create?: Maybe<PatientCreateWithoutPersonInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPersonInput>;
  upsert?: Maybe<PatientUpsertWithoutPersonInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PatientWhereUniqueInput>;
  update?: Maybe<PatientUpdateWithoutPersonInput>;
};

export type DentistCreateNestedOneWithoutPatientInput = {
  create?: Maybe<DentistCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<DentistCreateOrConnectWithoutPatientInput>;
  connect?: Maybe<DentistWhereUniqueInput>;
};

export type PersonCreateNestedOneWithoutPatientInput = {
  create?: Maybe<PersonCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutPatientInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type SchoolCreateNestedOneWithoutPatientInput = {
  create?: Maybe<SchoolCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<SchoolCreateOrConnectWithoutPatientInput>;
  connect?: Maybe<SchoolWhereUniqueInput>;
};

export type AppointmentCreateNestedManyWithoutPatientInput = {
  create?: Maybe<Array<AppointmentCreateWithoutPatientInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutPatientInput>>;
  createMany?: Maybe<AppointmentCreateManyPatientInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type DiagnosisCreateNestedOneWithoutPatientInput = {
  create?: Maybe<DiagnosisCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<DiagnosisCreateOrConnectWithoutPatientInput>;
  connect?: Maybe<DiagnosisWhereUniqueInput>;
};

export type FinancialPlanCreateNestedOneWithoutPatientInput = {
  create?: Maybe<FinancialPlanCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<FinancialPlanCreateOrConnectWithoutPatientInput>;
  connect?: Maybe<FinancialPlanWhereUniqueInput>;
};

export type TreatmentCreateNestedOneWithoutPatientInput = {
  create?: Maybe<TreatmentCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<TreatmentCreateOrConnectWithoutPatientInput>;
  connect?: Maybe<TreatmentWhereUniqueInput>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type DentistUpdateOneWithoutPatientInput = {
  create?: Maybe<DentistCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<DentistCreateOrConnectWithoutPatientInput>;
  upsert?: Maybe<DentistUpsertWithoutPatientInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DentistWhereUniqueInput>;
  update?: Maybe<DentistUpdateWithoutPatientInput>;
};

export type PersonUpdateOneRequiredWithoutPatientInput = {
  create?: Maybe<PersonCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutPatientInput>;
  upsert?: Maybe<PersonUpsertWithoutPatientInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutPatientInput>;
};

export type SchoolUpdateOneWithoutPatientInput = {
  create?: Maybe<SchoolCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<SchoolCreateOrConnectWithoutPatientInput>;
  upsert?: Maybe<SchoolUpsertWithoutPatientInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SchoolWhereUniqueInput>;
  update?: Maybe<SchoolUpdateWithoutPatientInput>;
};

export type AppointmentUpdateManyWithoutPatientInput = {
  create?: Maybe<Array<AppointmentCreateWithoutPatientInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutPatientInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutPatientInput>>;
  createMany?: Maybe<AppointmentCreateManyPatientInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutPatientInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type DiagnosisUpdateOneWithoutPatientInput = {
  create?: Maybe<DiagnosisCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<DiagnosisCreateOrConnectWithoutPatientInput>;
  upsert?: Maybe<DiagnosisUpsertWithoutPatientInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DiagnosisWhereUniqueInput>;
  update?: Maybe<DiagnosisUpdateWithoutPatientInput>;
};

export type FinancialPlanUpdateOneWithoutPatientInput = {
  create?: Maybe<FinancialPlanCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<FinancialPlanCreateOrConnectWithoutPatientInput>;
  upsert?: Maybe<FinancialPlanUpsertWithoutPatientInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<FinancialPlanWhereUniqueInput>;
  update?: Maybe<FinancialPlanUpdateWithoutPatientInput>;
};

export type TreatmentUpdateOneWithoutPatientInput = {
  create?: Maybe<TreatmentCreateWithoutPatientInput>;
  connectOrCreate?: Maybe<TreatmentCreateOrConnectWithoutPatientInput>;
  upsert?: Maybe<TreatmentUpsertWithoutPatientInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TreatmentWhereUniqueInput>;
  update?: Maybe<TreatmentUpdateWithoutPatientInput>;
};

export type PersonCreateNestedOneWithoutDentistInput = {
  create?: Maybe<PersonCreateWithoutDentistInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutDentistInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type PatientCreateNestedManyWithoutDentistInput = {
  create?: Maybe<Array<PatientCreateWithoutDentistInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutDentistInput>>;
  createMany?: Maybe<PatientCreateManyDentistInputEnvelope>;
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
};

export type PersonUpdateOneRequiredWithoutDentistInput = {
  create?: Maybe<PersonCreateWithoutDentistInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutDentistInput>;
  upsert?: Maybe<PersonUpsertWithoutDentistInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutDentistInput>;
};

export type PatientUpdateManyWithoutDentistInput = {
  create?: Maybe<Array<PatientCreateWithoutDentistInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutDentistInput>>;
  upsert?: Maybe<Array<PatientUpsertWithWhereUniqueWithoutDentistInput>>;
  createMany?: Maybe<PatientCreateManyDentistInputEnvelope>;
  set?: Maybe<Array<PatientWhereUniqueInput>>;
  disconnect?: Maybe<Array<PatientWhereUniqueInput>>;
  delete?: Maybe<Array<PatientWhereUniqueInput>>;
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  update?: Maybe<Array<PatientUpdateWithWhereUniqueWithoutDentistInput>>;
  updateMany?: Maybe<Array<PatientUpdateManyWithWhereWithoutDentistInput>>;
  deleteMany?: Maybe<Array<PatientScalarWhereInput>>;
};

export type PatientCreateNestedManyWithoutSchoolInput = {
  create?: Maybe<Array<PatientCreateWithoutSchoolInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutSchoolInput>>;
  createMany?: Maybe<PatientCreateManySchoolInputEnvelope>;
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
};

export type AddressCreateNestedManyWithoutCityInput = {
  create?: Maybe<Array<AddressCreateWithoutCityInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutCityInput>>;
  createMany?: Maybe<AddressCreateManyCityInputEnvelope>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
};

export type AddressUpdateManyWithoutCityInput = {
  create?: Maybe<Array<AddressCreateWithoutCityInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutCityInput>>;
  upsert?: Maybe<Array<AddressUpsertWithWhereUniqueWithoutCityInput>>;
  createMany?: Maybe<AddressCreateManyCityInputEnvelope>;
  set?: Maybe<Array<AddressWhereUniqueInput>>;
  disconnect?: Maybe<Array<AddressWhereUniqueInput>>;
  delete?: Maybe<Array<AddressWhereUniqueInput>>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
  update?: Maybe<Array<AddressUpdateWithWhereUniqueWithoutCityInput>>;
  updateMany?: Maybe<Array<AddressUpdateManyWithWhereWithoutCityInput>>;
  deleteMany?: Maybe<Array<AddressScalarWhereInput>>;
};

export type AddressCreateNestedManyWithoutCountryInput = {
  create?: Maybe<Array<AddressCreateWithoutCountryInput>>;
  connectOrCreate?: Maybe<Array<AddressCreateOrConnectWithoutCountryInput>>;
  createMany?: Maybe<AddressCreateManyCountryInputEnvelope>;
  connect?: Maybe<Array<AddressWhereUniqueInput>>;
};

export type VariableCreatevaluesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type VariableUpdatevaluesInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Scalars['String']>;
};

export type PatientCreateNestedOneWithoutTreatmentInput = {
  create?: Maybe<PatientCreateWithoutTreatmentInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutTreatmentInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
};

export type PatientCreateNestedOneWithoutDiagnosisInput = {
  create?: Maybe<PatientCreateWithoutDiagnosisInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutDiagnosisInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
};

export type PatientUpdateOneRequiredWithoutDiagnosisInput = {
  create?: Maybe<PatientCreateWithoutDiagnosisInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutDiagnosisInput>;
  upsert?: Maybe<PatientUpsertWithoutDiagnosisInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
  update?: Maybe<PatientUpdateWithoutDiagnosisInput>;
};

export type PatientUpdateOneRequiredWithoutTreatmentInput = {
  create?: Maybe<PatientCreateWithoutTreatmentInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutTreatmentInput>;
  upsert?: Maybe<PatientUpsertWithoutTreatmentInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
  update?: Maybe<PatientUpdateWithoutTreatmentInput>;
};

export type PatientUpdateOneRequiredWithoutFinancialPlanInput = {
  create?: Maybe<PatientCreateWithoutFinancialPlanInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutFinancialPlanInput>;
  upsert?: Maybe<PatientUpsertWithoutFinancialPlanInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
  update?: Maybe<PatientUpdateWithoutFinancialPlanInput>;
};

export type PatientCreateNestedOneWithoutFinancialPlanInput = {
  create?: Maybe<PatientCreateWithoutFinancialPlanInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutFinancialPlanInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
};

export type AppointmentCreateNestedManyWithoutAppointmentProposalInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentProposalInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentProposalInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentProposalInputEnvelope>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
};

export type AppointmentUpdateManyWithoutAppointmentProposalInput = {
  create?: Maybe<Array<AppointmentCreateWithoutAppointmentProposalInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutAppointmentProposalInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutAppointmentProposalInput>>;
  createMany?: Maybe<AppointmentCreateManyAppointmentProposalInputEnvelope>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutAppointmentProposalInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutAppointmentProposalInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type AppointmentProposalUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<AppointmentProposalCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentProposalCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<AppointmentProposalUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AppointmentProposalWhereUniqueInput>;
  update?: Maybe<AppointmentProposalUpdateWithoutAppointmentsInput>;
};

export type CalendarUpdateOneWithoutAppointmentsInput = {
  create?: Maybe<CalendarCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<CalendarCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<CalendarUpsertWithoutAppointmentsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CalendarWhereUniqueInput>;
  update?: Maybe<CalendarUpdateWithoutAppointmentsInput>;
};

export type InvoiceUpdateOneWithoutAppointmentInput = {
  create?: Maybe<InvoiceCreateWithoutAppointmentInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAppointmentInput>;
  upsert?: Maybe<InvoiceUpsertWithoutAppointmentInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUpdateWithoutAppointmentInput>;
};

export type PatientUpdateOneRequiredWithoutAppointmentsInput = {
  create?: Maybe<PatientCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutAppointmentsInput>;
  upsert?: Maybe<PatientUpsertWithoutAppointmentsInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
  update?: Maybe<PatientUpdateWithoutAppointmentsInput>;
};

export type AddressCreateWithoutPersonInput = {
  id?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumbering?: Maybe<Scalars['String']>;
  sndLine?: Maybe<Scalars['String']>;
  city?: Maybe<CityCreateNestedOneWithoutAddressInput>;
  country?: Maybe<CountryCreateNestedOneWithoutAddressInput>;
};

export type AddressCreateOrConnectWithoutPersonInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutPersonInput;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DentistCreateWithoutPersonInput = {
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientCreateNestedManyWithoutDentistInput>;
};

export type DentistCreateOrConnectWithoutPersonInput = {
  where: DentistWhereUniqueInput;
  create: DentistCreateWithoutPersonInput;
};

export type PatientCreateWithoutPersonInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutPersonInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutPersonInput;
};

export type AddressUpsertWithoutPersonInput = {
  update: AddressUpdateWithoutPersonInput;
  create: AddressCreateWithoutPersonInput;
};

export type AddressUpdateWithoutPersonInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumbering?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sndLine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  city?: Maybe<CityUpdateOneWithoutAddressInput>;
  country?: Maybe<CountryUpdateOneWithoutAddressInput>;
};

export type DentistUpsertWithoutPersonInput = {
  update: DentistUpdateWithoutPersonInput;
  create: DentistCreateWithoutPersonInput;
};

export type DentistUpdateWithoutPersonInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  patient?: Maybe<PatientUpdateManyWithoutDentistInput>;
};

export type PatientUpsertWithoutPersonInput = {
  update: PatientUpdateWithoutPersonInput;
  create: PatientCreateWithoutPersonInput;
};

export type PatientUpdateWithoutPersonInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisUpdateOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanUpdateOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentUpdateOneWithoutPatientInput>;
};

export type DentistCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  person: PersonCreateNestedOneWithoutDentistInput;
};

export type DentistCreateOrConnectWithoutPatientInput = {
  where: DentistWhereUniqueInput;
  create: DentistCreateWithoutPatientInput;
};

export type PersonCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fname: Scalars['String'];
  gender?: Maybe<Scalars['Gender']>;
  bdate: Scalars['DateTime'];
  language?: Maybe<Scalars['String']>;
  emails?: Maybe<PersonCreateemailsInput>;
  phones?: Maybe<PersonCreatephonesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  nationalRegisterNb?: Maybe<Scalars['String']>;
  address: AddressCreateNestedOneWithoutPersonInput;
  dentist?: Maybe<DentistCreateNestedOneWithoutPersonInput>;
};

export type PersonCreateOrConnectWithoutPatientInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutPatientInput;
};

export type SchoolCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SchoolCreateOrConnectWithoutPatientInput = {
  where: SchoolWhereUniqueInput;
  create: SchoolCreateWithoutPatientInput;
};

export type AppointmentCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  calendarTitle?: Maybe<Scalars['String']>;
  calendarDescription?: Maybe<Scalars['String']>;
  publicNote?: Maybe<Scalars['String']>;
  privateNote?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  reoccurringData?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
  calendarEventId?: Maybe<Scalars['String']>;
  appointmentProposal?: Maybe<AppointmentProposalCreateNestedOneWithoutAppointmentsInput>;
  calendar?: Maybe<CalendarCreateNestedOneWithoutAppointmentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutAppointmentInput>;
};

export type AppointmentCreateOrConnectWithoutPatientInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutPatientInput;
};

export type AppointmentCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<AppointmentCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DiagnosisCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  diagnosis?: Maybe<Scalars['String']>;
};

export type DiagnosisCreateOrConnectWithoutPatientInput = {
  where: DiagnosisWhereUniqueInput;
  create: DiagnosisCreateWithoutPatientInput;
};

export type FinancialPlanCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
};

export type FinancialPlanCreateOrConnectWithoutPatientInput = {
  where: FinancialPlanWhereUniqueInput;
  create: FinancialPlanCreateWithoutPatientInput;
};

export type TreatmentCreateWithoutPatientInput = {
  id?: Maybe<Scalars['String']>;
  treatment?: Maybe<Scalars['String']>;
};

export type TreatmentCreateOrConnectWithoutPatientInput = {
  where: TreatmentWhereUniqueInput;
  create: TreatmentCreateWithoutPatientInput;
};

export type DentistUpsertWithoutPatientInput = {
  update: DentistUpdateWithoutPatientInput;
  create: DentistCreateWithoutPatientInput;
};

export type DentistUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutDentistInput>;
};

export type PersonUpsertWithoutPatientInput = {
  update: PersonUpdateWithoutPatientInput;
  create: PersonCreateWithoutPatientInput;
};

export type PersonUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  fname?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  bdate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emails?: Maybe<PersonUpdateemailsInput>;
  phones?: Maybe<PersonUpdatephonesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  nationalRegisterNb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneRequiredWithoutPersonInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPersonInput>;
};

export type SchoolUpsertWithoutPatientInput = {
  update: SchoolUpdateWithoutPatientInput;
  create: SchoolCreateWithoutPatientInput;
};

export type SchoolUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type AppointmentUpsertWithWhereUniqueWithoutPatientInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutPatientInput;
  create: AppointmentCreateWithoutPatientInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutPatientInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutPatientInput;
};

export type AppointmentUpdateManyWithWhereWithoutPatientInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
};

export type AppointmentScalarWhereInput = {
  AND?: Maybe<Array<AppointmentScalarWhereInput>>;
  OR?: Maybe<Array<AppointmentScalarWhereInput>>;
  NOT?: Maybe<Array<AppointmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  start?: Maybe<DateTimeFilter>;
  end?: Maybe<DateTimeFilter>;
  patientId?: Maybe<StringFilter>;
  calendarTitle?: Maybe<StringFilter>;
  calendarDescription?: Maybe<StringNullableFilter>;
  publicNote?: Maybe<StringNullableFilter>;
  privateNote?: Maybe<StringNullableFilter>;
  cancelled?: Maybe<BoolFilter>;
  ignore?: Maybe<BoolFilter>;
  reoccurringData?: Maybe<StringNullableFilter>;
  appointmentProposalId?: Maybe<StringNullableFilter>;
  calendarId?: Maybe<StringNullableFilter>;
  invoiceId?: Maybe<StringNullableFilter>;
  counter?: Maybe<IntFilter>;
  calendarEventId?: Maybe<StringNullableFilter>;
};

export type DiagnosisUpsertWithoutPatientInput = {
  update: DiagnosisUpdateWithoutPatientInput;
  create: DiagnosisCreateWithoutPatientInput;
};

export type DiagnosisUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  diagnosis?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type FinancialPlanUpsertWithoutPatientInput = {
  update: FinancialPlanUpdateWithoutPatientInput;
  create: FinancialPlanCreateWithoutPatientInput;
};

export type FinancialPlanUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  options?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type TreatmentUpsertWithoutPatientInput = {
  update: TreatmentUpdateWithoutPatientInput;
  create: TreatmentCreateWithoutPatientInput;
};

export type TreatmentUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  treatment?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type PersonCreateWithoutDentistInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fname: Scalars['String'];
  gender?: Maybe<Scalars['Gender']>;
  bdate: Scalars['DateTime'];
  language?: Maybe<Scalars['String']>;
  emails?: Maybe<PersonCreateemailsInput>;
  phones?: Maybe<PersonCreatephonesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  nationalRegisterNb?: Maybe<Scalars['String']>;
  address: AddressCreateNestedOneWithoutPersonInput;
  patient?: Maybe<PatientCreateNestedOneWithoutPersonInput>;
};

export type PersonCreateOrConnectWithoutDentistInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutDentistInput;
};

export type PatientCreateWithoutDentistInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  person: PersonCreateNestedOneWithoutPatientInput;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutDentistInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutDentistInput;
};

export type PatientCreateManyDentistInputEnvelope = {
  data?: Maybe<Array<PatientCreateManyDentistInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PersonUpsertWithoutDentistInput = {
  update: PersonUpdateWithoutDentistInput;
  create: PersonCreateWithoutDentistInput;
};

export type PersonUpdateWithoutDentistInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  fname?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  bdate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emails?: Maybe<PersonUpdateemailsInput>;
  phones?: Maybe<PersonUpdatephonesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  nationalRegisterNb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneRequiredWithoutPersonInput>;
  patient?: Maybe<PatientUpdateOneWithoutPersonInput>;
};

export type PatientUpsertWithWhereUniqueWithoutDentistInput = {
  where: PatientWhereUniqueInput;
  update: PatientUpdateWithoutDentistInput;
  create: PatientCreateWithoutDentistInput;
};

export type PatientUpdateWithWhereUniqueWithoutDentistInput = {
  where: PatientWhereUniqueInput;
  data: PatientUpdateWithoutDentistInput;
};

export type PatientUpdateManyWithWhereWithoutDentistInput = {
  where: PatientScalarWhereInput;
  data: PatientUpdateManyMutationInput;
};

export type PatientScalarWhereInput = {
  AND?: Maybe<Array<PatientScalarWhereInput>>;
  OR?: Maybe<Array<PatientScalarWhereInput>>;
  NOT?: Maybe<Array<PatientScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  personId?: Maybe<StringFilter>;
  insRefund?: Maybe<BoolFilter>;
  dentistId?: Maybe<StringNullableFilter>;
  schoolId?: Maybe<StringNullableFilter>;
  disease?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  deletedAt?: Maybe<DateTimeNullableFilter>;
  boxId?: Maybe<StringNullableFilter>;
};

export type PatientCreateWithoutSchoolInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  person: PersonCreateNestedOneWithoutPatientInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutSchoolInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutSchoolInput;
};

export type PatientCreateManySchoolInputEnvelope = {
  data?: Maybe<Array<PatientCreateManySchoolInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AddressCreateWithoutCityInput = {
  id?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumbering?: Maybe<Scalars['String']>;
  sndLine?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCreateNestedOneWithoutAddressInput>;
  person?: Maybe<PersonCreateNestedOneWithoutAddressInput>;
};

export type AddressCreateOrConnectWithoutCityInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutCityInput;
};

export type AddressCreateManyCityInputEnvelope = {
  data?: Maybe<Array<AddressCreateManyCityInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AddressUpsertWithWhereUniqueWithoutCityInput = {
  where: AddressWhereUniqueInput;
  update: AddressUpdateWithoutCityInput;
  create: AddressCreateWithoutCityInput;
};

export type AddressUpdateWithWhereUniqueWithoutCityInput = {
  where: AddressWhereUniqueInput;
  data: AddressUpdateWithoutCityInput;
};

export type AddressUpdateManyWithWhereWithoutCityInput = {
  where: AddressScalarWhereInput;
  data: AddressUpdateManyMutationInput;
};

export type AddressScalarWhereInput = {
  AND?: Maybe<Array<AddressScalarWhereInput>>;
  OR?: Maybe<Array<AddressScalarWhereInput>>;
  NOT?: Maybe<Array<AddressScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  street?: Maybe<StringNullableFilter>;
  houseNumbering?: Maybe<StringNullableFilter>;
  sndLine?: Maybe<StringNullableFilter>;
  cityId?: Maybe<StringNullableFilter>;
  countryId?: Maybe<StringNullableFilter>;
};

export type AddressCreateWithoutCountryInput = {
  id?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumbering?: Maybe<Scalars['String']>;
  sndLine?: Maybe<Scalars['String']>;
  city?: Maybe<CityCreateNestedOneWithoutAddressInput>;
  person?: Maybe<PersonCreateNestedOneWithoutAddressInput>;
};

export type AddressCreateOrConnectWithoutCountryInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutCountryInput;
};

export type AddressCreateManyCountryInputEnvelope = {
  data?: Maybe<Array<AddressCreateManyCountryInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type PatientCreateWithoutTreatmentInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  person: PersonCreateNestedOneWithoutPatientInput;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutTreatmentInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutTreatmentInput;
};

export type PatientCreateWithoutDiagnosisInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  person: PersonCreateNestedOneWithoutPatientInput;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutDiagnosisInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutDiagnosisInput;
};

export type PatientUpsertWithoutDiagnosisInput = {
  update: PatientUpdateWithoutDiagnosisInput;
  create: PatientCreateWithoutDiagnosisInput;
};

export type PatientUpdateWithoutDiagnosisInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPatientInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanUpdateOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentUpdateOneWithoutPatientInput>;
};

export type PatientUpsertWithoutTreatmentInput = {
  update: PatientUpdateWithoutTreatmentInput;
  create: PatientCreateWithoutTreatmentInput;
};

export type PatientUpdateWithoutTreatmentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPatientInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisUpdateOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanUpdateOneWithoutPatientInput>;
};

export type PatientCreateWithoutFinancialPlanInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  person: PersonCreateNestedOneWithoutPatientInput;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutFinancialPlanInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutFinancialPlanInput;
};

export type PatientUpsertWithoutFinancialPlanInput = {
  update: PatientUpdateWithoutFinancialPlanInput;
  create: PatientCreateWithoutFinancialPlanInput;
};

export type PatientUpdateWithoutFinancialPlanInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPatientInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisUpdateOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentUpdateOneWithoutPatientInput>;
};

export type AppointmentCreateWithoutAppointmentProposalInput = {
  id?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  calendarTitle?: Maybe<Scalars['String']>;
  calendarDescription?: Maybe<Scalars['String']>;
  publicNote?: Maybe<Scalars['String']>;
  privateNote?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  reoccurringData?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
  calendarEventId?: Maybe<Scalars['String']>;
  calendar?: Maybe<CalendarCreateNestedOneWithoutAppointmentsInput>;
  invoice?: Maybe<InvoiceCreateNestedOneWithoutAppointmentInput>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
};

export type AppointmentCreateOrConnectWithoutAppointmentProposalInput = {
  where: AppointmentWhereUniqueInput;
  create: AppointmentCreateWithoutAppointmentProposalInput;
};

export type AppointmentCreateManyAppointmentProposalInputEnvelope = {
  data?: Maybe<Array<AppointmentCreateManyAppointmentProposalInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentUpsertWithWhereUniqueWithoutAppointmentProposalInput = {
  where: AppointmentWhereUniqueInput;
  update: AppointmentUpdateWithoutAppointmentProposalInput;
  create: AppointmentCreateWithoutAppointmentProposalInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutAppointmentProposalInput = {
  where: AppointmentWhereUniqueInput;
  data: AppointmentUpdateWithoutAppointmentProposalInput;
};

export type AppointmentUpdateManyWithWhereWithoutAppointmentProposalInput = {
  where: AppointmentScalarWhereInput;
  data: AppointmentUpdateManyMutationInput;
};

export type AppointmentProposalCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  publicNote?: Maybe<Scalars['String']>;
};

export type AppointmentProposalCreateOrConnectWithoutAppointmentsInput = {
  where: AppointmentProposalWhereUniqueInput;
  create: AppointmentProposalCreateWithoutAppointmentsInput;
};

export type AppointmentProposalUpsertWithoutAppointmentsInput = {
  update: AppointmentProposalUpdateWithoutAppointmentsInput;
  create: AppointmentProposalCreateWithoutAppointmentsInput;
};

export type AppointmentProposalUpdateWithoutAppointmentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  publicNote?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CalendarCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  googleCalId: Scalars['String'];
  bgColor?: Maybe<Scalars['String']>;
  fgColor?: Maybe<Scalars['String']>;
};

export type CalendarCreateOrConnectWithoutAppointmentsInput = {
  where: CalendarWhereUniqueInput;
  create: CalendarCreateWithoutAppointmentsInput;
};

export type CalendarUpsertWithoutAppointmentsInput = {
  update: CalendarUpdateWithoutAppointmentsInput;
  create: CalendarCreateWithoutAppointmentsInput;
};

export type CalendarUpdateWithoutAppointmentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  googleCalId?: Maybe<StringFieldUpdateOperationsInput>;
  bgColor?: Maybe<StringFieldUpdateOperationsInput>;
  fgColor?: Maybe<StringFieldUpdateOperationsInput>;
};

export type InvoiceCreateWithoutAppointmentInput = {
  id?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  options?: Maybe<InvoiceCreateoptionsInput>;
  prices?: Maybe<InvoiceCreatepricesInput>;
  paid?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateOrConnectWithoutAppointmentInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceCreateWithoutAppointmentInput;
};

export type InvoiceUpsertWithoutAppointmentInput = {
  update: InvoiceUpdateWithoutAppointmentInput;
  create: InvoiceCreateWithoutAppointmentInput;
};

export type InvoiceUpdateWithoutAppointmentInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  paymentId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  options?: Maybe<InvoiceUpdateoptionsInput>;
  prices?: Maybe<InvoiceUpdatepricesInput>;
  paid?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type PatientCreateWithoutAppointmentsInput = {
  id?: Maybe<Scalars['String']>;
  insRefund?: Maybe<Scalars['Boolean']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPatientInput>;
  person: PersonCreateNestedOneWithoutPatientInput;
  school?: Maybe<SchoolCreateNestedOneWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisCreateNestedOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanCreateNestedOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentCreateNestedOneWithoutPatientInput>;
};

export type PatientCreateOrConnectWithoutAppointmentsInput = {
  where: PatientWhereUniqueInput;
  create: PatientCreateWithoutAppointmentsInput;
};

export type PatientUpsertWithoutAppointmentsInput = {
  update: PatientUpdateWithoutAppointmentsInput;
  create: PatientCreateWithoutAppointmentsInput;
};

export type PatientUpdateWithoutAppointmentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPatientInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisUpdateOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanUpdateOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentUpdateOneWithoutPatientInput>;
};

export type CityCreateNestedOneWithoutAddressInput = {
  create?: Maybe<CityCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<CityCreateOrConnectWithoutAddressInput>;
  connect?: Maybe<CityWhereUniqueInput>;
};

export type CountryCreateNestedOneWithoutAddressInput = {
  create?: Maybe<CountryCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<CountryCreateOrConnectWithoutAddressInput>;
  connect?: Maybe<CountryWhereUniqueInput>;
};

export type CityUpdateOneWithoutAddressInput = {
  create?: Maybe<CityCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<CityCreateOrConnectWithoutAddressInput>;
  upsert?: Maybe<CityUpsertWithoutAddressInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CityWhereUniqueInput>;
  update?: Maybe<CityUpdateWithoutAddressInput>;
};

export type CountryUpdateOneWithoutAddressInput = {
  create?: Maybe<CountryCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<CountryCreateOrConnectWithoutAddressInput>;
  upsert?: Maybe<CountryUpsertWithoutAddressInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CountryWhereUniqueInput>;
  update?: Maybe<CountryUpdateWithoutAddressInput>;
};

export type AppointmentProposalCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<AppointmentProposalCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<AppointmentProposalCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<AppointmentProposalWhereUniqueInput>;
};

export type CalendarCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<CalendarCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<CalendarCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<CalendarWhereUniqueInput>;
};

export type InvoiceCreateNestedOneWithoutAppointmentInput = {
  create?: Maybe<InvoiceCreateWithoutAppointmentInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutAppointmentInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type AppointmentCreateManyPatientInput = {
  id?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  calendarTitle?: Maybe<Scalars['String']>;
  calendarDescription?: Maybe<Scalars['String']>;
  publicNote?: Maybe<Scalars['String']>;
  privateNote?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  reoccurringData?: Maybe<Scalars['String']>;
  appointmentProposalId?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
  calendarEventId?: Maybe<Scalars['String']>;
};

export type AppointmentUpdateWithoutPatientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  calendarTitle?: Maybe<StringFieldUpdateOperationsInput>;
  calendarDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  publicNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelled?: Maybe<BoolFieldUpdateOperationsInput>;
  ignore?: Maybe<BoolFieldUpdateOperationsInput>;
  reoccurringData?: Maybe<NullableStringFieldUpdateOperationsInput>;
  counter?: Maybe<IntFieldUpdateOperationsInput>;
  calendarEventId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  appointmentProposal?: Maybe<AppointmentProposalUpdateOneWithoutAppointmentsInput>;
  calendar?: Maybe<CalendarUpdateOneWithoutAppointmentsInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutAppointmentInput>;
};

export type AppointmentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  calendarTitle?: Maybe<StringFieldUpdateOperationsInput>;
  calendarDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  publicNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelled?: Maybe<BoolFieldUpdateOperationsInput>;
  ignore?: Maybe<BoolFieldUpdateOperationsInput>;
  reoccurringData?: Maybe<NullableStringFieldUpdateOperationsInput>;
  counter?: Maybe<IntFieldUpdateOperationsInput>;
  calendarEventId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type PatientCreateManyDentistInput = {
  id?: Maybe<Scalars['String']>;
  personId: Scalars['String'];
  insRefund?: Maybe<Scalars['Boolean']>;
  schoolId?: Maybe<Scalars['String']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutDentistInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutPatientInput>;
  school?: Maybe<SchoolUpdateOneWithoutPatientInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  diagnosis?: Maybe<DiagnosisUpdateOneWithoutPatientInput>;
  financialPlan?: Maybe<FinancialPlanUpdateOneWithoutPatientInput>;
  treatment?: Maybe<TreatmentUpdateOneWithoutPatientInput>;
};

export type PatientUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  insRefund?: Maybe<BoolFieldUpdateOperationsInput>;
  disease?: Maybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  boxId?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type PatientCreateManySchoolInput = {
  id?: Maybe<Scalars['String']>;
  personId: Scalars['String'];
  insRefund?: Maybe<Scalars['Boolean']>;
  dentistId?: Maybe<Scalars['String']>;
  disease?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  boxId?: Maybe<Scalars['String']>;
};

export type PersonCreateNestedOneWithoutAddressInput = {
  create?: Maybe<PersonCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutAddressInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type AddressCreateManyCityInput = {
  id?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumbering?: Maybe<Scalars['String']>;
  sndLine?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutCityInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumbering?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sndLine?: Maybe<NullableStringFieldUpdateOperationsInput>;
  country?: Maybe<CountryUpdateOneWithoutAddressInput>;
  person?: Maybe<PersonUpdateOneWithoutAddressInput>;
};

export type AddressUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  street?: Maybe<NullableStringFieldUpdateOperationsInput>;
  houseNumbering?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sndLine?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AddressCreateManyCountryInput = {
  id?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  houseNumbering?: Maybe<Scalars['String']>;
  sndLine?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
};

export type PatientCreateNestedOneWithoutAppointmentsInput = {
  create?: Maybe<PatientCreateWithoutAppointmentsInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutAppointmentsInput>;
  connect?: Maybe<PatientWhereUniqueInput>;
};

export type AppointmentCreateManyAppointmentProposalInput = {
  id?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  patientId: Scalars['String'];
  calendarTitle?: Maybe<Scalars['String']>;
  calendarDescription?: Maybe<Scalars['String']>;
  publicNote?: Maybe<Scalars['String']>;
  privateNote?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  reoccurringData?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['Int']>;
  calendarEventId?: Maybe<Scalars['String']>;
};

export type AppointmentUpdateWithoutAppointmentProposalInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  start?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end?: Maybe<DateTimeFieldUpdateOperationsInput>;
  calendarTitle?: Maybe<StringFieldUpdateOperationsInput>;
  calendarDescription?: Maybe<NullableStringFieldUpdateOperationsInput>;
  publicNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  privateNote?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cancelled?: Maybe<BoolFieldUpdateOperationsInput>;
  ignore?: Maybe<BoolFieldUpdateOperationsInput>;
  reoccurringData?: Maybe<NullableStringFieldUpdateOperationsInput>;
  counter?: Maybe<IntFieldUpdateOperationsInput>;
  calendarEventId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  calendar?: Maybe<CalendarUpdateOneWithoutAppointmentsInput>;
  invoice?: Maybe<InvoiceUpdateOneWithoutAppointmentInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
};

export type InvoiceCreateoptionsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type InvoiceCreatepricesInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type InvoiceUpdateoptionsInput = {
  set?: Maybe<Array<Scalars['String']>>;
  push?: Maybe<Scalars['String']>;
};

export type InvoiceUpdatepricesInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type CityCreateWithoutAddressInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
};

export type CityCreateOrConnectWithoutAddressInput = {
  where: CityWhereUniqueInput;
  create: CityCreateWithoutAddressInput;
};

export type CountryCreateWithoutAddressInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CountryCreateOrConnectWithoutAddressInput = {
  where: CountryWhereUniqueInput;
  create: CountryCreateWithoutAddressInput;
};

export type CityUpsertWithoutAddressInput = {
  update: CityUpdateWithoutAddressInput;
  create: CityCreateWithoutAddressInput;
};

export type CityUpdateWithoutAddressInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  postalCode?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CountryUpsertWithoutAddressInput = {
  update: CountryUpdateWithoutAddressInput;
  create: CountryCreateWithoutAddressInput;
};

export type CountryUpdateWithoutAddressInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type PersonCreateWithoutAddressInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  fname: Scalars['String'];
  gender?: Maybe<Scalars['Gender']>;
  bdate: Scalars['DateTime'];
  language?: Maybe<Scalars['String']>;
  emails?: Maybe<PersonCreateemailsInput>;
  phones?: Maybe<PersonCreatephonesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  nationalRegisterNb?: Maybe<Scalars['String']>;
  dentist?: Maybe<DentistCreateNestedOneWithoutPersonInput>;
  patient?: Maybe<PatientCreateNestedOneWithoutPersonInput>;
};

export type PersonCreateOrConnectWithoutAddressInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutAddressInput;
};

export type PersonUpdateOneWithoutAddressInput = {
  create?: Maybe<PersonCreateWithoutAddressInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutAddressInput>;
  upsert?: Maybe<PersonUpsertWithoutAddressInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutAddressInput>;
};

export type PersonUpsertWithoutAddressInput = {
  update: PersonUpdateWithoutAddressInput;
  create: PersonCreateWithoutAddressInput;
};

export type PersonUpdateWithoutAddressInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  fname?: Maybe<StringFieldUpdateOperationsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  bdate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emails?: Maybe<PersonUpdateemailsInput>;
  phones?: Maybe<PersonUpdatephonesInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  nationalRegisterNb?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dentist?: Maybe<DentistUpdateOneWithoutPersonInput>;
  patient?: Maybe<PatientUpdateOneWithoutPersonInput>;
};

export type CreatePatientMutationVariables = Exact<{
  data: PatientCreateInput;
}>;


export type CreatePatientMutation = (
  { __typename?: 'Mutation' }
  & { createOnePatient: (
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  ) }
);

export type CreateSchoolMutationVariables = Exact<{
  data: SchoolCreateInput;
}>;


export type CreateSchoolMutation = (
  { __typename?: 'Mutation' }
  & { createOneSchool: (
    { __typename?: 'School' }
    & Pick<School, 'id'>
  ) }
);

export type CreateCountryMutationVariables = Exact<{
  data: CountryCreateInput;
}>;


export type CreateCountryMutation = (
  { __typename?: 'Mutation' }
  & { createOneCountry: (
    { __typename?: 'Country' }
    & Pick<Country, 'id'>
  ) }
);

export type CreateCityMutationVariables = Exact<{
  data: CityCreateInput;
}>;


export type CreateCityMutation = (
  { __typename?: 'Mutation' }
  & { createOneCity: (
    { __typename?: 'City' }
    & Pick<City, 'id'>
  ) }
);

export type CreateDentistMutationVariables = Exact<{
  data: DentistCreateInput;
}>;


export type CreateDentistMutation = (
  { __typename?: 'Mutation' }
  & { createOneDentist: (
    { __typename?: 'Dentist' }
    & Pick<Dentist, 'id'>
  ) }
);

export type UpdatePatientMutationVariables = Exact<{
  data: PatientUpdateInput;
  id: Scalars['String'];
}>;


export type UpdatePatientMutation = (
  { __typename?: 'Mutation' }
  & { updateOnePatient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
  )> }
);

export type UpdateVariableMutationVariables = Exact<{
  name: Scalars['String'];
  values: Array<Scalars['String']> | Scalars['String'];
  id: Scalars['String'];
  partOf: Scalars['String'];
}>;


export type UpdateVariableMutation = (
  { __typename?: 'Mutation' }
  & { updateOneVariable?: Maybe<(
    { __typename?: 'Variable' }
    & Pick<Variable, 'id'>
  )> }
);

export type CreateVariableMutationVariables = Exact<{
  name: Scalars['String'];
  values: Array<Scalars['String']> | Scalars['String'];
  partOf: Scalars['String'];
}>;


export type CreateVariableMutation = (
  { __typename?: 'Mutation' }
  & { createOneVariable: (
    { __typename?: 'Variable' }
    & Pick<Variable, 'id'>
  ) }
);

export type CreateDiagnosisMutationVariables = Exact<{
  data: DiagnosisCreateInput;
}>;


export type CreateDiagnosisMutation = (
  { __typename?: 'Mutation' }
  & { createOneDiagnosis: (
    { __typename?: 'Diagnosis' }
    & Pick<Diagnosis, 'id'>
  ) }
);

export type CreateTreatmentMutationVariables = Exact<{
  data: TreatmentCreateInput;
}>;


export type CreateTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { createOneTreatment: (
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'id'>
  ) }
);

export type UpdateDiagnosisMutationVariables = Exact<{
  patientId: Scalars['String'];
  data: DiagnosisUpdateInput;
}>;


export type UpdateDiagnosisMutation = (
  { __typename?: 'Mutation' }
  & { updateOneDiagnosis?: Maybe<(
    { __typename?: 'Diagnosis' }
    & Pick<Diagnosis, 'diagnosis'>
  )> }
);

export type UpdateTreatmentMutationVariables = Exact<{
  patientId: Scalars['String'];
  data: TreatmentUpdateInput;
}>;


export type UpdateTreatmentMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTreatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'treatment'>
  )> }
);

export type DeleteVariablesMutationVariables = Exact<{
  vars?: Maybe<VariableWhereInput>;
}>;


export type DeleteVariablesMutation = (
  { __typename?: 'Mutation' }
  & { deleteManyVariable: (
    { __typename?: 'AffectedRowsOutput' }
    & Pick<AffectedRowsOutput, 'count'>
  ) }
);

export type CreateNewAppointmentForPatientMutationVariables = Exact<{
  input?: Maybe<CustomAppointmentCreateInput>;
}>;


export type CreateNewAppointmentForPatientMutation = (
  { __typename?: 'Mutation' }
  & { createNewAppointmentForPatient: (
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
    & { patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'fname' | 'name'>
      ) }
    ) }
  ) }
);

export type UpdateAppointmentByIdMutationVariables = Exact<{
  id: Scalars['String'];
  data: AppointmentUpdateInput;
}>;


export type UpdateAppointmentByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateOneAppointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id'>
  )> }
);

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CancelAppointmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelAppointment'>
);

export type SelectAppointmentFromProposalMutationVariables = Exact<{
  appointmentId: Scalars['String'];
}>;


export type SelectAppointmentFromProposalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'selectAppointmentFromProposal'>
);

export type CreateBoxIdMutationVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type CreateBoxIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBoxId'>
);

export type SendConfirmationMailMutationVariables = Exact<{
  appointmentId: Scalars['String'];
}>;


export type SendConfirmationMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendAppointmentConfirmation'>
);

export type SendProposalMailMutationVariables = Exact<{
  proposalId: Scalars['String'];
}>;


export type SendProposalMailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendAppointmentProposal'>
);

export type RemoveAppointmentProposalMutationVariables = Exact<{
  proposalId: Scalars['String'];
}>;


export type RemoveAppointmentProposalMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneAppointmentProposal?: Maybe<(
    { __typename?: 'AppointmentProposal' }
    & Pick<AppointmentProposal, 'id'>
  )> }
);

export type GetAppointmentProposalQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAppointmentProposalQuery = (
  { __typename?: 'Query' }
  & { appointmentProposal?: Maybe<(
    { __typename?: 'AppointmentProposal' }
    & Pick<AppointmentProposal, 'id'>
    & { appointments: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'start'>
    )> }
  )> }
);

export type PeopleQueryVariables = Exact<{ [key: string]: never; }>;


export type PeopleQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'name' | 'fname' | 'id'>
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  )> }
);

export type DentistsQueryVariables = Exact<{ [key: string]: never; }>;


export type DentistsQuery = (
  { __typename?: 'Query' }
  & { dentists: Array<(
    { __typename?: 'Dentist' }
    & Pick<Dentist, 'id'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'fname' | 'name'>
    ) }
  )> }
);

export type SchoolsQueryVariables = Exact<{ [key: string]: never; }>;


export type SchoolsQuery = (
  { __typename?: 'Query' }
  & { schools: Array<(
    { __typename?: 'School' }
    & Pick<School, 'id' | 'name'>
  )> }
);

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )> }
);

export type CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CitiesQuery = (
  { __typename?: 'Query' }
  & { cities: Array<(
    { __typename?: 'City' }
    & Pick<City, 'id' | 'name' | 'postalCode'>
  )> }
);

export type PatientsMinimalInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientsMinimalInfoQuery = (
  { __typename?: 'Query' }
  & { patients: Array<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id'>
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'fname' | 'name' | 'bdate'>
    ) }
  )> }
);

export type PatientQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PatientQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'insRefund' | 'disease' | 'boxId'>
    & { school?: Maybe<(
      { __typename?: 'School' }
      & Pick<School, 'id' | 'name'>
    )>, dentist?: Maybe<(
      { __typename?: 'Dentist' }
      & Pick<Dentist, 'id'>
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'emails'>
      ) }
    )>, person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'fname' | 'name' | 'bdate' | 'gender' | 'language' | 'phones' | 'emails'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'street' | 'sndLine' | 'houseNumbering'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'id'>
        )>, country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id'>
        )> }
      ) }
    ) }
  )> }
);

export type VariablesQueryVariables = Exact<{ [key: string]: never; }>;


export type VariablesQuery = (
  { __typename?: 'Query' }
  & { variables: Array<(
    { __typename?: 'Variable' }
    & Pick<Variable, 'name' | 'id' | 'values' | 'partOf'>
  )> }
);

export type DiagnosisQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type DiagnosisQuery = (
  { __typename?: 'Query' }
  & { diagnosis?: Maybe<(
    { __typename?: 'Diagnosis' }
    & Pick<Diagnosis, 'diagnosis'>
  )> }
);

export type TreatmentQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type TreatmentQuery = (
  { __typename?: 'Query' }
  & { treatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'treatment'>
  )> }
);

export type AppointmentsForPatientQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type AppointmentsForPatientQuery = (
  { __typename?: 'Query' }
  & { appointmentsForPatient: Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'start' | 'end' | 'counter' | 'ignore' | 'cancelled'>
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'paid' | 'options' | 'prices' | 'paymentId'>
    )>, patient: (
      { __typename?: 'Patient' }
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'fname' | 'name'>
      ) }
    ) }
  )> }
);

export type AppointmentsForPatientHistoryQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type AppointmentsForPatientHistoryQuery = (
  { __typename?: 'Query' }
  & { appointmentsForPatient: Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'start' | 'publicNote'>
  )> }
);

export type AppointmentByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AppointmentByIdQuery = (
  { __typename?: 'Query' }
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'start' | 'end' | 'ignore' | 'cancelled' | 'privateNote' | 'publicNote' | 'reoccurringData'>
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'paid' | 'prices' | 'paymentId' | 'options'>
    )>, calendar?: Maybe<(
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'id'>
    )>, patient: (
      { __typename?: 'Patient' }
      & Pick<Patient, 'id'>
      & { financialPlan?: Maybe<(
        { __typename?: 'FinancialPlan' }
        & Pick<FinancialPlan, 'id' | 'options'>
      )>, person: (
        { __typename?: 'Person' }
        & Pick<Person, 'fname' | 'name'>
      ) }
    ) }
  )> }
);

export type GetAppointmentsOnDayQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type GetAppointmentsOnDayQuery = (
  { __typename?: 'Query' }
  & { appointmentsOnDate: Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'start' | 'end'>
    & { patient: (
      { __typename?: 'Patient' }
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'name' | 'fname'>
      ) }
    ) }
  )> }
);

export type GetAppointmentsBetweenTwoDatesQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetAppointmentsBetweenTwoDatesQuery = (
  { __typename?: 'Query' }
  & { appointmentsBetweenTwoDates: Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'start' | 'end' | 'cancelled' | 'calendarTitle' | 'calendarDescription'>
    & { calendar?: Maybe<(
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'id'>
    )>, appointmentProposal?: Maybe<(
      { __typename?: 'AppointmentProposal' }
      & Pick<AppointmentProposal, 'id' | 'publicNote'>
    )>, patient: (
      { __typename?: 'Patient' }
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'name' | 'fname'>
      ) }
    ) }
  )> }
);

export type GetPublicAppointmentProposalQueryVariables = Exact<{
  proposalId: Scalars['String'];
}>;


export type GetPublicAppointmentProposalQuery = (
  { __typename?: 'Query' }
  & { getAppointmentInfoPublic?: Maybe<(
    { __typename?: 'PublicAppointmentProposal' }
    & Pick<PublicAppointmentProposal, 'id' | 'fname' | 'name'>
    & { proposedAppointments: Array<(
      { __typename?: 'PublicAppointment' }
      & Pick<PublicAppointment, 'date' | 'id'>
    )> }
  )> }
);

export type GetAppointmentProposalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppointmentProposalsQuery = (
  { __typename?: 'Query' }
  & { appointmentProposals: Array<(
    { __typename?: 'AppointmentProposal' }
    & Pick<AppointmentProposal, 'id' | 'publicNote'>
    & { appointments: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'calendarTitle'>
      & { patient: (
        { __typename?: 'Patient' }
        & { person: (
          { __typename?: 'Person' }
          & Pick<Person, 'fname' | 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type GetFinancialPlanForPatientQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type GetFinancialPlanForPatientQuery = (
  { __typename?: 'Query' }
  & { financialPlan?: Maybe<(
    { __typename?: 'FinancialPlan' }
    & Pick<FinancialPlan, 'id' | 'options'>
  )> }
);

export type GetCalendarsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCalendarsQuery = (
  { __typename?: 'Query' }
  & { calendars: Array<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'id' | 'name' | 'bgColor' | 'fgColor'>
  )> }
);

export type VariablesForPartOfQueryVariables = Exact<{
  partOf: Scalars['String'];
}>;


export type VariablesForPartOfQuery = (
  { __typename?: 'Query' }
  & { variablesForPartOf: Array<(
    { __typename?: 'Variable' }
    & Pick<Variable, 'id' | 'name' | 'values'>
  )> }
);

export type GoogleApiQueryVariables = Exact<{ [key: string]: never; }>;


export type GoogleApiQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateGoogleCalendarUrl'>
);

export type GetReportDataQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReportDataQuery = (
  { __typename?: 'Query' }
  & { diagnosis?: Maybe<(
    { __typename?: 'Diagnosis' }
    & Pick<Diagnosis, 'diagnosis'>
  )>, treatment?: Maybe<(
    { __typename?: 'Treatment' }
    & Pick<Treatment, 'treatment'>
  )>, patient?: Maybe<(
    { __typename?: 'Patient' }
    & { person: (
      { __typename?: 'Person' }
      & Pick<Person, 'name' | 'fname' | 'bdate' | 'gender'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'street' | 'houseNumbering'>
        & { city?: Maybe<(
          { __typename?: 'City' }
          & Pick<City, 'name' | 'postalCode'>
        )> }
      ) }
    ), dentist?: Maybe<(
      { __typename?: 'Dentist' }
      & { person: (
        { __typename?: 'Person' }
        & Pick<Person, 'fname' | 'name'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'street' | 'houseNumbering'>
          & { city?: Maybe<(
            { __typename?: 'City' }
            & Pick<City, 'name' | 'postalCode'>
          )> }
        ) }
      ) }
    )> }
  )> }
);


export const CreatePatientDocument = gql`
    mutation CreatePatient($data: PatientCreateInput!) {
  createOnePatient(data: $data) {
    id
  }
}
    `;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, baseOptions);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const CreateSchoolDocument = gql`
    mutation CreateSchool($data: SchoolCreateInput!) {
  createOneSchool(data: $data) {
    id
  }
}
    `;
export type CreateSchoolMutationFn = Apollo.MutationFunction<CreateSchoolMutation, CreateSchoolMutationVariables>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolMutation, CreateSchoolMutationVariables>) {
        return Apollo.useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(CreateSchoolDocument, baseOptions);
      }
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
export type CreateSchoolMutationResult = Apollo.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const CreateCountryDocument = gql`
    mutation CreateCountry($data: CountryCreateInput!) {
  createOneCountry(data: $data) {
    id
  }
}
    `;
export type CreateCountryMutationFn = Apollo.MutationFunction<CreateCountryMutation, CreateCountryMutationVariables>;

/**
 * __useCreateCountryMutation__
 *
 * To run a mutation, you first call `useCreateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryMutation, { data, loading, error }] = useCreateCountryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCountryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCountryMutation, CreateCountryMutationVariables>) {
        return Apollo.useMutation<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, baseOptions);
      }
export type CreateCountryMutationHookResult = ReturnType<typeof useCreateCountryMutation>;
export type CreateCountryMutationResult = Apollo.MutationResult<CreateCountryMutation>;
export type CreateCountryMutationOptions = Apollo.BaseMutationOptions<CreateCountryMutation, CreateCountryMutationVariables>;
export const CreateCityDocument = gql`
    mutation CreateCity($data: CityCreateInput!) {
  createOneCity(data: $data) {
    id
  }
}
    `;
export type CreateCityMutationFn = Apollo.MutationFunction<CreateCityMutation, CreateCityMutationVariables>;

/**
 * __useCreateCityMutation__
 *
 * To run a mutation, you first call `useCreateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCityMutation, { data, loading, error }] = useCreateCityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCityMutation(baseOptions?: Apollo.MutationHookOptions<CreateCityMutation, CreateCityMutationVariables>) {
        return Apollo.useMutation<CreateCityMutation, CreateCityMutationVariables>(CreateCityDocument, baseOptions);
      }
export type CreateCityMutationHookResult = ReturnType<typeof useCreateCityMutation>;
export type CreateCityMutationResult = Apollo.MutationResult<CreateCityMutation>;
export type CreateCityMutationOptions = Apollo.BaseMutationOptions<CreateCityMutation, CreateCityMutationVariables>;
export const CreateDentistDocument = gql`
    mutation CreateDentist($data: DentistCreateInput!) {
  createOneDentist(data: $data) {
    id
  }
}
    `;
export type CreateDentistMutationFn = Apollo.MutationFunction<CreateDentistMutation, CreateDentistMutationVariables>;

/**
 * __useCreateDentistMutation__
 *
 * To run a mutation, you first call `useCreateDentistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDentistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDentistMutation, { data, loading, error }] = useCreateDentistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDentistMutation(baseOptions?: Apollo.MutationHookOptions<CreateDentistMutation, CreateDentistMutationVariables>) {
        return Apollo.useMutation<CreateDentistMutation, CreateDentistMutationVariables>(CreateDentistDocument, baseOptions);
      }
export type CreateDentistMutationHookResult = ReturnType<typeof useCreateDentistMutation>;
export type CreateDentistMutationResult = Apollo.MutationResult<CreateDentistMutation>;
export type CreateDentistMutationOptions = Apollo.BaseMutationOptions<CreateDentistMutation, CreateDentistMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation UpdatePatient($data: PatientUpdateInput!, $id: String!) {
  updateOnePatient(data: $data, where: {id: $id}) {
    id
  }
}
    `;
export type UpdatePatientMutationFn = Apollo.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, baseOptions);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const UpdateVariableDocument = gql`
    mutation UpdateVariable($name: String!, $values: [String!]!, $id: String!, $partOf: String!) {
  updateOneVariable(
    data: {name: {set: $name}, values: {set: $values}, partOf: {set: $partOf}}
    where: {id: $id}
  ) {
    id
  }
}
    `;
export type UpdateVariableMutationFn = Apollo.MutationFunction<UpdateVariableMutation, UpdateVariableMutationVariables>;

/**
 * __useUpdateVariableMutation__
 *
 * To run a mutation, you first call `useUpdateVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVariableMutation, { data, loading, error }] = useUpdateVariableMutation({
 *   variables: {
 *      name: // value for 'name'
 *      values: // value for 'values'
 *      id: // value for 'id'
 *      partOf: // value for 'partOf'
 *   },
 * });
 */
export function useUpdateVariableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVariableMutation, UpdateVariableMutationVariables>) {
        return Apollo.useMutation<UpdateVariableMutation, UpdateVariableMutationVariables>(UpdateVariableDocument, baseOptions);
      }
export type UpdateVariableMutationHookResult = ReturnType<typeof useUpdateVariableMutation>;
export type UpdateVariableMutationResult = Apollo.MutationResult<UpdateVariableMutation>;
export type UpdateVariableMutationOptions = Apollo.BaseMutationOptions<UpdateVariableMutation, UpdateVariableMutationVariables>;
export const CreateVariableDocument = gql`
    mutation CreateVariable($name: String!, $values: [String!]!, $partOf: String!) {
  createOneVariable(data: {partOf: $partOf, name: $name, values: {set: $values}}) {
    id
  }
}
    `;
export type CreateVariableMutationFn = Apollo.MutationFunction<CreateVariableMutation, CreateVariableMutationVariables>;

/**
 * __useCreateVariableMutation__
 *
 * To run a mutation, you first call `useCreateVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVariableMutation, { data, loading, error }] = useCreateVariableMutation({
 *   variables: {
 *      name: // value for 'name'
 *      values: // value for 'values'
 *      partOf: // value for 'partOf'
 *   },
 * });
 */
export function useCreateVariableMutation(baseOptions?: Apollo.MutationHookOptions<CreateVariableMutation, CreateVariableMutationVariables>) {
        return Apollo.useMutation<CreateVariableMutation, CreateVariableMutationVariables>(CreateVariableDocument, baseOptions);
      }
export type CreateVariableMutationHookResult = ReturnType<typeof useCreateVariableMutation>;
export type CreateVariableMutationResult = Apollo.MutationResult<CreateVariableMutation>;
export type CreateVariableMutationOptions = Apollo.BaseMutationOptions<CreateVariableMutation, CreateVariableMutationVariables>;
export const CreateDiagnosisDocument = gql`
    mutation CreateDiagnosis($data: DiagnosisCreateInput!) {
  createOneDiagnosis(data: $data) {
    id
  }
}
    `;
export type CreateDiagnosisMutationFn = Apollo.MutationFunction<CreateDiagnosisMutation, CreateDiagnosisMutationVariables>;

/**
 * __useCreateDiagnosisMutation__
 *
 * To run a mutation, you first call `useCreateDiagnosisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiagnosisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiagnosisMutation, { data, loading, error }] = useCreateDiagnosisMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDiagnosisMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiagnosisMutation, CreateDiagnosisMutationVariables>) {
        return Apollo.useMutation<CreateDiagnosisMutation, CreateDiagnosisMutationVariables>(CreateDiagnosisDocument, baseOptions);
      }
export type CreateDiagnosisMutationHookResult = ReturnType<typeof useCreateDiagnosisMutation>;
export type CreateDiagnosisMutationResult = Apollo.MutationResult<CreateDiagnosisMutation>;
export type CreateDiagnosisMutationOptions = Apollo.BaseMutationOptions<CreateDiagnosisMutation, CreateDiagnosisMutationVariables>;
export const CreateTreatmentDocument = gql`
    mutation CreateTreatment($data: TreatmentCreateInput!) {
  createOneTreatment(data: $data) {
    id
  }
}
    `;
export type CreateTreatmentMutationFn = Apollo.MutationFunction<CreateTreatmentMutation, CreateTreatmentMutationVariables>;

/**
 * __useCreateTreatmentMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentMutation, { data, loading, error }] = useCreateTreatmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateTreatmentMutation, CreateTreatmentMutationVariables>) {
        return Apollo.useMutation<CreateTreatmentMutation, CreateTreatmentMutationVariables>(CreateTreatmentDocument, baseOptions);
      }
export type CreateTreatmentMutationHookResult = ReturnType<typeof useCreateTreatmentMutation>;
export type CreateTreatmentMutationResult = Apollo.MutationResult<CreateTreatmentMutation>;
export type CreateTreatmentMutationOptions = Apollo.BaseMutationOptions<CreateTreatmentMutation, CreateTreatmentMutationVariables>;
export const UpdateDiagnosisDocument = gql`
    mutation UpdateDiagnosis($patientId: String!, $data: DiagnosisUpdateInput!) {
  updateOneDiagnosis(where: {patientId: $patientId}, data: $data) {
    diagnosis
  }
}
    `;
export type UpdateDiagnosisMutationFn = Apollo.MutationFunction<UpdateDiagnosisMutation, UpdateDiagnosisMutationVariables>;

/**
 * __useUpdateDiagnosisMutation__
 *
 * To run a mutation, you first call `useUpdateDiagnosisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiagnosisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiagnosisMutation, { data, loading, error }] = useUpdateDiagnosisMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDiagnosisMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiagnosisMutation, UpdateDiagnosisMutationVariables>) {
        return Apollo.useMutation<UpdateDiagnosisMutation, UpdateDiagnosisMutationVariables>(UpdateDiagnosisDocument, baseOptions);
      }
export type UpdateDiagnosisMutationHookResult = ReturnType<typeof useUpdateDiagnosisMutation>;
export type UpdateDiagnosisMutationResult = Apollo.MutationResult<UpdateDiagnosisMutation>;
export type UpdateDiagnosisMutationOptions = Apollo.BaseMutationOptions<UpdateDiagnosisMutation, UpdateDiagnosisMutationVariables>;
export const UpdateTreatmentDocument = gql`
    mutation UpdateTreatment($patientId: String!, $data: TreatmentUpdateInput!) {
  updateOneTreatment(where: {patientId: $patientId}, data: $data) {
    treatment
  }
}
    `;
export type UpdateTreatmentMutationFn = Apollo.MutationFunction<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>;

/**
 * __useUpdateTreatmentMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentMutation, { data, loading, error }] = useUpdateTreatmentMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>) {
        return Apollo.useMutation<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>(UpdateTreatmentDocument, baseOptions);
      }
export type UpdateTreatmentMutationHookResult = ReturnType<typeof useUpdateTreatmentMutation>;
export type UpdateTreatmentMutationResult = Apollo.MutationResult<UpdateTreatmentMutation>;
export type UpdateTreatmentMutationOptions = Apollo.BaseMutationOptions<UpdateTreatmentMutation, UpdateTreatmentMutationVariables>;
export const DeleteVariablesDocument = gql`
    mutation DeleteVariables($vars: VariableWhereInput) {
  deleteManyVariable(where: $vars) {
    count
  }
}
    `;
export type DeleteVariablesMutationFn = Apollo.MutationFunction<DeleteVariablesMutation, DeleteVariablesMutationVariables>;

/**
 * __useDeleteVariablesMutation__
 *
 * To run a mutation, you first call `useDeleteVariablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVariablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVariablesMutation, { data, loading, error }] = useDeleteVariablesMutation({
 *   variables: {
 *      vars: // value for 'vars'
 *   },
 * });
 */
export function useDeleteVariablesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVariablesMutation, DeleteVariablesMutationVariables>) {
        return Apollo.useMutation<DeleteVariablesMutation, DeleteVariablesMutationVariables>(DeleteVariablesDocument, baseOptions);
      }
export type DeleteVariablesMutationHookResult = ReturnType<typeof useDeleteVariablesMutation>;
export type DeleteVariablesMutationResult = Apollo.MutationResult<DeleteVariablesMutation>;
export type DeleteVariablesMutationOptions = Apollo.BaseMutationOptions<DeleteVariablesMutation, DeleteVariablesMutationVariables>;
export const CreateNewAppointmentForPatientDocument = gql`
    mutation CreateNewAppointmentForPatient($input: CustomAppointmentCreateInput) {
  createNewAppointmentForPatient(input: $input) {
    id
    patient {
      id
      person {
        fname
        name
      }
    }
  }
}
    `;
export type CreateNewAppointmentForPatientMutationFn = Apollo.MutationFunction<CreateNewAppointmentForPatientMutation, CreateNewAppointmentForPatientMutationVariables>;

/**
 * __useCreateNewAppointmentForPatientMutation__
 *
 * To run a mutation, you first call `useCreateNewAppointmentForPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewAppointmentForPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewAppointmentForPatientMutation, { data, loading, error }] = useCreateNewAppointmentForPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewAppointmentForPatientMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewAppointmentForPatientMutation, CreateNewAppointmentForPatientMutationVariables>) {
        return Apollo.useMutation<CreateNewAppointmentForPatientMutation, CreateNewAppointmentForPatientMutationVariables>(CreateNewAppointmentForPatientDocument, baseOptions);
      }
export type CreateNewAppointmentForPatientMutationHookResult = ReturnType<typeof useCreateNewAppointmentForPatientMutation>;
export type CreateNewAppointmentForPatientMutationResult = Apollo.MutationResult<CreateNewAppointmentForPatientMutation>;
export type CreateNewAppointmentForPatientMutationOptions = Apollo.BaseMutationOptions<CreateNewAppointmentForPatientMutation, CreateNewAppointmentForPatientMutationVariables>;
export const UpdateAppointmentByIdDocument = gql`
    mutation UpdateAppointmentById($id: String!, $data: AppointmentUpdateInput!) {
  updateOneAppointment(data: $data, where: {id: $id}) {
    id
  }
}
    `;
export type UpdateAppointmentByIdMutationFn = Apollo.MutationFunction<UpdateAppointmentByIdMutation, UpdateAppointmentByIdMutationVariables>;

/**
 * __useUpdateAppointmentByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentByIdMutation, { data, loading, error }] = useUpdateAppointmentByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppointmentByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentByIdMutation, UpdateAppointmentByIdMutationVariables>) {
        return Apollo.useMutation<UpdateAppointmentByIdMutation, UpdateAppointmentByIdMutationVariables>(UpdateAppointmentByIdDocument, baseOptions);
      }
export type UpdateAppointmentByIdMutationHookResult = ReturnType<typeof useUpdateAppointmentByIdMutation>;
export type UpdateAppointmentByIdMutationResult = Apollo.MutationResult<UpdateAppointmentByIdMutation>;
export type UpdateAppointmentByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentByIdMutation, UpdateAppointmentByIdMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($id: String!) {
  cancelAppointment(id: $id)
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, baseOptions);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const SelectAppointmentFromProposalDocument = gql`
    mutation SelectAppointmentFromProposal($appointmentId: String!) {
  selectAppointmentFromProposal(appointmentId: $appointmentId)
}
    `;
export type SelectAppointmentFromProposalMutationFn = Apollo.MutationFunction<SelectAppointmentFromProposalMutation, SelectAppointmentFromProposalMutationVariables>;

/**
 * __useSelectAppointmentFromProposalMutation__
 *
 * To run a mutation, you first call `useSelectAppointmentFromProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectAppointmentFromProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectAppointmentFromProposalMutation, { data, loading, error }] = useSelectAppointmentFromProposalMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useSelectAppointmentFromProposalMutation(baseOptions?: Apollo.MutationHookOptions<SelectAppointmentFromProposalMutation, SelectAppointmentFromProposalMutationVariables>) {
        return Apollo.useMutation<SelectAppointmentFromProposalMutation, SelectAppointmentFromProposalMutationVariables>(SelectAppointmentFromProposalDocument, baseOptions);
      }
export type SelectAppointmentFromProposalMutationHookResult = ReturnType<typeof useSelectAppointmentFromProposalMutation>;
export type SelectAppointmentFromProposalMutationResult = Apollo.MutationResult<SelectAppointmentFromProposalMutation>;
export type SelectAppointmentFromProposalMutationOptions = Apollo.BaseMutationOptions<SelectAppointmentFromProposalMutation, SelectAppointmentFromProposalMutationVariables>;
export const CreateBoxIdDocument = gql`
    mutation CreateBoxId($patientId: String!) {
  createBoxId(patientId: $patientId)
}
    `;
export type CreateBoxIdMutationFn = Apollo.MutationFunction<CreateBoxIdMutation, CreateBoxIdMutationVariables>;

/**
 * __useCreateBoxIdMutation__
 *
 * To run a mutation, you first call `useCreateBoxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBoxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBoxIdMutation, { data, loading, error }] = useCreateBoxIdMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCreateBoxIdMutation(baseOptions?: Apollo.MutationHookOptions<CreateBoxIdMutation, CreateBoxIdMutationVariables>) {
        return Apollo.useMutation<CreateBoxIdMutation, CreateBoxIdMutationVariables>(CreateBoxIdDocument, baseOptions);
      }
export type CreateBoxIdMutationHookResult = ReturnType<typeof useCreateBoxIdMutation>;
export type CreateBoxIdMutationResult = Apollo.MutationResult<CreateBoxIdMutation>;
export type CreateBoxIdMutationOptions = Apollo.BaseMutationOptions<CreateBoxIdMutation, CreateBoxIdMutationVariables>;
export const SendConfirmationMailDocument = gql`
    mutation sendConfirmationMail($appointmentId: String!) {
  sendAppointmentConfirmation(appointmentId: $appointmentId)
}
    `;
export type SendConfirmationMailMutationFn = Apollo.MutationFunction<SendConfirmationMailMutation, SendConfirmationMailMutationVariables>;

/**
 * __useSendConfirmationMailMutation__
 *
 * To run a mutation, you first call `useSendConfirmationMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationMailMutation, { data, loading, error }] = useSendConfirmationMailMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useSendConfirmationMailMutation(baseOptions?: Apollo.MutationHookOptions<SendConfirmationMailMutation, SendConfirmationMailMutationVariables>) {
        return Apollo.useMutation<SendConfirmationMailMutation, SendConfirmationMailMutationVariables>(SendConfirmationMailDocument, baseOptions);
      }
export type SendConfirmationMailMutationHookResult = ReturnType<typeof useSendConfirmationMailMutation>;
export type SendConfirmationMailMutationResult = Apollo.MutationResult<SendConfirmationMailMutation>;
export type SendConfirmationMailMutationOptions = Apollo.BaseMutationOptions<SendConfirmationMailMutation, SendConfirmationMailMutationVariables>;
export const SendProposalMailDocument = gql`
    mutation sendProposalMail($proposalId: String!) {
  sendAppointmentProposal(proposalId: $proposalId)
}
    `;
export type SendProposalMailMutationFn = Apollo.MutationFunction<SendProposalMailMutation, SendProposalMailMutationVariables>;

/**
 * __useSendProposalMailMutation__
 *
 * To run a mutation, you first call `useSendProposalMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProposalMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProposalMailMutation, { data, loading, error }] = useSendProposalMailMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useSendProposalMailMutation(baseOptions?: Apollo.MutationHookOptions<SendProposalMailMutation, SendProposalMailMutationVariables>) {
        return Apollo.useMutation<SendProposalMailMutation, SendProposalMailMutationVariables>(SendProposalMailDocument, baseOptions);
      }
export type SendProposalMailMutationHookResult = ReturnType<typeof useSendProposalMailMutation>;
export type SendProposalMailMutationResult = Apollo.MutationResult<SendProposalMailMutation>;
export type SendProposalMailMutationOptions = Apollo.BaseMutationOptions<SendProposalMailMutation, SendProposalMailMutationVariables>;
export const RemoveAppointmentProposalDocument = gql`
    mutation removeAppointmentProposal($proposalId: String!) {
  deleteOneAppointmentProposal(where: {id: $proposalId}) {
    id
  }
}
    `;
export type RemoveAppointmentProposalMutationFn = Apollo.MutationFunction<RemoveAppointmentProposalMutation, RemoveAppointmentProposalMutationVariables>;

/**
 * __useRemoveAppointmentProposalMutation__
 *
 * To run a mutation, you first call `useRemoveAppointmentProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAppointmentProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAppointmentProposalMutation, { data, loading, error }] = useRemoveAppointmentProposalMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useRemoveAppointmentProposalMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAppointmentProposalMutation, RemoveAppointmentProposalMutationVariables>) {
        return Apollo.useMutation<RemoveAppointmentProposalMutation, RemoveAppointmentProposalMutationVariables>(RemoveAppointmentProposalDocument, baseOptions);
      }
export type RemoveAppointmentProposalMutationHookResult = ReturnType<typeof useRemoveAppointmentProposalMutation>;
export type RemoveAppointmentProposalMutationResult = Apollo.MutationResult<RemoveAppointmentProposalMutation>;
export type RemoveAppointmentProposalMutationOptions = Apollo.BaseMutationOptions<RemoveAppointmentProposalMutation, RemoveAppointmentProposalMutationVariables>;
export const GetAppointmentProposalDocument = gql`
    query getAppointmentProposal($id: String!) {
  appointmentProposal(where: {id: $id}) {
    id
    appointments {
      start
    }
  }
}
    `;

/**
 * __useGetAppointmentProposalQuery__
 *
 * To run a query within a React component, call `useGetAppointmentProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentProposalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppointmentProposalQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentProposalQuery, GetAppointmentProposalQueryVariables>) {
        return Apollo.useQuery<GetAppointmentProposalQuery, GetAppointmentProposalQueryVariables>(GetAppointmentProposalDocument, baseOptions);
      }
export function useGetAppointmentProposalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentProposalQuery, GetAppointmentProposalQueryVariables>) {
          return Apollo.useLazyQuery<GetAppointmentProposalQuery, GetAppointmentProposalQueryVariables>(GetAppointmentProposalDocument, baseOptions);
        }
export type GetAppointmentProposalQueryHookResult = ReturnType<typeof useGetAppointmentProposalQuery>;
export type GetAppointmentProposalLazyQueryHookResult = ReturnType<typeof useGetAppointmentProposalLazyQuery>;
export type GetAppointmentProposalQueryResult = Apollo.QueryResult<GetAppointmentProposalQuery, GetAppointmentProposalQueryVariables>;
export const PeopleDocument = gql`
    query People {
  people {
    name
    fname
    id
  }
}
    `;

/**
 * __usePeopleQuery__
 *
 * To run a query within a React component, call `usePeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeopleQuery(baseOptions?: Apollo.QueryHookOptions<PeopleQuery, PeopleQueryVariables>) {
        return Apollo.useQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, baseOptions);
      }
export function usePeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleQuery, PeopleQueryVariables>) {
          return Apollo.useLazyQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, baseOptions);
        }
export type PeopleQueryHookResult = ReturnType<typeof usePeopleQuery>;
export type PeopleLazyQueryHookResult = ReturnType<typeof usePeopleLazyQuery>;
export type PeopleQueryResult = Apollo.QueryResult<PeopleQuery, PeopleQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const DentistsDocument = gql`
    query Dentists {
  dentists {
    id
    person {
      fname
      name
    }
  }
}
    `;

/**
 * __useDentistsQuery__
 *
 * To run a query within a React component, call `useDentistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentistsQuery(baseOptions?: Apollo.QueryHookOptions<DentistsQuery, DentistsQueryVariables>) {
        return Apollo.useQuery<DentistsQuery, DentistsQueryVariables>(DentistsDocument, baseOptions);
      }
export function useDentistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DentistsQuery, DentistsQueryVariables>) {
          return Apollo.useLazyQuery<DentistsQuery, DentistsQueryVariables>(DentistsDocument, baseOptions);
        }
export type DentistsQueryHookResult = ReturnType<typeof useDentistsQuery>;
export type DentistsLazyQueryHookResult = ReturnType<typeof useDentistsLazyQuery>;
export type DentistsQueryResult = Apollo.QueryResult<DentistsQuery, DentistsQueryVariables>;
export const SchoolsDocument = gql`
    query Schools {
  schools {
    id
    name
  }
}
    `;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
        return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, baseOptions);
      }
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
          return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, baseOptions);
        }
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    id
    name
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, baseOptions);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const CitiesDocument = gql`
    query Cities {
  cities {
    id
    name
    postalCode
  }
}
    `;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const PatientsMinimalInfoDocument = gql`
    query PatientsMinimalInfo {
  patients {
    id
    person {
      id
      fname
      name
      bdate
    }
  }
}
    `;

/**
 * __usePatientsMinimalInfoQuery__
 *
 * To run a query within a React component, call `usePatientsMinimalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsMinimalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsMinimalInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientsMinimalInfoQuery(baseOptions?: Apollo.QueryHookOptions<PatientsMinimalInfoQuery, PatientsMinimalInfoQueryVariables>) {
        return Apollo.useQuery<PatientsMinimalInfoQuery, PatientsMinimalInfoQueryVariables>(PatientsMinimalInfoDocument, baseOptions);
      }
export function usePatientsMinimalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientsMinimalInfoQuery, PatientsMinimalInfoQueryVariables>) {
          return Apollo.useLazyQuery<PatientsMinimalInfoQuery, PatientsMinimalInfoQueryVariables>(PatientsMinimalInfoDocument, baseOptions);
        }
export type PatientsMinimalInfoQueryHookResult = ReturnType<typeof usePatientsMinimalInfoQuery>;
export type PatientsMinimalInfoLazyQueryHookResult = ReturnType<typeof usePatientsMinimalInfoLazyQuery>;
export type PatientsMinimalInfoQueryResult = Apollo.QueryResult<PatientsMinimalInfoQuery, PatientsMinimalInfoQueryVariables>;
export const PatientDocument = gql`
    query Patient($id: String!) {
  patient(where: {id: $id}) {
    id
    insRefund
    disease
    boxId
    school {
      id
      name
    }
    dentist {
      id
      person {
        emails
      }
    }
    person {
      id
      fname
      name
      bdate
      gender
      language
      phones
      emails
      address {
        id
        street
        sndLine
        houseNumbering
        city {
          id
        }
        country {
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientQuery(baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>) {
        return Apollo.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, baseOptions);
      }
export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, baseOptions);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientQueryResult = Apollo.QueryResult<PatientQuery, PatientQueryVariables>;
export const VariablesDocument = gql`
    query Variables {
  variables {
    name
    id
    values
    partOf
  }
}
    `;

/**
 * __useVariablesQuery__
 *
 * To run a query within a React component, call `useVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVariablesQuery(baseOptions?: Apollo.QueryHookOptions<VariablesQuery, VariablesQueryVariables>) {
        return Apollo.useQuery<VariablesQuery, VariablesQueryVariables>(VariablesDocument, baseOptions);
      }
export function useVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariablesQuery, VariablesQueryVariables>) {
          return Apollo.useLazyQuery<VariablesQuery, VariablesQueryVariables>(VariablesDocument, baseOptions);
        }
export type VariablesQueryHookResult = ReturnType<typeof useVariablesQuery>;
export type VariablesLazyQueryHookResult = ReturnType<typeof useVariablesLazyQuery>;
export type VariablesQueryResult = Apollo.QueryResult<VariablesQuery, VariablesQueryVariables>;
export const DiagnosisDocument = gql`
    query Diagnosis($patientId: String!) {
  diagnosis(where: {patientId: $patientId}) {
    diagnosis
  }
}
    `;

/**
 * __useDiagnosisQuery__
 *
 * To run a query within a React component, call `useDiagnosisQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosisQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useDiagnosisQuery(baseOptions: Apollo.QueryHookOptions<DiagnosisQuery, DiagnosisQueryVariables>) {
        return Apollo.useQuery<DiagnosisQuery, DiagnosisQueryVariables>(DiagnosisDocument, baseOptions);
      }
export function useDiagnosisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagnosisQuery, DiagnosisQueryVariables>) {
          return Apollo.useLazyQuery<DiagnosisQuery, DiagnosisQueryVariables>(DiagnosisDocument, baseOptions);
        }
export type DiagnosisQueryHookResult = ReturnType<typeof useDiagnosisQuery>;
export type DiagnosisLazyQueryHookResult = ReturnType<typeof useDiagnosisLazyQuery>;
export type DiagnosisQueryResult = Apollo.QueryResult<DiagnosisQuery, DiagnosisQueryVariables>;
export const TreatmentDocument = gql`
    query Treatment($patientId: String!) {
  treatment(where: {patientId: $patientId}) {
    treatment
  }
}
    `;

/**
 * __useTreatmentQuery__
 *
 * To run a query within a React component, call `useTreatmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useTreatmentQuery(baseOptions: Apollo.QueryHookOptions<TreatmentQuery, TreatmentQueryVariables>) {
        return Apollo.useQuery<TreatmentQuery, TreatmentQueryVariables>(TreatmentDocument, baseOptions);
      }
export function useTreatmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TreatmentQuery, TreatmentQueryVariables>) {
          return Apollo.useLazyQuery<TreatmentQuery, TreatmentQueryVariables>(TreatmentDocument, baseOptions);
        }
export type TreatmentQueryHookResult = ReturnType<typeof useTreatmentQuery>;
export type TreatmentLazyQueryHookResult = ReturnType<typeof useTreatmentLazyQuery>;
export type TreatmentQueryResult = Apollo.QueryResult<TreatmentQuery, TreatmentQueryVariables>;
export const AppointmentsForPatientDocument = gql`
    query AppointmentsForPatient($patientId: String!) {
  appointmentsForPatient(patientId: $patientId) {
    id
    start
    end
    counter
    invoice {
      id
      paid
      options
      prices
      paymentId
    }
    ignore
    cancelled
    patient {
      person {
        fname
        name
      }
    }
  }
}
    `;

/**
 * __useAppointmentsForPatientQuery__
 *
 * To run a query within a React component, call `useAppointmentsForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsForPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useAppointmentsForPatientQuery(baseOptions: Apollo.QueryHookOptions<AppointmentsForPatientQuery, AppointmentsForPatientQueryVariables>) {
        return Apollo.useQuery<AppointmentsForPatientQuery, AppointmentsForPatientQueryVariables>(AppointmentsForPatientDocument, baseOptions);
      }
export function useAppointmentsForPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsForPatientQuery, AppointmentsForPatientQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentsForPatientQuery, AppointmentsForPatientQueryVariables>(AppointmentsForPatientDocument, baseOptions);
        }
export type AppointmentsForPatientQueryHookResult = ReturnType<typeof useAppointmentsForPatientQuery>;
export type AppointmentsForPatientLazyQueryHookResult = ReturnType<typeof useAppointmentsForPatientLazyQuery>;
export type AppointmentsForPatientQueryResult = Apollo.QueryResult<AppointmentsForPatientQuery, AppointmentsForPatientQueryVariables>;
export const AppointmentsForPatientHistoryDocument = gql`
    query AppointmentsForPatientHistory($patientId: String!) {
  appointmentsForPatient(patientId: $patientId) {
    id
    start
    publicNote
  }
}
    `;

/**
 * __useAppointmentsForPatientHistoryQuery__
 *
 * To run a query within a React component, call `useAppointmentsForPatientHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsForPatientHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsForPatientHistoryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useAppointmentsForPatientHistoryQuery(baseOptions: Apollo.QueryHookOptions<AppointmentsForPatientHistoryQuery, AppointmentsForPatientHistoryQueryVariables>) {
        return Apollo.useQuery<AppointmentsForPatientHistoryQuery, AppointmentsForPatientHistoryQueryVariables>(AppointmentsForPatientHistoryDocument, baseOptions);
      }
export function useAppointmentsForPatientHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsForPatientHistoryQuery, AppointmentsForPatientHistoryQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentsForPatientHistoryQuery, AppointmentsForPatientHistoryQueryVariables>(AppointmentsForPatientHistoryDocument, baseOptions);
        }
export type AppointmentsForPatientHistoryQueryHookResult = ReturnType<typeof useAppointmentsForPatientHistoryQuery>;
export type AppointmentsForPatientHistoryLazyQueryHookResult = ReturnType<typeof useAppointmentsForPatientHistoryLazyQuery>;
export type AppointmentsForPatientHistoryQueryResult = Apollo.QueryResult<AppointmentsForPatientHistoryQuery, AppointmentsForPatientHistoryQueryVariables>;
export const AppointmentByIdDocument = gql`
    query AppointmentById($id: String!) {
  appointment(where: {id: $id}) {
    id
    start
    end
    invoice {
      id
      paid
      prices
      paymentId
      options
    }
    ignore
    cancelled
    calendar {
      id
    }
    patient {
      id
      financialPlan {
        id
        options
      }
      person {
        fname
        name
      }
    }
    privateNote
    publicNote
    reoccurringData
  }
}
    `;

/**
 * __useAppointmentByIdQuery__
 *
 * To run a query within a React component, call `useAppointmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentByIdQuery(baseOptions: Apollo.QueryHookOptions<AppointmentByIdQuery, AppointmentByIdQueryVariables>) {
        return Apollo.useQuery<AppointmentByIdQuery, AppointmentByIdQueryVariables>(AppointmentByIdDocument, baseOptions);
      }
export function useAppointmentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentByIdQuery, AppointmentByIdQueryVariables>) {
          return Apollo.useLazyQuery<AppointmentByIdQuery, AppointmentByIdQueryVariables>(AppointmentByIdDocument, baseOptions);
        }
export type AppointmentByIdQueryHookResult = ReturnType<typeof useAppointmentByIdQuery>;
export type AppointmentByIdLazyQueryHookResult = ReturnType<typeof useAppointmentByIdLazyQuery>;
export type AppointmentByIdQueryResult = Apollo.QueryResult<AppointmentByIdQuery, AppointmentByIdQueryVariables>;
export const GetAppointmentsOnDayDocument = gql`
    query GetAppointmentsOnDay($date: Date!) {
  appointmentsOnDate(date: $date) {
    id
    start
    end
    patient {
      person {
        name
        fname
      }
    }
  }
}
    `;

/**
 * __useGetAppointmentsOnDayQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsOnDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsOnDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsOnDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAppointmentsOnDayQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentsOnDayQuery, GetAppointmentsOnDayQueryVariables>) {
        return Apollo.useQuery<GetAppointmentsOnDayQuery, GetAppointmentsOnDayQueryVariables>(GetAppointmentsOnDayDocument, baseOptions);
      }
export function useGetAppointmentsOnDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentsOnDayQuery, GetAppointmentsOnDayQueryVariables>) {
          return Apollo.useLazyQuery<GetAppointmentsOnDayQuery, GetAppointmentsOnDayQueryVariables>(GetAppointmentsOnDayDocument, baseOptions);
        }
export type GetAppointmentsOnDayQueryHookResult = ReturnType<typeof useGetAppointmentsOnDayQuery>;
export type GetAppointmentsOnDayLazyQueryHookResult = ReturnType<typeof useGetAppointmentsOnDayLazyQuery>;
export type GetAppointmentsOnDayQueryResult = Apollo.QueryResult<GetAppointmentsOnDayQuery, GetAppointmentsOnDayQueryVariables>;
export const GetAppointmentsBetweenTwoDatesDocument = gql`
    query GetAppointmentsBetweenTwoDates($startDate: DateTime!, $endDate: DateTime!) {
  appointmentsBetweenTwoDates(startDate: $startDate, endDate: $endDate) {
    id
    start
    end
    calendar {
      id
    }
    cancelled
    appointmentProposal {
      id
      publicNote
    }
    patient {
      person {
        name
        fname
      }
    }
    calendarTitle
    calendarDescription
  }
}
    `;

/**
 * __useGetAppointmentsBetweenTwoDatesQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsBetweenTwoDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsBetweenTwoDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsBetweenTwoDatesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAppointmentsBetweenTwoDatesQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentsBetweenTwoDatesQuery, GetAppointmentsBetweenTwoDatesQueryVariables>) {
        return Apollo.useQuery<GetAppointmentsBetweenTwoDatesQuery, GetAppointmentsBetweenTwoDatesQueryVariables>(GetAppointmentsBetweenTwoDatesDocument, baseOptions);
      }
export function useGetAppointmentsBetweenTwoDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentsBetweenTwoDatesQuery, GetAppointmentsBetweenTwoDatesQueryVariables>) {
          return Apollo.useLazyQuery<GetAppointmentsBetweenTwoDatesQuery, GetAppointmentsBetweenTwoDatesQueryVariables>(GetAppointmentsBetweenTwoDatesDocument, baseOptions);
        }
export type GetAppointmentsBetweenTwoDatesQueryHookResult = ReturnType<typeof useGetAppointmentsBetweenTwoDatesQuery>;
export type GetAppointmentsBetweenTwoDatesLazyQueryHookResult = ReturnType<typeof useGetAppointmentsBetweenTwoDatesLazyQuery>;
export type GetAppointmentsBetweenTwoDatesQueryResult = Apollo.QueryResult<GetAppointmentsBetweenTwoDatesQuery, GetAppointmentsBetweenTwoDatesQueryVariables>;
export const GetPublicAppointmentProposalDocument = gql`
    query GetPublicAppointmentProposal($proposalId: String!) {
  getAppointmentInfoPublic(proposalId: $proposalId) {
    id
    fname
    name
    proposedAppointments {
      date
      id
    }
  }
}
    `;

/**
 * __useGetPublicAppointmentProposalQuery__
 *
 * To run a query within a React component, call `useGetPublicAppointmentProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicAppointmentProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicAppointmentProposalQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useGetPublicAppointmentProposalQuery(baseOptions: Apollo.QueryHookOptions<GetPublicAppointmentProposalQuery, GetPublicAppointmentProposalQueryVariables>) {
        return Apollo.useQuery<GetPublicAppointmentProposalQuery, GetPublicAppointmentProposalQueryVariables>(GetPublicAppointmentProposalDocument, baseOptions);
      }
export function useGetPublicAppointmentProposalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicAppointmentProposalQuery, GetPublicAppointmentProposalQueryVariables>) {
          return Apollo.useLazyQuery<GetPublicAppointmentProposalQuery, GetPublicAppointmentProposalQueryVariables>(GetPublicAppointmentProposalDocument, baseOptions);
        }
export type GetPublicAppointmentProposalQueryHookResult = ReturnType<typeof useGetPublicAppointmentProposalQuery>;
export type GetPublicAppointmentProposalLazyQueryHookResult = ReturnType<typeof useGetPublicAppointmentProposalLazyQuery>;
export type GetPublicAppointmentProposalQueryResult = Apollo.QueryResult<GetPublicAppointmentProposalQuery, GetPublicAppointmentProposalQueryVariables>;
export const GetAppointmentProposalsDocument = gql`
    query getAppointmentProposals {
  appointmentProposals {
    id
    publicNote
    appointments {
      calendarTitle
      patient {
        person {
          fname
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetAppointmentProposalsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentProposalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentProposalsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>) {
        return Apollo.useQuery<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>(GetAppointmentProposalsDocument, baseOptions);
      }
export function useGetAppointmentProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>) {
          return Apollo.useLazyQuery<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>(GetAppointmentProposalsDocument, baseOptions);
        }
export type GetAppointmentProposalsQueryHookResult = ReturnType<typeof useGetAppointmentProposalsQuery>;
export type GetAppointmentProposalsLazyQueryHookResult = ReturnType<typeof useGetAppointmentProposalsLazyQuery>;
export type GetAppointmentProposalsQueryResult = Apollo.QueryResult<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>;
export const GetFinancialPlanForPatientDocument = gql`
    query getFinancialPlanForPatient($patientId: String!) {
  financialPlan(where: {patientId: $patientId}) {
    id
    options
  }
}
    `;

/**
 * __useGetFinancialPlanForPatientQuery__
 *
 * To run a query within a React component, call `useGetFinancialPlanForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialPlanForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialPlanForPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetFinancialPlanForPatientQuery(baseOptions: Apollo.QueryHookOptions<GetFinancialPlanForPatientQuery, GetFinancialPlanForPatientQueryVariables>) {
        return Apollo.useQuery<GetFinancialPlanForPatientQuery, GetFinancialPlanForPatientQueryVariables>(GetFinancialPlanForPatientDocument, baseOptions);
      }
export function useGetFinancialPlanForPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialPlanForPatientQuery, GetFinancialPlanForPatientQueryVariables>) {
          return Apollo.useLazyQuery<GetFinancialPlanForPatientQuery, GetFinancialPlanForPatientQueryVariables>(GetFinancialPlanForPatientDocument, baseOptions);
        }
export type GetFinancialPlanForPatientQueryHookResult = ReturnType<typeof useGetFinancialPlanForPatientQuery>;
export type GetFinancialPlanForPatientLazyQueryHookResult = ReturnType<typeof useGetFinancialPlanForPatientLazyQuery>;
export type GetFinancialPlanForPatientQueryResult = Apollo.QueryResult<GetFinancialPlanForPatientQuery, GetFinancialPlanForPatientQueryVariables>;
export const GetCalendarsDocument = gql`
    query getCalendars {
  calendars {
    id
    name
    bgColor
    fgColor
  }
}
    `;

/**
 * __useGetCalendarsQuery__
 *
 * To run a query within a React component, call `useGetCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalendarsQuery(baseOptions?: Apollo.QueryHookOptions<GetCalendarsQuery, GetCalendarsQueryVariables>) {
        return Apollo.useQuery<GetCalendarsQuery, GetCalendarsQueryVariables>(GetCalendarsDocument, baseOptions);
      }
export function useGetCalendarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarsQuery, GetCalendarsQueryVariables>) {
          return Apollo.useLazyQuery<GetCalendarsQuery, GetCalendarsQueryVariables>(GetCalendarsDocument, baseOptions);
        }
export type GetCalendarsQueryHookResult = ReturnType<typeof useGetCalendarsQuery>;
export type GetCalendarsLazyQueryHookResult = ReturnType<typeof useGetCalendarsLazyQuery>;
export type GetCalendarsQueryResult = Apollo.QueryResult<GetCalendarsQuery, GetCalendarsQueryVariables>;
export const VariablesForPartOfDocument = gql`
    query variablesForPartOf($partOf: String!) {
  variablesForPartOf(partOf: $partOf) {
    id
    name
    values
  }
}
    `;

/**
 * __useVariablesForPartOfQuery__
 *
 * To run a query within a React component, call `useVariablesForPartOfQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariablesForPartOfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariablesForPartOfQuery({
 *   variables: {
 *      partOf: // value for 'partOf'
 *   },
 * });
 */
export function useVariablesForPartOfQuery(baseOptions: Apollo.QueryHookOptions<VariablesForPartOfQuery, VariablesForPartOfQueryVariables>) {
        return Apollo.useQuery<VariablesForPartOfQuery, VariablesForPartOfQueryVariables>(VariablesForPartOfDocument, baseOptions);
      }
export function useVariablesForPartOfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariablesForPartOfQuery, VariablesForPartOfQueryVariables>) {
          return Apollo.useLazyQuery<VariablesForPartOfQuery, VariablesForPartOfQueryVariables>(VariablesForPartOfDocument, baseOptions);
        }
export type VariablesForPartOfQueryHookResult = ReturnType<typeof useVariablesForPartOfQuery>;
export type VariablesForPartOfLazyQueryHookResult = ReturnType<typeof useVariablesForPartOfLazyQuery>;
export type VariablesForPartOfQueryResult = Apollo.QueryResult<VariablesForPartOfQuery, VariablesForPartOfQueryVariables>;
export const GoogleApiDocument = gql`
    query GoogleAPI {
  generateGoogleCalendarUrl
}
    `;

/**
 * __useGoogleApiQuery__
 *
 * To run a query within a React component, call `useGoogleApiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleApiQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleApiQuery(baseOptions?: Apollo.QueryHookOptions<GoogleApiQuery, GoogleApiQueryVariables>) {
        return Apollo.useQuery<GoogleApiQuery, GoogleApiQueryVariables>(GoogleApiDocument, baseOptions);
      }
export function useGoogleApiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleApiQuery, GoogleApiQueryVariables>) {
          return Apollo.useLazyQuery<GoogleApiQuery, GoogleApiQueryVariables>(GoogleApiDocument, baseOptions);
        }
export type GoogleApiQueryHookResult = ReturnType<typeof useGoogleApiQuery>;
export type GoogleApiLazyQueryHookResult = ReturnType<typeof useGoogleApiLazyQuery>;
export type GoogleApiQueryResult = Apollo.QueryResult<GoogleApiQuery, GoogleApiQueryVariables>;
export const GetReportDataDocument = gql`
    query getReportData($id: String!) {
  diagnosis(where: {patientId: $id}) {
    diagnosis
  }
  treatment(where: {patientId: $id}) {
    treatment
  }
  patient(where: {id: $id}) {
    person {
      name
      fname
      bdate
      gender
      address {
        street
        city {
          name
          postalCode
        }
        houseNumbering
      }
    }
    dentist {
      person {
        fname
        name
        address {
          street
          houseNumbering
          city {
            name
            postalCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetReportDataQuery__
 *
 * To run a query within a React component, call `useGetReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportDataQuery(baseOptions: Apollo.QueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables>) {
        return Apollo.useQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, baseOptions);
      }
export function useGetReportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportDataQuery, GetReportDataQueryVariables>) {
          return Apollo.useLazyQuery<GetReportDataQuery, GetReportDataQueryVariables>(GetReportDataDocument, baseOptions);
        }
export type GetReportDataQueryHookResult = ReturnType<typeof useGetReportDataQuery>;
export type GetReportDataLazyQueryHookResult = ReturnType<typeof useGetReportDataLazyQuery>;
export type GetReportDataQueryResult = Apollo.QueryResult<GetReportDataQuery, GetReportDataQueryVariables>;