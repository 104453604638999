import React from "react";
import FormMUI from "@rjsf/material-ui";
import { FormProps, ObjectFieldTemplateProps } from "@rjsf/core";
import { Button, Grid, Typography } from "@material-ui/core";
interface Props<T> extends FormProps<T> {
  buttonName?: string;
  children?: React.ReactNode;
}

function columnsObjectFieldTemplate({
  properties,
  description,
  title,
}: ObjectFieldTemplateProps) {
  return (
    <div>
      <Typography variant={"h5"}>{title}</Typography>
      <Grid container>
        {properties.map((prop: any) => {
          const uiSchema = prop.content.props.uiSchema;
          return (
            <Grid item key={prop.content.key} md={uiSchema["ui:column"] || 12}>
              {prop.content}
            </Grid>
          );
        })}
      </Grid>
      {description}
    </div>
  );
}

function Form<T>(props: Props<T>) {
  return (
    <FormMUI ObjectFieldTemplate={columnsObjectFieldTemplate} {...props}>
      {props.children ?? (
        <Button color="primary" variant="contained" type={"submit"}>
          {props.buttonName ?? "Verstuur"}
        </Button>
      )}
    </FormMUI>
  );
}
export default Form;
