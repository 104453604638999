import React, { useEffect, useState } from "react";
import {
  usePatientQuery,
  useUpdatePatientMutation,
} from "../../generated/graphql";
import {
  mapFormDataToUpdatePatientInput,
  mapPatientQueryToFormData,
} from "../Forms/FormSchemas/PatientSchema";
import PatientForm from "../Forms/PatientForm";
import { Button, CircularProgress, DialogActions } from "@material-ui/core";
import { PersonalCard } from "./PersonalCard";
import FullScreenDialog from "../FullScreenDialog";
import { NewAppointmentPatientIdDialog } from "../Appointments/NewAppointmentPatientId";
interface Props {
  patientId: string;
}
export const BasicInformation = ({ patientId }: Props) => {
  const { data, loading } = usePatientQuery({
    variables: { id: patientId },
    nextFetchPolicy: "network-only",
  });
  const [updatePatient, resultsUpdate] = useUpdatePatientMutation();
  const [formData, setFormData] = useState<any>(null);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData(mapPatientQueryToFormData(data));
    }
  }, [data]);

  if (data && !data.patient) {
    return <p>Geen patient gevonden!</p>;
  }
  return (
    <>
      <PersonalCard patientData={data?.patient}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Button
            variant={"outlined"}
            onClick={() => {
              setEdit(true);
            }}
            style={{ marginRight: "1em" }}
          >
            Pas patiënt aan
          </Button>
        </div>

        {/* TODO Better integration here */}
        {/*<NewAppointmentPatientIdDialog patientId={patientId} />*/}
      </PersonalCard>
      {loading && ( //  || resultsUpdate.loading
        <div>
          <CircularProgress />
        </div>
      )}
      <FullScreenDialog
        handleClose={() => setEdit(false)}
        open={edit}
        title={"Pas patiënt aan"}
      >
        {/*{resultsUpdate.data && <p>Succesvol patiënt aangepast!</p>}*/}
        {data && !resultsUpdate.loading ? (
          <PatientForm
            loading={resultsUpdate.loading}
            formData={formData}
            onChange={(e) => setFormData(e.formData)}
            onSubmit={(e, mapped) => {
              const variables = mapFormDataToUpdatePatientInput(
                e.formData,
                patientId
              );
              updatePatient(variables);
            }}
          />
        ) : (
          <CircularProgress />
        )}
      </FullScreenDialog>
    </>
  );
};
