import React from "react";
import Calendar from "../../components/Calendar";

export const CalendarPage = () => {
  return (
    <div style={{width: "90vw"}}>
      {/*<h1>Agenda</h1>*/}
      <Calendar />
    </div>
  );
};
