import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  useAppointmentByIdQuery,
  useUpdateAppointmentByIdMutation,
  useVariablesQuery,
} from "../../generated/graphql";
import { Button, CircularProgress, Grid, GridList, GridListTile, Paper } from "@material-ui/core";
import Form from "../../components/Forms/Form";
import DateTimePickerWidget from "../../components/Forms/JsonSchemaFormWidgets/DateTimePickerWidget";
import moment from "moment";
import { DiagnosisTreatment, sortVariables, TreatmentFields } from "../../components/Diagnosis-Treatment/DiagnosisTreatment";
import AppointmentHistory from "../../components/Appointments/AppointmentHistory";
import { AutoCompleteMultipleSelect } from "../../components/Forms/JsonSchemaFormWidgets/AutoCompleteMultipleSelect";
import { GenericTable } from "../../components/Tables/GenericSelectTable";
import dateFormat from "dateformat";
import { WidgetProps } from "@rjsf/core";
import OverviewForPatient from "../../components/Invoice/OverviewForPatient";
import { AutoCompleteCalendarWidget } from "../../components/Forms/JsonSchemaFormWidgets/AutoCompleteCalendarWidget";
import { undefinedOrSetInput } from "../../components/Forms/FormSchemas/PatientSchema";

function generateNumberList(
  lower: number,
  higher: number,
  prefix?: string,
  suffix?: string
) {
  let result = [];
  while (!(lower > higher)) {
    result.push(`${prefix ?? ""}${lower}${suffix ?? ""}`);
    lower += 1;
  }
  return result;
}

const wireOptions = [
  "niti012",
  "niti014",
  "niti016",
  "niti016/016",
  "niti016/022",
  "niti019/025",
  "niti016 reverse curve",
  "niti016/022 reverse curve",
  "rst016",
  "rst016/016",
  "rst016/022",
  "rst019/025",
];
export const Appointment: React.FC<RouteComponentProps<{
  appointmentId: string;
}>> = ({ match }) => {
  const { data, loading, refetch } = useAppointmentByIdQuery({
    variables: { id: match.params.appointmentId },
  });
  const [updateAppointment, resultUpdate] = useUpdateAppointmentByIdMutation({
    refetchQueries: ["AppointmentsForPatient"],
  });
  if (loading || resultUpdate.loading)
    return (
      <div>
        <p>Loading...</p>
        <CircularProgress />
      </div>
    );
  if (!data?.appointment) {
    return <p>Geen afspraak gevonden...</p>;
  }

  const Treatment = (props: {patientId: string}) => {

    const {data} = useVariablesQuery();
    
    return (<Paper style={{ padding: "3em" }}>
    <TreatmentFields
      patientId={props.patientId}
      variables={
        sortVariables("Behandelingsplan", data?.variables)
      }
    />
  </Paper>)
  }

  const {
    reoccurringData: rawReoccuringData,
    id,
    patient,
    privateNote,
    publicNote,
    start,
    end,
    ignore,
    invoice,
    calendar,
  } = data.appointment;
  const reoccurringData = JSON.parse(rawReoccuringData ?? "{}");
  let loadedOptions: any[] = [];
  if (invoice?.options && invoice?.prices) {
    loadedOptions = invoice.options.map((o, index) => ({
      id: index,
      name: o,
      price: invoice.prices[index],
    }));
  }
  const parsedPublicNote: { upper?: string; lower?: string } = publicNote
    ? JSON.parse(publicNote)
    : {};
  return (
    <>
      <h1>{`Afspraak met ${patient.person.fname} ${patient.person.name}`}</h1>
      <p>
        {`${moment(start).format("DD/MM/yyyy HH:mm")} tot ${moment(end).format(
          "DD/MM/yyyy HH:mm"
        )}`}
      </p>
      <Link to={`/patient/${patient.id}`}>Ga terug naar patiënt</Link>
      
      <GridList style={{height: "50vh"}} >
        <Grid item>
        <GridListTile>
          <AppointmentHistory
            patientId={patient.id}
            currentAppointmentId={id}
            currentAppointmentDate={start}
          />
        </GridListTile>
        </Grid>
        <Grid item style={{width: "50%"}}>
          <Treatment patientId={patient.id}></Treatment>
        </Grid>
        
      </GridList>
      
      {/*<Grid container>*/}
      {/*  <Grid item sm={6}>*/}
      <Form
        onSubmit={async ({ formData }: any) => {
          const variables: { name: string; price: string }[] = JSON.parse(
            formData.option || "[]"
          );
          const options = variables.map((i) => i.name);
          const prices = variables.map((i) => Number(i.price));
          console.log(formData.option, variables, options, prices);
          let invoiceData = {};
          if (formData.freeApp) {
            if (invoice?.id) {
              // todo should be deleted
              invoiceData = {
                invoice: {
                  delete: true,
                },
              };
            }
          } else {
            if (invoice?.id) {
              invoiceData = {
                invoice: {
                  update: {
                    id: { set: invoice.id },
                    options: { set: options },
                    prices: { set: prices },
                  },
                },
              };
            } else if (formData.option) {
              invoiceData = {
                invoice: {
                  create: {
                    options: { set: options },
                    prices: { set: prices },
                  },
                },
              };
            }
          }
          await updateAppointment({
            variables: {
              id,
              data: {
                // TODO UPDATE DATE
                start: undefinedOrSetInput(formData.startDateTime),
                end: undefinedOrSetInput(formData.endDateTime),
                privateNote: undefinedOrSetInput(formData.privateNote),
                publicNote: {
                  set: JSON.stringify({
                    upper: formData.publicNoteUpper,
                    lower: formData.publicNoteLower,
                  }),
                },
                calendar: formData.calendar
                  ? { connect: { id: formData.calendar } }
                  : undefined,
                ignore: { set: !!formData.freeApp },
                ...invoiceData,
                reoccurringData: undefinedOrSetInput(
                  JSON.stringify({
                    wireWidthLower: formData.wireWidthLower,
                    wireWidthUpper: formData.wireWidthUpper,
                  })
                ),
              },
            },
          });
          await refetch();
        }}
        buttonName={"Opslaan"}
        uiSchema={{
          "ui:order": [
            "*",
            "showInternalNote",
            "privateNote",
            "freeApp",
            "option",
          ],
          privateNote: {
            "ui:widget": "textarea",
          },
          publicNote: {
            "ui:widget": "textarea",
          },
          endDateTime: {
            "ui:widget": DateTimePickerWidget,
          },
          startDateTime: {
            "ui:widget": DateTimePickerWidget,
          },
          calendar: {
            "ui:widget": AutoCompleteCalendarWidget,
          },
          option: {
            "ui:widget": (props: WidgetProps) =>
              AutoCompleteMultipleSelect(
                props,
                Object.entries(
                  JSON.parse(patient.financialPlan?.options ?? "{}")
                ).map(([k, v], i) => ({
                  id: i.toString(),
                  name: k,
                  price: v,
                }))
              ),
            // TODO Better way of passing these...
          },
        }}
        formData={{ option: JSON.stringify(loadedOptions) }}
        schema={{
          type: "object",
          properties: {
            startDateTime: {
              type: "string",
              title: "Start",
              default: start,
            },
            endDateTime: {
              type: "string",
              title: "Einde",
              default: end,
            },
            calendar: {
              type: "string",
              title: "Kleurcode - agenda",
              default: calendar?.id ?? undefined,
            },
            publicNoteUpper: {
              type: "string",
              title: "Notitie bovenkaak",
              default: parsedPublicNote.upper ?? "",
            },
            publicNoteLower: {
              type: "string",
              title: "Notitie onderkaak",
              default: parsedPublicNote.lower ?? "",
            },
            wireWidthUpper: {
              type: "string",
              title: "Draaddikte bovenkaak",
              default: reoccurringData?.wireWidthUpper ?? undefined,
              enum: wireOptions,
            },
            wireWidthLower: {
              type: "string",
              title: "Draaddikte onderkaak",
              default: reoccurringData?.wireWidthLower ?? undefined,
              enum: wireOptions,
            },
            showInternalNote: {
              type: "boolean",
              title: privateNote? "Toon extra info": "Voeg extra info toe",
              default: false,
            },
            freeApp: {
              type: "boolean",
              title: "Niet aangerekend",
              default: ignore,
            },
          },
          dependencies: {
            freeApp: {
              oneOf: [
                {
                  properties: {
                    freeApp: {
                      enum: [true],
                    },
                  },
                },
                {
                  properties: {
                    freeApp: {
                      enum: [false],
                    },
                    option: {
                      type: "string",
                      title: "Aan te rekenen...",
                    },
                  },
                },
              ],
            },
            showInternalNote: {
              oneOf: [
                {
                  properties: {
                    showInternalNote: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    showInternalNote: {
                      enum: [true],
                    },
                    privateNote: {
                      title: "Interne notitie",
                      type: "string",
                      default: privateNote ?? "",
                    },
                  },
                },
              ],
            },
          },
        }}
      />
      {/*</Grid>*/}
      {/*<Grid item sm={6}>*/}
      {/*  <p>Foto's van patient</p>*/}
      {/*</Grid>*/}
      {/*</Grid>*/}
      <h1>Financieel overzicht</h1>
      <Link to={`/patient/${patient.id}/financial`}>Financieel plan</Link>
      <OverviewForPatient patientId={patient.id} />
      <h1>Fiche</h1>
      <DiagnosisTreatment patientId={patient.id} />
    </>
  );
};
