import React, { useEffect } from "react";
import { WidgetProps } from "@rjsf/core";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

interface Props {
  widgetProps: WidgetProps;
  options?: string[];
  multilineInput?: boolean;
}
export const AutoCompleteEditableSelectInput = (props: {
  label?: string;
  required?: boolean;
  value: string;
  id: string | number;
  options: string[];
  onChange: (value?: string) => void;
}) => {
  const { id, options, label, required } = props;
  const [value, setValue] = React.useState<string | undefined>("");
  useEffect(() => {
    // console.log(widgetProps.value);
    if (value !== props.value && props.value) {
      setValue(props.value);
    }
  }, [props.value]);
  return (
    <Autocomplete
      id={`autocomplete-editableSelect-${id}`}
      multiple={false}
      freeSolo
      value={value}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
        props.onChange(newInputValue === "" ? undefined : newInputValue);
      }}
      style={value ? { color: "orangered important!" } : undefined}
      options={options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          // id={widgetProps.id}
        />
      )}
    />
  );
};
export const AutoCompleteEditableSelectInputWidget = (props: Props) => {
  const { widgetProps, options, multilineInput=false} = props;
  const [value, setValue] = React.useState<string | undefined>("");
  useEffect(() => {
    // console.log(widgetProps.value);
    if (value !== widgetProps.value && widgetProps.value) {
      setValue(widgetProps.value);
    }
  }, [widgetProps.value]);
  return (
    <Autocomplete
      id={`autocomplete-editableSelect-${props.widgetProps.id}`}
      multiple={false}
      freeSolo
      value={value}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
        props.widgetProps.onChange(
          newInputValue === "" ? undefined : newInputValue
        );
      }}
      options={options ?? []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={widgetProps.label}
          multiline={multilineInput}
          required={widgetProps.required}
          // id={widgetProps.id}
        />
      )}
    />
  );
};
