import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  useGetFinancialPlanForPatientQuery,
  useUpdatePatientMutation,
  useVariablesForPartOfQuery,
} from "../../../generated/graphql";

import MaterialTable from "../../../components/MaterialTable";
import { Column } from "material-table";
import { AutoCompleteEditableSelectInput } from "../../../components/Forms/JsonSchemaFormWidgets/AutoCompleteEditableSelectInputWidget";
import { undefinedOrSetInput } from "../../../components/Forms/FormSchemas/PatientSchema";

export interface FinancialPlanOption {
  // id: string;
  name: string;
  price: number;
}
const FinancialPlanTable = ({ patientId }: { patientId: string }) => {
  const { data, loading } = useGetFinancialPlanForPatientQuery({
    variables: { patientId },
  });
  const { data: variablesQry } = useVariablesForPartOfQuery({
    variables: { partOf: "FinancialPlan" },
  });
  const [standardOptions, setStandardOptions] = useState<string[]>([]);

  useEffect(() => {
    if (
      variablesQry?.variablesForPartOf &&
      variablesQry.variablesForPartOf[0]
    ) {
      console.log(variablesQry);
      setStandardOptions(
        variablesQry.variablesForPartOf.map((o) =>
          o!.values.map((i) => i.toString())
        )[0]
      );
    }
  }, [variablesQry]);
  const columns: Column<any>[] = useMemo(() => {
    return [
      {
        title: "Naam",
        field: "name",
        editComponent: (props) => (
          <AutoCompleteEditableSelectInput
            id={`autocomplete-editselect-financialplan-${props.rowData.id}`}
            onChange={props.onChange}
            options={standardOptions}
            value={props.value}
          />
        ),
      },
      {
        title: "Prijs",
        field: "price",
        type: "numeric",
        render: (data1) => `€${data1.price}`,
      },
    ];
  }, [standardOptions]);

  const [options, setOptions] = useState<FinancialPlanOption[]>([]);
  const [updatePatient, updateResult] = useUpdatePatientMutation();

  const handleDelete = async (oldData: FinancialPlanOption) => {
    const afterDeletion = options.filter((o) => o.name !== oldData.name);
    let mappedOptions = Object.fromEntries(
      afterDeletion.map((o) => [o.name, o.price])
    );

    await updatePatient({
      variables: {
        id: patientId,
        data: {
          financialPlan: {
            update: {
              options: undefinedOrSetInput(JSON.stringify(mappedOptions)),
            },
          },
        },
      },
    });

    setOptions(afterDeletion);
  };
  const handleUpdate = async (
    rowData: FinancialPlanOption,
    oldData?: FinancialPlanOption
  ) => {
    let filtered = oldData
      ? options.filter((o) => o.name !== oldData.name)
      : options;
    let mappedObject = Object.fromEntries(
      filtered.map((o) => [o.name, o.price])
    );
    mappedObject[rowData.name] = rowData.price;

    await updatePatient({
      variables: {
        id: patientId,
        data: {
          financialPlan: {
            update: {
              options: undefinedOrSetInput(JSON.stringify(mappedObject)),
            },
          },
        },
      },
    });

    setOptions(
      Object.entries(mappedObject).map(([k, v]) => ({ name: k, price: v }))
    );
  };
  const handleCreate = async (rowData: FinancialPlanOption) => {
    let mappedObject = Object.fromEntries(
      options.map((o) => [o.name, o.price])
    );
    mappedObject[rowData.name] = rowData.price;

    await updatePatient({
      variables: {
        id: patientId,
        data: {
          financialPlan: {
            upsert: {
              update: {
                options: undefinedOrSetInput(JSON.stringify(mappedObject)),
              },
              create: { options: JSON.stringify(mappedObject) },
            },
          },
        },
      },
    });

    setOptions(
      Object.entries(mappedObject).map(([k, v]) => ({ name: k, price: v }))
    );
  };
  useEffect(() => {
    if (data?.financialPlan?.options) {
      const rows = Object.entries(JSON.parse(data.financialPlan.options)).map(
        ([k, v]) => ({
          name: k,
          price: v as number,
        })
      );
      setOptions(rows);
    }
  }, [data]);
  return (
    <MaterialTable
      title={"Financieel plan"}
      columns={columns}
      data={options}
      editable={{
        onRowAdd: async (newData) => await handleCreate(newData),
        onRowUpdate: async (newData, oldData) =>
          await handleUpdate(newData, oldData),
        onRowDelete: (oldData) => handleDelete(oldData),
      }}
    />
  );
};
export const FinancialPlanPage: React.FunctionComponent<RouteComponentProps<{
  patientId: string;
}>> = (props) => {
  return (
    <>
      <h1>Financieel plan</h1>
      <p>
        Geef hier de opties in waaruit je kan kiezen bij de afspraak als
        afrekening. Voorbeelden: risiv nummers of bij niet terugbetaalde
        afspraken Consultatie
      </p>
      <FinancialPlanTable patientId={props.match.params.patientId} />
    </>
  );
};
