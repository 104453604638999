import React, { useEffect, useRef } from "react";
import { useCreatePatientMutation } from "../../generated/graphql";
import PatientForm from "../Forms/PatientForm";
import { CircularProgress } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { MINIMAL_PATIENTS_QUERY } from "../../queries";

const NewPatient = () => {
  const [createPatient, { loading, data }] = useCreatePatientMutation({
    refetchQueries: [{ query: MINIMAL_PATIENTS_QUERY }],
  });
  const myRef = useRef<null | HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (myRef && location.hash.includes("#new_patient")) {
      if (myRef.current) {
        myRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center",
        });
      }
    }
  }, [myRef, location.hash]);

  return (
    <div style={{ paddingTop: "4em" }} ref={myRef}>
      <h1>Maak nieuwe patient aan</h1>
      {!loading && !data && (
        <PatientForm
          onSubmit={(e, mapped) => {
            console.log(mapped);
            createPatient(mapped);
          }}
        />
      )}
      {loading && (
        <div>
          <CircularProgress />
        </div>
      )}
      {data && (
        <p>
          Patient toegevoegd!{" "}
          <Link to={`/patient/${data.createOnePatient.id}`}>Bekijk pagina</Link>
        </p>
      )}
    </div>
  );
};

export default NewPatient;
