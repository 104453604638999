import { UiSchema, WidgetProps } from "@rjsf/core";

import { JSONSchema7 } from "json-schema";
import AutoCompleteSelectAndCreateNewDialogWidget from "../JsonSchemaFormWidgets/AutoCompleteSelectAndCreateNewDialogWidget";
import {
  CountriesQuery,
  Country,
  CreateCountryMutationVariables,
  useCountriesQuery,
  useCreateCountryMutation,
} from "../../../generated/graphql";
import { haveMatchingIds, selectId } from "./Utils";

export const countryUiSchema: UiSchema = {};
export interface CountryFormData {
  name: string;
}
export const countrySchema: JSONSchema7 = {
  title: "Country form",
  description: "A simple form example.",
  type: "object",
  required: ["name"],
  properties: {
    name: {
      type: "string",
      title: "Naam",
    },
  },
};

function mapCountryFormToCreate(
  formData: CountryFormData
): CreateCountryMutationVariables {
  return {
    data: { name: formData.name },
  };
}

export const countryAutoComplete = (props: WidgetProps) =>
  AutoCompleteSelectAndCreateNewDialogWidget<Country, CountriesQuery>(props, {
    schema: countrySchema,
    uiSchema: countryUiSchema,
    queryHook: useCountriesQuery,
    mutationHook: useCreateCountryMutation,
    getDataFromQryResult: (data: CountriesQuery) => data.countries,
    getOptionsDoMatch: haveMatchingIds,
    getValueToSubmit: selectId,
    getTextToShowOptionInList: (option: Country) => option.name,
    convertFormDataToMutationVariables: mapCountryFormToCreate,
    defaultValueFieldName: "name",
  });
