import { useSendConfirmationMailMutation } from "../../generated/graphql";
import { Button } from "@material-ui/core";
import React from "react";

const SendConfirmationButton = ({
  appointmentId,
}: {
  appointmentId: string;
}) => {
  const [sendMail, results] = useSendConfirmationMailMutation();
  return (
    <Button
      onClick={() => {
        if (!results.loading && !results.data)
          sendMail({ variables: { appointmentId } });
      }}
    >
      {results.loading
        ? "Bezig met versturen..."
        : results.data
        ? "Verstuurd!"
        : "Stuur bevestigingsmail"}
    </Button>
  );
};
export default SendConfirmationButton;
