import { gql } from "@apollo/client";

export const GET_APPOINTMENT_PROPOSAL = gql`
query getAppointmentProposal($id: String!){
  appointmentProposal(where: {id: $id}) {
    id
    appointments {
      start
    }
  }
}`
export const GET_PEOPLE_QUERY = gql`
  query People {
    people {
      name
      fname
      id
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      email
    }
  }
`;

export const DENTISTS_QUERY = gql`
  query Dentists {
    dentists {
      id
      person {
        fname
        name
      }
    }
  }
`;

export const SCHOOLS_QUERY = gql`
  query Schools {
    schools {
      id
      name
    }
  }
`;
export const COUNTRIES_QUERY = gql`
  query Countries {
    countries {
      id
      name
    }
  }
`;
export const CITIES_QUERY = gql`
  query Cities {
    cities {
      id
      name
      postalCode
    }
  }
`;

export const MINIMAL_PATIENTS_QUERY = gql`
  query PatientsMinimalInfo {
    patients {
      id
      person {
        id
        fname
        name
        bdate
      }
    }
  }
`;

const PATIENT_QUERY = gql`
  query Patient($id: String!) {
    patient(where: { id: $id }) {
      id
      insRefund
      disease
      boxId
      school {
        id
        name
      }
      dentist {
        id
        person {
            emails
        }
      }
      person {
        id
        fname
        name
        bdate
        gender
        language
        phones
        emails
        address {
          id
          street
          sndLine
          houseNumbering
          city {
            id
          }
          country {
            id
          }
        }
      }
    }
  }
`;

export const VARIABLES_QUERY = gql`
  query Variables {
    variables {
      name
      id
      values
      partOf
    }
  }
`;

export const DIAGNOSIS_QUERY = gql`
  query Diagnosis($patientId: String!) {
    diagnosis(where: { patientId: $patientId }) {
      diagnosis
    }
  }
`;
export const TREATMENT_QUERY = gql`
  query Treatment($patientId: String!) {
    treatment(where: { patientId: $patientId }) {
      treatment
    }
  }
`;
export const APPOINTMENTs_PATIENT_QUERY = gql`
  query AppointmentsForPatient($patientId: String!) {
    appointmentsForPatient(patientId: $patientId) {
      id
      start
      end
      counter
      invoice {
        id
        paid
        options
        prices
        paymentId
      }
      ignore
      cancelled
      patient {
        person {
          fname
          name
        }
      }
    }
  }
`;

export const APPOINTMENTs_PATIENT_HISTORY_QUERY = gql`
  query AppointmentsForPatientHistory($patientId: String!) {
    appointmentsForPatient(patientId: $patientId) {
      id
      start
      publicNote
    }
  }
`;

export const APPOINTMENT_QRY_BY_ID = gql`
  query AppointmentById($id: String!) {
    appointment(where: { id: $id }) {
      id
      start
      end
      invoice {
        id
        paid
        prices
        paymentId
        options
      }
      ignore
      cancelled
      calendar {
        id
      }
      patient {
        id
        financialPlan {
          id
          options
        }
        person {
          fname
          name
        }
      }
      privateNote
      publicNote
      reoccurringData
    }
  }
`;

export const APPOINTMENTS_BY_DAY = gql`
  query GetAppointmentsOnDay($date: Date!) {
    appointmentsOnDate(date: $date) {
      id
      start
      end
      patient {
        person {
          name
          fname
        }
      }
    }
  }
`;

export const APPOINTMENTS_BETWEEN_TWO_DATES = gql`
  query GetAppointmentsBetweenTwoDates(
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    appointmentsBetweenTwoDates(startDate: $startDate, endDate: $endDate) {
      id
      start
      end
      calendar {
        id
      }
      cancelled
      appointmentProposal {
        id
        publicNote
      }
      patient {
        person {
          name
          fname
        }
      }
      calendarTitle
      calendarDescription
    }
  }
`;

const PUBLIC_APPOINTMENT_PROPOSAL = gql`
  query GetPublicAppointmentProposal($proposalId: String!) {
    getAppointmentInfoPublic(proposalId: $proposalId) {
      id
      fname
      name
      proposedAppointments {
        date
        id
      }
    }
  }
`;

export const APPOINTMENT_PROPOSALS = gql`
  query getAppointmentProposals {
    appointmentProposals {
      id
      publicNote
      appointments {
        calendarTitle
        patient {
          person {
            fname
            name
          }
        }
      }
    }
  }
`;

const FinancialPlanByPatientId = gql`
  query getFinancialPlanForPatient($patientId: String!) {
    financialPlan(where: { patientId: $patientId }) {
      id
      options
    }
  }
`;

const Calendars = gql`
  query getCalendars {
    calendars {
      id
      name
      bgColor
      fgColor
    }
  }
`;

const VariablesByPartOf = gql`
  query variablesForPartOf($partOf: String!) {
    variablesForPartOf(partOf: $partOf) {
      id
      name
      values
    }
  }
`;

const GOOGLE_API = gql`
  query GoogleAPI {
    generateGoogleCalendarUrl
  }
`;

export const REPORT_QRY = gql`
  query getReportData($id: String!) {
    diagnosis(where: { patientId: $id }) {
      diagnosis
    }
    treatment(where: { patientId: $id }) {
      treatment
    }
    patient(where: { id: $id }) {
      person {
        name
        fname
        bdate
        gender
        address {
          street
          city {
            name
            postalCode
          }
          houseNumbering
        }
      }
      dentist {
        person {
          fname
          name
          address {
            street
            houseNumbering
            city {
              name
              postalCode
            }
          }
        }
      }
    }
  }
`;
