import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { WidgetProps } from "@rjsf/core";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const RadioSelectWidget = <T extends any>(
  props: WidgetProps & {
    providedOptions: T[];
    getValue: (option: T) => string | null;
    getLabel: (option: T) => string | ReactNode;
  }
) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        aria-label={props.label}
        name={props.id}
        defaultValue={props.defaultValue}
        value={props.providedOptions.find(
          (o) => props.getValue(o) === props.value
        )}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {props.providedOptions.map((opt, i) => (
          <FormControlLabel
            key={`RadioSelect-${props.id}-option-${i}`}
            disabled={props.disabled}
            value={props.getValue(opt)}
            control={<Radio />}
            label={props.getLabel(opt)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default RadioSelectWidget;
