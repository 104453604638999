export interface BaseModel {
  id: string;
}

export function haveMatchingIds<T extends BaseModel>(item1: T, item2: T) {
  return item1.id === item2.id;
}

export function selectId<T extends BaseModel>(item: T | null) {
  return item?.id;
}
