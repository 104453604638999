import { UiSchema } from "@rjsf/core";

import { JSONSchema7 } from "json-schema";
import { Nullable } from "../../../BasicTypes/Nullable";
import { countryAutoComplete } from "./CountrySchema";
import { cityAutoComplete } from "./CitySchema";

export const AddressUiSchema: UiSchema = {
  countryId: {
    "ui:widget": countryAutoComplete,
  },
  cityId: {
    "ui:widget": cityAutoComplete,
  },
};

export interface AddressFormData {
  addressId?: string;
  street?: Nullable<string>;
  houseNumbering?: Nullable<string>;
  sndLine?: Nullable<string>;
  countryId?: Nullable<string>;
  cityId?: Nullable<string>;
}

export const AddressSchema: JSONSchema7 = {
  type: "object",
  properties: {
    street: { type: ["string", "null"], title: "Straat" },
    houseNumbering: { type: ["string", "null"], title: "Huisnummer" },
    sndLine: { type: ["string", "null"], title: "Tweede adres" },
    cityId: { type: ["string", "null"], title: "Stad" },
    countryId: { type: ["string", "null"], title: "Land" },
  },
};
