import React, { useEffect, useState } from "react";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { WidgetProps, Widget } from "@rjsf/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Icon } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
const TimePickerWidget: Widget = (props: WidgetProps) => {
  const [
    selectedDate,
    setSelectedDate,
  ] = React.useState<null | MaterialUiPickersDate>(null);
  const [originalDate, setOriginalDate] = useState<MaterialUiPickersDate>();

  useEffect(() => {
    // This is necessary to reset field when form submits; otherwise value does not disappear!
    // if (selectedDate?.toISOString() !== props.value) {
    //   setSelectedDate(props.value);
    //   return;
    // }
    if (props.value) {
      // Only set the date the first time!
      // TODO Check if cleaner solution is possible
      if (!originalDate) setOriginalDate(moment(props.value));
      if (selectedDate?.toISOString() === props.value) {
        // No difference from props ==> no change
        return;
      }
      // selected date is null or difference
      console.log(
        "Selected date is null or values differ, but received value!",
        props.value,
        selectedDate?.toISOString()
      );
      setSelectedDate(moment(props.value));
      return;
    }
    // Prop is null or undefined
    if (props.value === undefined) {
      setSelectedDate(null);
    }
  }, [props.value, selectedDate]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date?.isValid()) {
      // Should change the hour of the given date
      if (!originalDate) throw new Error("Should always have a date");
      const cp = originalDate.clone();
      cp.set({ hour: date.get("hour"), minute: date.get("minute") });
      // MUST BE A STRING
      props.onChange(cp.toISOString());
    } else {
      setSelectedDate(date);
      // Date not valid...
      props.onChange(date?.toISOString());
    }
    console.log("handledatechange", date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        // margin="normal"
        variant={"inline"}
        required={props.required}
        id={`${props.id}-time`}
        label={props.label}
        format="HH:mm"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        keyboardIcon={<Icon component={ScheduleIcon} />}
      />
    </MuiPickersUtilsProvider>
  );
};
export default TimePickerWidget;
