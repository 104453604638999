import React from "react";
import TodaysAppointments from "../../components/Appointments/TodaysAppointments";

export const PrivateHome = () => {
  return (
    <>
      <h1>Dashboard</h1>
      <TodaysAppointments />
    </>
  );
};
