import {TemplateHandler, MimeType} from "easy-template-x";
import {useEffect, useState} from "react";
import {DocumentData, NewDocumentData} from "../Data";
import jimp from 'jimp';
import {isDevMode} from "../../../index";

var myHeaders = new Headers();
myHeaders.append('pragma', 'no-cache');
myHeaders.append('cache-control', 'no-cache');

var myInit = {
    method: 'GET',
    headers: myHeaders,
};

function convertWordImageSizeToPixels(): {widthPx: number, heightPx: number}{
    const width = 4.83; // cm
    const height = 3.51; // cm
    const cmToEMU = 360000; // emu
    const widthEMU = width*cmToEMU;
    const heightEMU = height*cmToEMU;

    const EMUToPixels = 1/9525;
    const widthPx = Math.round(widthEMU*EMUToPixels);
    const heightPx = Math.round(heightEMU*EMUToPixels);

    // Math.round(pixels * 9525) in source code from pixels => emu
    return {widthPx, heightPx}

}

function getPersonExampleImage(isFemale: boolean){
    if (isDevMode()) {
        // dev code
        return "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cGVyc29ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80"
    } else {
        // production code
        return `${process.env.REACT_APP_BACKEND_URL}templates/${isFemale?"woman.jpg": "man.jpg"}`;
    }

}
export default function useGenerateDocumentHook(data: NewDocumentData): {

    generateDocument: (fileName: string, data: any)=>void
    blobUrl: undefined | string, loading: boolean, error: Error | undefined,
    reset: ()=>void
} {

    const [fileName, setFileName] = useState<string | undefined>(undefined)
    const [template, setTemplate] = useState<Blob | undefined>(undefined)
    const [error, setError] = useState<Error| undefined>(undefined)
    const [blobUrl, setBlobUrl] = useState<string| undefined>(undefined)
    const handler = new TemplateHandler();

    function reset() {

        setBlobUrl(undefined);
    }

    async function processDocument(template: Blob, d: any){
        // Better place for downloading the image?
        console.log(d);
        const url = d.patient_photoPath??getPersonExampleImage(d.isFemale)
        const image = await jimp.read(url);
        const {heightPx, widthPx} = convertWordImageSizeToPixels();

        // Scaling with factor to keep quality high enough
        image.background( 0xFFFFFFFF )
        image.contain(widthPx*3, heightPx*3);

        const personalImage = await image.getBufferAsync(jimp.MIME_JPEG);
        //const buffer = Buffer.from(imageResponse.data, 'binary')
        //const personalImage = await prepareImageRatio(buffer);
        const data = await handler.process(template, {...d,
            "my_name": "Heidbuchel",
            "my_first_name": "Ruth",
            "my_address_street_and_no": "Kardinaal Mercierlaan 6/72",
            "my_postalcode_and_city": "3001 Heverlee",
            "riziv_no": "3/92015/59/007",
            "KBO_no": "0672838619",
            "place": "Heverlee",
            "gender-possessive": (d.patient_isFemale? "haar": "zijn"),
            "footer": `Ruth Heidbuchel\nKardinaal Mercierlaan 6/72\n3001 Heverlee\n016/ 23 65 69\northeid@gmail.com`,
            "image": {
                _type: "image",
                source: personalImage,
                format: MimeType.Jpeg,
                width: widthPx,
                height: heightPx
            },
            "kennisgeving-1": {
                _type: 'rawXml',
                xml: `<w:sym w:font="Wingdings" w:char=${false?"F0FD":"F0A8" }"/>`,
                replaceParagraph: false,  // optional - should the plugin replace an entire paragraph or just the tag itself
            },
            "kennisgeving-2": {
                _type: 'rawXml',
                xml: `<w:sym w:font="Wingdings" w:char=${false?"F0FD":"F0A8" }"/>`,
                replaceParagraph: false,  // optional - should the plugin replace an entire paragraph or just the tag itself
            },
            "teeth_care": {
                _type: 'rawXml',
                xml: `<w:sym w:font="Wingdings" w:char=${false?"F0FD":"F0A8" }"/>`,
                replaceParagraph: false,  // optional - should the plugin replace an entire paragraph or just the tag itself
            },
            "teeth_caries": {
                _type: 'rawXml',
                xml: `<w:sym w:font="Wingdings" w:char=${false?"F0FD":"F0A8" }"/>`,
                replaceParagraph: false,  // optional - should the plugin replace an entire paragraph or just the tag itself
            }} )
        setBlobUrl(URL.createObjectURL(data));
        // TODO Buffer?
        setFileName(undefined);
        // setTemplate();
    }
    useEffect(()=> {
        if (fileName){
            fetch(`${process.env.REACT_APP_BACKEND_URL}templates/${fileName}`, myInit).then(async (res)=>  setTemplate(await res.blob())).catch(setError)
        }
    }, [fileName])

    useEffect(()=> {
        if (template){
            processDocument(template, data);
        }
    }, [template, data])

    function generateDocument(name:string) {
        console.log('loading document...')
        setFileName(name)
    }
    return {generateDocument, reset, blobUrl, loading: !!fileName, error}
}