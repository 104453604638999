import React from "react";

//import { useWorker } from "@koale/useworker";
// TODO WEBWORKER

import useGenerateDocumentHook from "./hooks/useGenerateDocumentHook";
import useDownloadFileHook from "./hooks/useDownloadFileHook";
import {DocumentData, NewDocumentData} from "./Data";
import {CircularProgress} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const reportTypes =  {
    "Bijlage 60":"BIJLAGE60.docx",
    "Harmonisatiedocument":"Harmonisatiedocument.docx",
    "Bijlage 60 bis": "Bijlage60bis.docx",
    "Attest voor logopedie": "Voorschrift_logopedie.docx",
    "Orthodontisch verslag": "Report.docx",
    "DKV": "DKV_orth_verslag.docx",
}

/**
 * UI
 * @constructor
 */
function ReportGenerator(props: {data: NewDocumentData}) {
    const {setNameAndExt, downloadFile} = useDownloadFileHook();
    const hook = useGenerateDocumentHook(props.data);

    return (
        <div>
            {/*{JSON.stringify(props.data)}*/}
            {hook.error??null}
            {hook.loading? <CircularProgress/>: 'Klaar '}
            {hook.blobUrl? <Button color={"secondary"} onClick={()=>downloadFile(hook.blobUrl!)}>Download</Button>: null}
            <ul>
                {Object.entries(reportTypes).map(([k, v], i)=>{
                    return (<li key={i} >
                        <a style={{cursor: 'pointer'}} key={`a-${i}`} onClick={()=> {
                            setNameAndExt(v);
                            hook.generateDocument(v, undefined)
                        }}>{k}</a>
                    </li>);
                })}
            </ul>

        </div>
    );
}

export default ReportGenerator