import { Button } from "@material-ui/core";
import React from "react";
import { setAccessToken } from "../config/accessToken";
import { useHistory } from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
const LogoutBtn = () => {
  //const [logout] = useLogoutMutation();
    const {logout}  = useAuth0()
  const history = useHistory();
  return (
    <Button
      color="secondary"
      variant={"contained"}
      // onClick={async () => {
      //   await logout(); // clear refresh token
      //   setAccessToken(""); // clear access token
      //   // await client!.resetStore(); // TODO this makes apollo refetch...
      //
      //   history.push("/login");
      // }}
      onClick={()=>logout()}
    >
      Logout
    </Button>
  );
};
export default LogoutBtn;
