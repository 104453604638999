import { WidgetProps } from "@rjsf/core";
import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import formatDate from "dateformat";
import { CircularProgress, TextField } from "@material-ui/core";
import {
  GetAppointmentProposalsQuery,
  useGetAppointmentProposalsQuery,
} from "../../../generated/graphql";
import { ArrayElement } from "../../../BasicTypes/basics";

export const AutoCompleteAppointmentProposalWidget = (
  props: WidgetProps & {
    data: GetAppointmentProposalsQuery["appointmentProposals"];
    loading: boolean;
  }
) => {
  // const throwError = useAsyncError();
  const [options, setOptions] = React.useState<
    GetAppointmentProposalsQuery["appointmentProposals"]
  >();

  // useEffect(() => {
  //   if (error) {
  //     throwError(error);
  //   }
  // }, [error]);

  useEffect(() => {
    if (props.data) {
      setOptions(props.data);
    }
  }, [props.data]);
  return (
    <Autocomplete<
      ArrayElement<GetAppointmentProposalsQuery["appointmentProposals"]>
    >
      id={`autocomplete-appointmentproposal-${props.id}`}
      placeholder={props.placeholder}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${
          option.appointments.length > 0
            ? `${option.appointments[0].patient.person.name} ${option.appointments[0].patient.person.fname} - ${option.appointments[0]?.calendarTitle}`
            : option.id
        }`
      }
      onChange={(e, value) => {
        if (value) props.onChange(value.id);
        else props.onChange(null);
      }}
      value={options?.find((o) => o.id === props.value) ?? null}
      options={options ?? []}
      loading={props.loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          variant={"standard"}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
