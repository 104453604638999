import { UiSchema, WidgetProps } from "@rjsf/core";

import { JSONSchema7 } from "json-schema";
import {
  mapFormDataToCreatePersonInput,
  PersonFormData,
  PersonSchema,
  PersonUiSchema,
} from "./PersonSchema";
import {
  CreateDentistMutationVariables,
  Dentist,
  DentistsQuery,
  useCreateDentistMutation,
  useDentistsQuery,
} from "../../../generated/graphql";
import AutoCompleteSelectAndCreateNewDialogWidget from "../JsonSchemaFormWidgets/AutoCompleteSelectAndCreateNewDialogWidget";

export interface DentistFormData extends PersonFormData {
  dentistId?: string;
}

export const DentistSchema: JSONSchema7 = {
  type: "object",
  required: PersonSchema.required,
  properties: {
    ...PersonSchema.properties,
  },
};

export function mapDentistFormToCreate(
  formData: DentistFormData
): CreateDentistMutationVariables {
  return {
    data: mapFormDataToCreatePersonInput(formData),
  };
}
interface BaseModel {
  id: string;
}
function haveMatchingIds<T extends BaseModel>(item1: T, item2: T) {
  return item1.id === item2.id;
}

function selectId<T extends BaseModel>(item: T | null) {
  return item?.id;
}

export const dentistAutoComplete = (props: WidgetProps) =>
  AutoCompleteSelectAndCreateNewDialogWidget<Dentist, DentistsQuery>(props, {
    schema: DentistSchema,
    uiSchema: DentistUiSchema,
    queryHook: useDentistsQuery,
    mutationHook: useCreateDentistMutation,
    getDataFromQryResult: (data: DentistsQuery) => data.dentists,
    getOptionsDoMatch: haveMatchingIds,
    getValueToSubmit: selectId,
    getTextToShowOptionInList: ({ person }: Dentist) =>
      `${person.fname} ${person.name}`,
    defaultValueFieldName: "firstName",
    convertFormDataToMutationVariables: mapDentistFormToCreate,
  });

export const DentistUiSchema: UiSchema = {
  ...PersonUiSchema,
  dentistId: {
    "ui:widget": dentistAutoComplete,
  },
};
