import { UiSchema } from "@rjsf/core";
import React from "react";
import { JSONSchema7 } from "json-schema";
import DatePickerWidget from "../JsonSchemaFormWidgets/DatePickerWidget";
import {
  AddressUiSchema,
  AddressFormData,
  AddressSchema,
} from "./AddressSchema";
import { Nullable } from "../../../BasicTypes/Nullable";
import {
  Person,
  PersonCreateInput,
  PersonUpdateInput,
} from "../../../generated/graphql";
import { undefinedOrSetInput } from "./PatientSchema";

// function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
//   return (
//     <>
//       {props.items.map((element) => element.children)}
//       {props.canAdd && (
//         <Button variant="contained" onClick={props.onAddClick}>
//           Voeg nieuwe toe
//         </Button>
//       )}
//     </>
//   );
// }
export const PersonUiSchema: UiSchema = {
  birthDate: {
    "ui:widget": DatePickerWidget,
  },
  phones: {
    // "ui:ArrayFieldTemplate": ArrayFieldTemplate,
    "ui:options": {
      orderable: false,
    },
  },
  emails: {
    "ui:options": {
      orderable: false,
    },
  },
  ...AddressUiSchema,
};

export interface PersonFormData extends AddressFormData {
  personId?: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  phones: string[];
  emails: string[];
  gender: Nullable<string>;
  language?: Nullable<string>;
}

export const PersonSchema: JSONSchema7 = {
  type: "object",
  required: ["firstName", "lastName", "birthDate"].concat(
    AddressSchema.required ?? []
  ),
  properties: {
    firstName: {
      type: "string",
      title: "Voornaam",
    },
    lastName: {
      type: "string",
      title: "Achternaam",
    },
    birthDate: {
      type: "string",
      title: "Geboortedatum",
      default: "",
    },
    gender: {
      type: ["string", "null"],
      title: "Geslacht",
      enum: ["M", "V", "X"],
      default: "X",
    },
    phones: {
      type: "array",
      title: "Telefoon",
      default: [],
      items: {
        type: "string",
      },
    },
    emails: {
      type: "array",
      title: "Email",
      default: [],
      items: {
        type: "string",
      },
    },
    language: {
      type: ["string", "null"],
      title: "Taal",
    },
    ...AddressSchema.properties,
  },
};

function mapGenderToForm(gender: Nullable<string>): string | null {
  switch (gender) {
    case "MALE":
      return "M";
    case "FEMALE":
      return "V";
    case "X":
      return "X";
    default:
      return null;
  }
}

function mapGender(gender: Nullable<string>): string | null {
  switch (gender) {
    case "M":
      return "MALE";
    case "V":
      return "FEMALE";
    case "X":
      return "X";
    default:
      return null;
  }
}
export function mapFormDataToUpdatePersonInput(formData: PersonFormData) {
  const update: PersonUpdateInput = {
    id: { set: formData.personId },
    fname: { set: formData.firstName },
    name: { set: formData.lastName },
    bdate: { set: formData.birthDate },
    phones: { set: formData.phones },
    emails: { set: formData.emails },
    language: undefinedOrSetInput(formData.language),
    gender: { set: mapGender(formData.gender) },
    address: {
      update: {
        id: { set: formData.addressId },
        street: undefinedOrSetInput(formData.street),
        houseNumbering: formData.houseNumbering
          ? { set: formData.houseNumbering }
          : undefined,
        sndLine: undefinedOrSetInput(formData.sndLine),
        country: formData.countryId
          ? { connect: { id: formData.countryId } }
          : undefined,
        city: formData.cityId
          ? {
              connect: {
                id: formData.cityId,
              },
            }
          : undefined,
      },
    },
  };
  return {
    person: {
      update,
    },
  };
}
export function mapFormDataToCreatePersonInput(formData: PersonFormData) {
  const create: PersonCreateInput = {
    fname: formData.firstName,
    name: formData.lastName,
    bdate: formData.birthDate,
    gender: mapGender(formData.gender),
    phones: { set: formData.phones },
    emails: { set: formData.emails },
    language: formData.language ?? undefined,
    address: {
      create: {
        street: formData.street ?? undefined,
        houseNumbering: formData.houseNumbering ?? undefined,
        sndLine: formData.sndLine ?? undefined,
        country: formData.countryId
          ? { connect: { id: formData.countryId } }
          : undefined,
        city: formData.cityId
          ? {
              connect: {
                id: formData.cityId,
              },
            }
          : undefined,
      },
    },
  };
  return {
    person: {
      create,
    },
  };
}

export function mapPersonQueryToFormData<T extends Person>(
  person: any // TODO Fix type
): PersonFormData {
  const { address } = person;
  // const {person, id, dentist, disease, insRefund} = patient;
  return {
    personId: person.id,
    addressId: address.id,
    birthDate: person.bdate,
    gender: mapGenderToForm(person.gender),
    cityId: address.city?.id,
    countryId: address.country?.id,
    emails: person.emails,
    firstName: person.fname,
    houseNumbering: address.houseNumbering,
    language: person.language,
    lastName: person.name,
    phones: person.phones,
    sndLine: address.sndLine,
    street: address.street,
  };
}
