import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useBijlage60PDF, useReportPDF } from "../../hooks/pdfHooks";
import dateFormat from "dateformat";
import DatePickerWidget from "../Forms/JsonSchemaFormWidgets/DatePickerWidget";
import moment from "moment";
import styled from "styled-components";
import Form from "../Forms/Form";
import FileDropZoneWidget from "../Forms/JsonSchemaFormWidgets/FileDropZoneWidget";
import { RouteComponentProps } from "react-router-dom";
import {useGetReportDataQuery, useVariablesQuery} from "../../generated/graphql";
import { DocumentData } from "../PDFs/Data";
import {sortVariables} from "./DiagnosisTreatment";
import ReportGenerator from "../PDFs/ReportGenerator";
const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Left = styled.div`
  grid-column: 1;
`;

const Right = styled.div`
  grid-column: 1;
  width: 50%;
  background: red;
  iframe {
    padding: 0;
    margin: 0;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;
// const OuterContainer = styled.div`
//   display: flex;
//   flex-flow: column;
//   background: yellow;
// `;
// const RestOfPage = styled.div`
//   background: green;
//   flex-grow: 1;
// `;
// const Container = styled.div`
//   display: flex;
//   flex-flow: row;
// `;
// const Left = styled.div`
//   width: 50%;
//   height: 100%;
//   background: blue;
// `;
//
// const Right = styled.div`
//   flex-grow: 1;
//   background: transparant;
//   iframe {
//     padding: 0;
//     margin: 0;
//     border: none;
//     margin: 0;
//     padding: 0;
//     display: block;
//     width: 100%;
//     height: 100%;
//   }
// `;

const RadioPDFView = (props: {
  pdfs: { name: string; pdf: () => Promise<Blob> }[];
}) => {
  const { selected, options, onChange } = useTogglePDF(
    ...props.pdfs.map((o) => o.name)
  );
  useEffect(() => {
    const item = props.pdfs.find((o) => o.name === selected);
    item?.pdf().then(URL.createObjectURL).then(setPreviewUrl);
  }, [selected, props.pdfs]);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  return (
    <div style={{ height: "100%" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Weer te geven:</FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          value={selected}
          onChange={(e, v) => onChange(v)}
        >
          {options.map((o) => (
            <FormControlLabel
              value={o}
              control={<Radio color="primary" />}
              label={o}
              labelPlacement="top"
            />
          ))}
        </RadioGroup>
      </FormControl>
      <div>
        <Button
          onClick={async () => {
            if (previewUrl) window.open(previewUrl);
          }}
        >
          Open pdf in nieuw venster
        </Button>
      </div>
    </div>
  );
};

const useTogglePDF = (...options: string[]) => {
  const [selected, setSelected] = useState(options[0] ?? null);
  function onChange(selected: string) {
    setSelected(selected);
  }
  return { selected, onChange, options };
};

function downloadFile(blob: Blob, filename: string) {
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.pdf`);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode?.removeChild(link);
}

const FrameWrapper = styled.div`
  height: 100%;
  iframe {
    padding: 0;
    margin: 0;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;
function mapOrder (array: any[], order: any[], key: string|number|undefined) {

  array.sort( function (a, b) {
    var A = !(key === undefined)? a[key]: a, B = !(key === undefined)? b[key]: b;
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }

  });

  return array;
};
export const ReportDataProvider = (
  props: RouteComponentProps<{ patientId: string }>
) => {
  const { data, loading } = useGetReportDataQuery({
    variables: { id: props.match.params.patientId },
  });

  const {data: variablesData, loading: varLoading} = useVariablesQuery();
  if (loading || varLoading || !data?.patient) return <CircularProgress />;
  const sortedVariablesBP = sortVariables("Behandelingsplan", variablesData?.variables).map(a=>a.id);
  const sortedVariablesDIAG = sortVariables("Diagnose", variablesData?.variables).map(a=>a.id);
  //console.log(sortedVariablesDIAG)
  const formData = {
    salutation: "Geachte Collega",
    // TODO Volgorde van treatment and diagnosis nog fout
    treatment: data.treatment?.treatment
      ? mapOrder(Object.entries(JSON.parse(data.treatment.treatment)), sortedVariablesBP, 0)
          .map(([, v], i) => `${i + 1}. ${v}`)
          .join("\n")
      : "",
    diagnosis: data.diagnosis?.diagnosis
      ? mapOrder(Object.entries(JSON.parse(data.diagnosis.diagnosis)), sortedVariablesDIAG, 0)
          .map(([, v]) => v)
          .join(", ")
      : "",
    date: new Date().toISOString(),
  };
  const reportData = {
    salutation: "",
    date: moment().format("D MMMM YYYY"),
    patient: {
      fname: data.patient.person.fname,
      name: data.patient.person.name,
      bdate: dateFormat(data.patient.person.bdate, "dd/mm/yyyy"),
      street: data.patient.person.address.street,
      houseNumbering: data.patient.person.address.houseNumbering,
      postalCode: data.patient.person.address.city?.postalCode ?? "",
      city: data.patient.person.address.city?.name ?? "",
      isFemale: data.patient.person.gender === "FEMALE",
    },
    dentist: {
      name: data.patient.dentist?.person.name ?? "",
      fname: data.patient.dentist?.person.fname ?? "",
      street: data.patient.dentist?.person.address.street ?? "",
      houseNumbering: data.patient.dentist?.person.address.houseNumbering ?? "",
      postalCode: data.patient.dentist?.person.address.city?.postalCode ?? "",
      city: data.patient.dentist?.person.address.city?.name ?? "",
    },
    diagnosis: "",
    treatment: "",
  };
  return <ReportPreview reportData={reportData} formData={formData} />;
};
const ReportPreview = (props: { formData: any; reportData: any }) => {
  const [formData, setFormData] = useState<any>(props.formData);
  const [reportData, setReportData] = useState<DocumentData>(props.reportData);

  // const { downloadBlob: downloadReport } = useReportPDF(reportData);
  // const { downloadBlob: downloadBijlage60 } = useBijlage60PDF(reportData);

  return (
    <div style={{ height: "100vh", display: "flex", flexFlow: "column" }}>
      <h1>Genereer orthodontisch verslag</h1>
      <Grid container style={{ flexGrow: 1 }}>
        <Grid item xs>
          <div style={{ padding: "1em" }}>
            <Form
              formData={formData}
              uiSchema={{
                diagnosis: {
                  "ui:widget": "textarea",
                },
                treatment: {
                  "ui:widget": "textarea",
                },
                date: {
                  "ui:widget": DatePickerWidget,
                  "ui:dateformat": "D MMMM YYYY",
                },
                photo: {
                  "ui:widget": FileDropZoneWidget,
                  "ui:nbOfFiles": 1,
                  "ui:acceptedFiles": ["image/*"],
                },
              }}
              schema={{
                title: "Rapport",
                type: "object",
                properties: {
                  date: {
                    title: "Datum",
                    type: "string",
                  },
                  salutation: {
                    title: "Begroeting",
                    type: "string",
                    default: "",
                  },
                  diagnosis: {
                    title: "Diagnose",
                    type: "string",
                    default: "",
                  },
                  treatment: {
                    title: "Behandeling",
                    type: "string",
                    default: "",
                  },
                  photo: {
                    title: "Foto",
                    // format: "data-url",
                    type: ["string", "null"],
                    default: "",
                  },
                },
              }}
              buttonName={"Maak gereed voor generatie van document..."}
              onSubmit={({ formData }: any) => {
                setReportData({
                  ...reportData,
                  treatment: formData.treatment,
                  diagnosis: formData.diagnosis,
                  salutation: formData.salutation,
                  date: moment(formData.date).format("D MMMM YYYY"),
                  patient: {
                    ...reportData.patient,
                    photoPath:
                      formData.photo && formData.photo !== ""
                        ? formData.photo
                        : undefined,
                  },
                });
                setFormData(formData);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          {/*<Button*/}
          {/*  variant={"contained"}*/}
          {/*  onClick={async () => {*/}
          {/*    const report = await downloadReport();*/}
          {/*    downloadFile(report, "Orthodontisch-verslag");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Download Orthodontisch verslag*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  variant={"contained"}*/}
          {/*  onClick={async () => {*/}
          {/*    const report = await downloadBijlage60();*/}
          {/*    downloadFile(report, "Bijlage60");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Download Bijlage 60*/}
          {/*</Button>*/}
          {/*<RadioPDFView*/}
          {/*  pdfs={[*/}
          {/*    { name: "Bijlage 60", pdf: downloadBijlage60 },*/}
          {/*    { name: "Orthodontisch verslag", pdf: downloadReport },*/}
          {/*  ]}*/}
          {/*/>*/}
          <ReportGenerator  data={{
            patient_bdate: reportData.patient.bdate,
            patient_isFemale: reportData.patient.isFemale,
            patient_photoPath: reportData.patient.photoPath,
            patient_name: reportData.patient.name,
            patient_first_name: reportData.patient.fname,
            patient_street: reportData.patient.street,
            patient_houseNo: reportData.patient.houseNumbering,
            patient_postalCode: reportData.patient.postalCode,
            patient_city: reportData.patient.city,
            dentist_name: reportData.dentist.name,
            dentist_first_name: reportData.dentist.fname,
            dentist_street: reportData.dentist.street,
            dentist_houseNo: reportData.dentist.houseNumbering,
            dentist_postalCode: reportData.dentist.postalCode,
            dentist_city: reportData.dentist.city,
            date: reportData.date,
            salutation: reportData.salutation,
            diagnosis: reportData.diagnosis,
            treatment: reportData.treatment,
          }}/>

        </Grid>
      </Grid>
    </div>
  );
};

export default ReportPreview;
