import { WidgetProps } from "@rjsf/core";
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import formatDate from "dateformat";
import { CircularProgress, TextField } from "@material-ui/core";

export const AutoCompleteMultipleSelect = <
  T extends { id: string; name: string }
>(
  props: WidgetProps,
  options: T[]
) => {
  console.log("Autocomplete select multiple", options);
  const loading = false;
  const [value, setValue] = useState<T[]>([]);
  useEffect(() => {
    if (!props.value) {
      if (value.length > 0) setValue([]);
      return;
    }

    const parsed = JSON.parse(props.value).map((o: T) => o.name);
    const current = options.filter((e) => parsed.includes(e.name));
    console.log("found matches:", current);
    setValue(current);
  }, [props.value]);
  return (
    <Autocomplete
      id={`autocomplete-multiple-select-${props.id}`}
      multiple
      placeholder={props.placeholder}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.name}`}
      onChange={(e, value) => {
        props.onChange(JSON.stringify(value));
        // if (value) props.onChange(value.map((v) => v.id).join(","));
        // if (value) props.onChange(JSON.stringify(value.map((v) => v.id)));
      }}
      value={value}
      options={options ?? []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          variant={"standard"}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
