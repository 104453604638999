import React, {ReactElement, ReactNode, useCallback, useEffect, useState} from "react";
import "./App.css";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import { useAppApolloClient } from "./config/apolloClient";
import { Routes } from "./Routes";
import { getAccessToken, setAccessToken } from "./config/accessToken";
import { CircularProgress } from "@material-ui/core";
import { useAsyncError } from "./ErrorHandling/useAsyncError";
import { RecoilRoot, atom } from "recoil";
import {Auth0Provider, useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {setContext} from "@apollo/client/link/context";
import {Redirect, Route, Switch, BrowserRouter} from "react-router-dom";
import { Suspense } from "react";
import PublicRoute from "./PublicRoute";
import {PublicHome} from "./pages/PublicHome";
import PrivateRoute from "./PrivateRoute";
import {PrivateHome} from "./pages/home/PrivateHome";
import {Login} from "./pages/login/Login";
import {Header} from "./components/Header/Header";

export const billsPaidState = atom({
  key: "allBillsPaid", // unique ID (with respect to other atoms/selectors)
  default: true, // default value (aka initial value)
});

const httpLink = createHttpLink({
    uri: '/graphql',
});
let tokenGenerator: any;
function setTokenGenerator(generator: any){
    tokenGenerator = generator;
}

export function getTokenGenerator(){
    return tokenGenerator;
}

const LoadingFallback = () => {
    return <div>Loading fallback<br/><CircularProgress/></div>
}


const Demo = () => {
    const {logout} = useAuth0();
    return <div>
        <button onClick={() => logout()}>Logout</button>
    </div>
}

const AppRoutes = () => {
    const { isLoading, getAccessTokenSilently} = useAuth0();
    useEffect(()=>setTokenGenerator(getAccessTokenSilently), [getAccessTokenSilently])

    if (isLoading) {
        return (
            <div className="h-screen flex justify-center">
                <div>Loading authentication...</div>
                <LoadingFallback />
            </div>
        );
    }
    return (
        <BrowserRouter>
            <Suspense fallback={<div><h1>Loading dashboard</h1><LoadingFallback /></div>}>
                <Switch>
                    {/*<ProtectedRoute exact path="/home" component={PrivateHome} />*/}
                    {/*<ProtectedRoute exact path={"/dashboard"} component={Demo}/>*/}
                    {/*<UnauthenticatedRoutes/>*/}
                    <Routes/>
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
};



const requestedScopes = [
    'read:dashboard',
    'read:inventory',
    'write:inventory',
    'edit:inventory',
    'delete:inventory',
    'read:users',
    'read:user',
    'edit:user',
    'openid email profile'
];


function App() {
    const client = useAppApolloClient();

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || 'nodomain'}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID || 'noclientid'}
            redirectUri={`${window.location.origin}/home`}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE || 'App Audience'}
            scope={requestedScopes.join(' ')}
        >
            <ApolloProvider client={client}>
                <RecoilRoot>
                    <AppRoutes/>
                </RecoilRoot>
            </ApolloProvider>

        </Auth0Provider>
    );
}

export default App;
