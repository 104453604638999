import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function AppointmentCard(props: {
  title: string;
  upperSmallTitle?: string;
  lowerSmallTitle?: string;
  id: string;
  children?: React.ReactNode;
}) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.root}>
      <CardContent>
        {props.upperSmallTitle && (
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {props.upperSmallTitle}
          </Typography>
        )}
        <Typography variant="h5" component="h2">
          {props.title}
        </Typography>
        {props.lowerSmallTitle && (
          <Typography className={classes.pos} color="textSecondary">
            {props.lowerSmallTitle}
          </Typography>
        )}

        {props.children}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => history.push(`/appointment/${props.id}`)}
        >
          Ga naar afspraak
        </Button>
      </CardActions>
    </Card>
  );
}
