import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { WidgetProps } from "@rjsf/core";

/**
 * If ui:onlyOne is provided only one string will be returned onChange else a list of all the data urls (prefixed by "blob")
 * @param props
 * @constructor
 */
const FileDropZoneWidget = (props: WidgetProps) => {
  return (
    <DropzoneArea
      acceptedFiles={props.uiSchema["ui:acceptedFiles"]}
      dropzoneText={"Sleep hier een afbeelding van de patiënt voor het verslag"}
      filesLimit={props.uiSchema["ui:nbOfFiles"] ?? undefined}
      onChange={([file, ...rest]: any[]) => {
        if (props.uiSchema["ui:nbOfFiles"] === 1)
          props.onChange(file ? URL.createObjectURL(file) : null);
        else {
          props.onChange(
            file ? [file, ...rest].map((f) => URL.createObjectURL(file)) : null
          );
        }
      }}
    />
  );
};

export default FileDropZoneWidget;
