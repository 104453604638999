import React from "react";
import { ISubmitEvent, UiSchema, WidgetProps } from "@rjsf/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Form from "@rjsf/material-ui";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { JSONSchema7 } from "json-schema";

export interface AutoCompleteFormResolver<AutoCompleteOption, QueryData>
  extends CreateNewFormUtils {
  queryHook: any;
  getTextToShowOptionInList: (option: AutoCompleteOption) => string;
  getDataFromQryResult: (data: QueryData) => any;
  getOptionsDoMatch: (
    option: AutoCompleteOption,
    otherOption: AutoCompleteOption
  ) => boolean;
  getValueToSubmit: (option: AutoCompleteOption | null) => any;
  defaultValueFieldName?: string;
}

/**
 * Interface for react json form based on graphql hooks
 */
interface CreateNewFormUtils {
  mutationHook: any;
  convertFormDataToMutationVariables: (formData: any) => any;
  schema: JSONSchema7;
  uiSchema: UiSchema;
}

const CreateDialog = (props: {
  handleClose: any;
  open: boolean;
  diagValue: string;
  defaultValueFieldName?: string;
  formUtils: CreateNewFormUtils;
}) => {
  const [doMutation, { data, loading, error }] = props.formUtils.mutationHook();

  // set default value if prop is given
  const { defaultValueFieldName, formUtils } = props;
  const { schema } = formUtils;
  if (defaultValueFieldName) {
    if (schema.properties && defaultValueFieldName) {
      if (!schema.properties[defaultValueFieldName]) {
        console.log(
          "No default value for dialog set!",
          schema.properties,
          defaultValueFieldName
        );
      } else {
        // @ts-ignore
        schema.properties[defaultValueFieldName]["default"] = props.diagValue;
      }
    }
  } else {
    console.log("No default value field name");
  }

  const handleSubmit = async ({ formData }: ISubmitEvent<any>) => {
    await doMutation({
      variables: props.formUtils.convertFormDataToMutationVariables(formData),
    });
    props.handleClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Voeg toe</DialogTitle>
      <DialogContent>
        {/*<DialogContentText>{props.diagValue}</DialogContentText>*/}
        {loading && <CircularProgress />}
        {!data && !loading && (
          <Form
            uiSchema={props.formUtils.uiSchema}
            schema={props.formUtils.schema}
            onSubmit={handleSubmit}
          >
            <DialogActions>
              <Button onClick={props.handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
};

const AutoCompleteSelectAndCreateNewDialogWidget = <A extends any, E>(
  props: WidgetProps,
  formUtils: AutoCompleteFormResolver<A, E>
) => {
  interface BaseOption {
    __shouldCreateNew: boolean;
  }
  interface NormalOption extends BaseOption {
    __shouldCreateNew: false;
    option: A;
  }

  interface CreateHelper extends BaseOption {
    __shouldCreateNew: true;
    name: string;
  }
  const { data, loading, refetch } = formUtils.queryHook();
  const [open, setOpen] = React.useState(false);
  const [diagOpen, setDiagOpen] = React.useState(false);
  const [diagValue, setDiagValue] = React.useState("");
  const [options, setOptions] = React.useState<BaseOption[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<null | BaseOption>(
    null
  );
  const loadingBool = open && loading;
  const filter = createFilterOptions<BaseOption>();
  function findAndSelectMatchingOption() {
    if (
      selectedOption &&
      !selectedOption.__shouldCreateNew &&
      formUtils.getValueToSubmit((selectedOption as NormalOption).option) ===
        props.value
    ) {
      return;
    }

    if (!props.value && selectedOption) {
      setSelectedOption(null);
      return;
    }
    const gotya: BaseOption | undefined = options.find((option) => {
      if (!option.__shouldCreateNew) {
        return (
          formUtils.getValueToSubmit((option as NormalOption).option) ===
          props.value
        );
      } else return false;
    });
    setSelectedOption(gotya ?? null);
  }

  React.useEffect(() => {
    if (data) {
      const options = formUtils.getDataFromQryResult(data);
      setOptions(
        options.map(
          (option: A) => ({ __shouldCreateNew: false, option } as BaseOption)
        )
      );
    }
  }, [data]);

  React.useEffect(() => {
    findAndSelectMatchingOption();
  }, [options]);

  React.useEffect(() => {
    if (
      selectedOption &&
      !selectedOption.__shouldCreateNew &&
      formUtils.getValueToSubmit((selectedOption as NormalOption).option) !==
        props.value
    ) {
      findAndSelectMatchingOption();
    } else {
      if (!props.value && selectedOption) setSelectedOption(null);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (!diagOpen && data) {
      // On close reload data because new option could have been added
      refetch();
    }
  }, [diagOpen]);

  return (
    <>
      <Autocomplete
        multiple={false}
        id={`autocomplete-create-dialog-select-${props.id}`}
        onChange={(e, d) => {
          if (d && d.__shouldCreateNew) {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setDiagOpen(true);
              // Give values as props to dialog
              setDiagValue((d as CreateHelper).name);
            });
          }
          if (d?.__shouldCreateNew === false) {
            setSelectedOption(d);
            props.onChange(
              formUtils.getValueToSubmit((d as NormalOption).option)
            );
          } else props.onChange(null);
        }}
        // style={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          // Todo pass id to form
          setOpen(false);
        }}
        value={selectedOption}
        getOptionSelected={(option, value) => {
          if (option.__shouldCreateNew && value.__shouldCreateNew) {
            return true;
          }
          if (!option.__shouldCreateNew && !value.__shouldCreateNew) {
            // Both NormalOptions
            return formUtils.getOptionsDoMatch(
              (option as NormalOption).option,
              (value as NormalOption).option
            );
          }
          return false;
        }}
        getOptionLabel={(item: BaseOption) => {
          if (item.__shouldCreateNew) {
            return `Maak "${(item as CreateHelper).name}" aan`;
          } else {
            return formUtils.getTextToShowOptionInList(
              (item as NormalOption).option
            );
          }
        }}
        options={options}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              __shouldCreateNew: true,
              name: params.inputValue,
            } as CreateHelper);
          }

          return filtered;
        }}
        loading={loadingBool}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            required={props.required}
            id={props.id}
            // variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingBool ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <CreateDialog
        open={diagOpen}
        handleClose={(id?: string) => {
          if (id) console.log("Closed after submit");
          setDiagOpen(false);
        }}
        diagValue={diagValue}
        defaultValueFieldName={formUtils.defaultValueFieldName}
        formUtils={formUtils}
      />
    </>
  );
};
export default AutoCompleteSelectAndCreateNewDialogWidget;
