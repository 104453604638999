import { UiSchema, WidgetProps } from "@rjsf/core";

import { JSONSchema7 } from "json-schema";
import {
  CitiesQuery,
  City,
  CreateCityMutationVariables,
  useCitiesQuery,
  useCreateCityMutation,
} from "../../../generated/graphql";
import AutoCompleteSelectAndCreateNewDialogWidget from "../JsonSchemaFormWidgets/AutoCompleteSelectAndCreateNewDialogWidget";
import { haveMatchingIds, selectId } from "./Utils";

export const CityUiSchema: UiSchema = {};

export interface CityFormData {
  name: string;
  postalCode: string;
}

export const CitySchema: JSONSchema7 = {
  title: "City form",
  description: "A simple form example.",
  type: "object",
  required: ["name", "postalCode"],
  properties: {
    name: {
      type: "string",
      title: "Naam",
    },
    postalCode: {
      type: "string",
      title: "Postcode",
    },
  },
};
function mapCityFormToCreate(
  formData: CityFormData
): CreateCityMutationVariables {
  return {
    data: { name: formData.name, postalCode: formData.postalCode },
  };
}

export const cityAutoComplete = (props: WidgetProps) =>
  AutoCompleteSelectAndCreateNewDialogWidget<City, CitiesQuery>(props, {
    schema: CitySchema,
    uiSchema: CityUiSchema,
    queryHook: useCitiesQuery,
    mutationHook: useCreateCityMutation,
    getDataFromQryResult: (data: CitiesQuery) => data.cities,
    getOptionsDoMatch: haveMatchingIds,
    getValueToSubmit: selectId,
    getTextToShowOptionInList: (option: City) =>
      `${option.postalCode} -- ${option.name}`,
    defaultValueFieldName: "name",
    convertFormDataToMutationVariables: mapCityFormToCreate,
  });
