import React from "react";
import { Route } from "react-router-dom";

import { PublicHome } from "./pages/PublicHome";
import { Login } from "./pages/login/Login";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { PrivateHome } from "./pages/home/PrivateHome";
import { Patient } from "./pages/patient/Patient";
import NotFound from "./pages/404";
import { Appointment } from "./pages/appointment/Appointment";
import Variables from "./pages/variables/Variables";
import { DiagnosisPage } from "./pages/patient/diagnosis/Diagnosis";
import { EmailBuilder } from "./pages/email/EmailBuilder";
import { Images } from "./pages/images/Images";
import Patients from "./pages/patients/Patients";
import { CalendarPage } from "./pages/calendar/CalendarPage";
import ConfirmAppointment from "./pages/confirmation/ConfirmAppointment";
import { FinancialPlanPage } from "./pages/patient/financial/FinancialPlanPage";
import { ReportDataProvider } from "./components/Diagnosis-Treatment/ReportPreview";
import Test from "./pages/oauthcallback/Test";
import CalendarSync from "./pages/calendarsync/CalendarSync";



//https://stackoverflow.com/questions/61870378/access-token-from-auth0provider-outside-of-react-components/68407340#68407340
const AuthenticatedRoutes: React.FC = () => {
  return (
      <>
        <PrivateRoute exact path="/oauthcallback" component={Test} />
        <PrivateRoute exact path="/calendarsync" component={CalendarSync} />
        {/*<PublicRoute*/}
        {/*  exact*/}
        {/*  path="/email"*/}
        {/*  component={EmailBuilder}*/}
        {/*  restricted={false}*/}
        {/*/>*/}
        {/*<PublicRoute*/}
        {/*  exact*/}
        {/*  path="/images"*/}
        {/*  component={Images}*/}
        {/*  restricted={false}*/}
        {/*/>*/}
        <PrivateRoute exact path="/variables" component={Variables} />
        {/*<PrivateRoute exact path="/home" component={PrivateHome} />*/}
        {/*<PublicRoute*/}
        {/*  exact*/}
        {/*  path="/register"*/}
        {/*  component={Register}*/}
        {/*  restricted={true}*/}
        {/*/>*/}

        <PrivateRoute exact path="/patients" component={Patients} />

        <PrivateRoute
          exact
          path="/patient/diagnosis/:patientId"
          component={DiagnosisPage}
        />
        <PrivateRoute
          exact
          path="/patient/report/:patientId"
          component={ReportDataProvider}
        />
        <PrivateRoute exact path="/patient/:patientId" component={Patient} />
        <PrivateRoute
          exact
          path="/patient/:patientId/financial"
          component={FinancialPlanPage}
        />

        <PrivateRoute
          exact
          path="/appointment/:appointmentId"
          component={Appointment}
        />
        <PrivateRoute exact path="/calendar" component={CalendarPage} />
        <PrivateRoute exact path={"/home"} component={PrivateHome}/>
        {/*<PublicRoute component={NotFound} restricted={false} />*/}
</>
  );
};

const UnauthenticatedRoutes = () => (
    <>


      <Route exact path="/" component={PublicHome} />
      <Route
          exact
          path="/confirmation/:proposalId"
          component={ConfirmAppointment}
      />
      <Route path="*">
        <div>Not found 404</div>
      </Route>

    </>
);

export const Routes = () => (<><UnauthenticatedRoutes/><AuthenticatedRoutes/></>)