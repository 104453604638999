import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";

const CalendarSync = () => {
  const [doMut, mutRes] = useMutation(gql`
    mutation TestMutation {
      createGoogleCalendars
    }
  `);
  return (
    <div>
      <h1>
        <Button onClick={() => doMut()}>Maak kalenders aan</Button>
      </h1>
    </div>
  );
};
export default CalendarSync;
