import React from "react";
import { useMeQuery } from "../generated/graphql";
import { Link, RouteComponentProps } from "react-router-dom";
import LogoutBtn from "../components/LogoutButton";
import { Button, CircularProgress } from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";

export const PublicHome: React.FC<RouteComponentProps> = ({ history }) => {
  const {isLoading, user, loginWithRedirect} = useAuth0();

  let btn: JSX.Element;
  if (!isLoading && user) {
    btn = <LogoutBtn />;
  } else {
    btn = (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => loginWithRedirect()}
      >
        Log in
      </Button>
    );
  }
  return (
    <div>
      <header className="App-header">
        <div>Public home</div>
        {btn}
        <div>
          {user? (
            <p>{`${JSON.stringify(user)}`}</p>
          ) :(
            <p>No user logged in</p>
          )}
        </div>
        <h3>
          <Link style={{ color: "white" }} to={"/home"}>
            Dashboard
          </Link>
        </h3>
        <h3>
          <Link style={{ color: "white" }} to={"/calendar"}>
            Agenda
          </Link>
        </h3>
      </header>
    </div>
  );
};
