import React, { useEffect } from "react";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { WidgetProps, Widget } from "@rjsf/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Icon,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
const DateTimePickerWidget: Widget = (
  props: { time?: boolean; date?: boolean } & WidgetProps
) => {
  const [
    selectedDate,
    setSelectedDate,
  ] = React.useState<null | MaterialUiPickersDate>(null);

  useEffect(() => {
    // This is necessary to reset field when form submits; otherwise value does not disappear!
    // if (selectedDate?.toISOString() !== props.value) {
    //   setSelectedDate(props.value);
    //   return;
    // }
    if (props.value) {
      if (selectedDate?.toISOString() === props.value) {
        // No difference from props ==> no change
        return;
      }
      // selected date is null or difference
      console.log(
        "Selected date is null or values differ, but received value!",
        props.value,
        selectedDate?.toISOString()
      );
      setSelectedDate(moment(props.value));
      return;
    }
    // Prop is null or undefined
    if (props.value === undefined) {
      setSelectedDate(null);
    }
  }, [props.value, selectedDate]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);
    console.log("handledatechange", date);
    // MUST BE A STRING
    props.onChange(date?.toISOString());
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{props.label}</FormLabel>
        <FormGroup>
          <KeyboardDatePicker
            disableToolbar
            required={props.required}
            variant="inline"
            format="DD/MM/yyyy"
            id={`${props.id}-date`}
            label={"Datum"}
            value={selectedDate}
            onChange={handleDateChange}
            helperText={""}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardTimePicker
            // margin="normal"
            variant={"inline"}
            required={props.required}
            id={`${props.id}-time`}
            label="Tijdstip"
            format="HH:mm"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            keyboardIcon={<Icon component={ScheduleIcon} />}
          />
        </FormGroup>
        <FormHelperText>Eventuele hulptekst</FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};
export default DateTimePickerWidget;
