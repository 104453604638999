import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";

import { getAccessToken, setAccessToken } from "./accessToken";
import { Observable } from "@apollo/client/utilities/observables/Observable";
import { onError } from "@apollo/client/link/error";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { useAsyncError } from "../ErrorHandling/useAsyncError";
import jwtDecode from "jwt-decode";
import { useAuth0 } from '@auth0/auth0-react';

import { TokenRefreshLink } from "apollo-link-token-refresh";
import {getTokenGenerator} from "../App";

/*
const link = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: isTokenValidOrUndefined,
  fetchAccessToken: () =>
    fetch(`${process.env.REACT_APP_BACKEND_URL}refresh_token`, {
      method: "POST",
      credentials: "include",
    }),
  handleFetch: (accessToken: string) => setAccessToken(accessToken),
});
*/

// const httpLink = new HttpLink({
//   uri: "http://localhost:5000/graphql",
//   credentials: "include",
// });

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: "include", // Otherwise no cookies etc
  fetch: binaryFetch,
});

export function shouldEncode(url: string, options: any) {
  return false;
  if (process.env.NODE_ENV === "development") return false;
  if (!options.method || options.method.toLowerCase() !== "post") return false;
  url = url.split("?")[0].split("#")[0];
  return url.endsWith("/graphql");
}
function encodeTextBody(text: string) {
  let buffer = new Uint8Array(new TextEncoder().encode(text));
  const encodedText = btoa(String.fromCharCode.apply(null, buffer as any)); // btoa(String.fromCharCode.apply(null, buffer));
  return encodedText;
}

function decodeTextBody(text: any) {
  let buffer = new Uint8Array(
    [...atob(text)].map((char) => char.charCodeAt(0))
  );
  const decodedText = new TextDecoder().decode(buffer);
  return decodedText;
}

function binaryFetch(url: string, options: any): Promise<Response> {
  options = options ?? {};
  const isEncoding = shouldEncode(url, options);

  return new Promise(async (resolve, reject) => {
    function response() {
      let keys: any[] = [],
        all: any[] = [],
        headers: any = {},
        header;

      req
        .getAllResponseHeaders()
        .replace(/^(.*?):\s*([\s\S]*?)$/gm, (m, key, value) => {
          keys.push((key = key.toLowerCase()));
          all.push([key, value]);
          header = headers[key];
          headers[key] = header ? `${header},${value}` : value;
          return m;
        });

      return ({
        ok: ((req.status / 200) | 0) == 1,
        status: req.status,
        statusText: req.statusText,
        url: req.responseURL,
        clone: response,
        text: async () =>
          isEncoding
            ? await decodeTextBody(req.responseText) // decoding
            : req.responseText,
        json: async () =>
          JSON.parse(
            isEncoding
              ? await decodeTextBody(req.responseText) // decoding
              : req.responseText
          ),
        blob: () => Promise.resolve(new Blob([req.response])),
        headers: {
          keys: () => keys,
          entries: () => all,
          get: (n: string) => headers[n.toLowerCase()],
          has: (n: string) => n.toLowerCase() in headers,
        },
      } as unknown) as Response;
    }
    let req = new XMLHttpRequest();
    req.open(options.method || "get", url);
    for (let i in options.headers) {
      if (isEncoding && i.toLowerCase() === "content-type") {
        req.setRequestHeader(i, "text/plain; charset=UTF-8");
        req.setRequestHeader("Content-Transfer-Encoding", "base64");
      } else {
        req.setRequestHeader(i, options.headers[i]);
      }
    }
    // Some additional necessary bits of the fetch() standard function
    req.withCredentials = options.credentials == "include";
    req.onload = () => {
      resolve(response());
    };
    req.onerror = reject;
    let body = options.body;
    req.send(isEncoding ? await encodeTextBody(body) : body);
  });
}

/**
 * Indicates the current state of access token expiration. If token not yet expired or user doesn't have a token (guest) true should be returned
 */
function isTokenValidOrUndefined() {
  const token = getAccessToken();
  if (!token) return true;
  try {
    // @ts-ignore TODO type unknown?
    const { exp } = jwtDecode(token);
    return Date.now() < exp * 1000;
  } catch (e) {
    return false;
  }
}

// async function renewTokenIfNeeded() {
//   if (!isTokenValidOrUndefined()) {
//     // token not okay
//     console.log("Problem with token");
//     setAccessToken("");
//     const res = await fetch(
//       `${process.env.REACT_APP_BACKEND_URL}refresh_token`,
//       {
//         method: "POST",
//         credentials: "include",
//       }
//     );
//     console.log(res);
//     const { accessToken } = await res.json();
//     setAccessToken(accessToken);
//   }
// }

const cache = new InMemoryCache({});
// const authLink = new ApolloLink(
//   (operation, forward) =>
//     new Observable((observer) => {
//       let handle: any;
//       Promise.resolve(operation)
//         .then(() => {
//           handle = forward(operation).subscribe({
//             next: observer.next.bind(observer),
//             error: observer.error.bind(observer),
//             complete: observer.complete.bind(observer),
//           });
//         })
//         .catch(observer.error.bind(observer));
//       return () => {
//         if (handle) handle.unsubscribe();
//       };
//     })
// );

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then(async (operation) => {
          const authToken = await getTokenGenerator()();
          if (authToken) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${authToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);
export const useAppApolloClient = () => {
  const throwError = useAsyncError();

  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        throwError(
          new Error(
            `GraphQL Errors:\n${graphQLErrors
              ?.map(
                (e) =>
                  `message:\n${e.message ?? "No message"}\nstack:\n${
                    e.stack ?? "No stack"
                  }`
              )
              .join("\n\n")}\nNetworkError:\nmessage:\n${
              networkError?.message ?? "No info"
            }\nstack:\n${networkError?.stack ?? "No info"}`
          )
        );
      }),
      //link,
      requestLink,
      httpLink,
    ]),
    cache,
  });
};
