import React, { useState } from "react";
import FullScreenDialog from "../FullScreenDialog";
import Form from "../Forms/Form";
import DatePickerWidget from "../Forms/JsonSchemaFormWidgets/DatePickerWidget";
import { useCreatePatientMutation } from "../../generated/graphql";
import { MINIMAL_PATIENTS_QUERY } from "../../queries";
import Calendar from "../Calendar";
import { CircularProgress } from "@material-ui/core";

const FastNewPatientAppointment = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const [createNew, results] = useCreatePatientMutation({
    refetchQueries: [{ query: MINIMAL_PATIENTS_QUERY }],
  });
  const [open, setOpen] = useState(true);

  return (
    <FullScreenDialog
      open={props.open}
      handleClose={() => {
        props.onClose();
        setOpen(true);
      }}
      title={"Voeg patient toe"}
    >
      {results.loading && <CircularProgress />}
      {open && (
        <Form
          uiSchema={{
            bdate: {
              "ui:widget": DatePickerWidget,
            },
          }}
          schema={{
            required: ["name", "fname", "bdate"],
            properties: {
              fname: { type: "string", title: "Voornaam" },
              name: { type: "string", title: "Achternaam" },
              bdate: { type: "string", title: "Geboortedatum" },
            },
          }}
          onSubmit={async ({ formData }: any) => {
            await createNew({
              variables: {
                data: {
                  person: {
                    create: {
                      fname: formData.fname,
                      bdate: formData.bdate,
                      name: formData.name,
                      address: { create: {} },
                    },
                  },
                },
              },
            });
            setOpen(false);
          }}
          buttonName={"Volgende"}
        />
      )}
      {!open && <Calendar />}
    </FullScreenDialog>
  );
};

export default FastNewPatientAppointment;
