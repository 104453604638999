import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card, CardContent, CircularProgress, Paper } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import bplist from "bplist-parser";
import {
  GetPublicAppointmentProposalQuery,
  useGetPublicAppointmentProposalQuery,
  useSelectAppointmentFromProposalMutation,
} from "../../generated/graphql";
import Form from "../../components/Forms/Form";
import RadioSelectWidget from "../../components/Forms/JsonSchemaFormWidgets/RadioSelectWidget";
import { WidgetProps } from "@rjsf/core";
import dateFormat from "dateformat";
import moment from "moment";
const ConfirmAppointment: React.FC<RouteComponentProps<{
  proposalId: string;
}>> = ({ match }) => {
  const { proposalId } = match.params;

  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Card>
        <CardContent>
          <ConfirmAppointmentBody proposalId={proposalId} />
        </CardContent>
      </Card>
    </div>
  );
};

const ConfirmAppointmentBody = ({ proposalId }: { proposalId: string }) => {
  const { data, loading } = useGetPublicAppointmentProposalQuery({
    variables: { proposalId },
  });

  const [
    selectAppointment,
    selectMutResult,
  ] = useSelectAppointmentFromProposalMutation();
  const [options, setOptions] = useState<
    GetPublicAppointmentProposalQuery["getAppointmentInfoPublic"] | undefined
  >();

  useEffect(() => {
    if (data && options !== data.getAppointmentInfoPublic)
      setOptions(data.getAppointmentInfoPublic);
  }, [data, options]);

  if (selectMutResult.loading) {
    return (
      <p>
        Afspraak wordt bevestigd! Even geduld... <CircularProgress />
      </p>
    );
  }
  if (loading) {
    return <CircularProgress />;
  }
  if (!options) {
    return <p>Oeps, pagina niet gevonden</p>;
  }
  let body;
  if (selectMutResult.data) {
    body = (
      <p>
        Afspraak succesvol bevestigd. Een bevestigingsmail is verstuurd naar
        jouw emailadres.
      </p>
    );
  } else {
    body = (
      <>
        <p>
          Bevestig je afspraak door een van de opties te kiezen. Moest geen van
          volgende opties mogelijk zijn, neem dan terug contact op via mail voor
          een nieuw voorstel.
        </p>
        <Form<{ select: string }>
          onSubmit={({ formData }) => {
            selectAppointment({
              variables: { appointmentId: formData.select },
            });
          }}
          uiSchema={{
            select: {
              "ui:widget": (props: WidgetProps) => (
                <RadioSelectWidget
                  {...props}
                  getLabel={(option) =>
                    moment(option!.date).format("D MMMM YYYY [om] hh:mm")
                  }
                  getValue={(option) => option!.id!}
                  providedOptions={options.proposedAppointments!}
                />
              ),
            },
          }}
          buttonName={"Kies deze afspraak"}
          schema={{
            title: "Bevestig afspraak",
            required: ["select"],
            type: "object",
            properties: {
              select: {
                type: "string",
                title: "Mogelijke opties",
              },
            },
          }}
        />
      </>
    );
  }
  return (
    <div>
      <p>
        {`Beste ${options.fname} ${options.name},`}
        <br />
        <br />
      </p>
      {body}
    </div>
  );
};
export default ConfirmAppointment;
