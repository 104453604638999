import React from "react";
import { useGetAppointmentsOnDayQuery } from "../../generated/graphql";
import dateFormat from "dateformat";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import AppointmentCard from "./AppointmentCard";

const TodaysAppointments = () => {
  const { data, loading } = useGetAppointmentsOnDayQuery({
    variables: { date: dateFormat(new Date(), "yyyy-mm-dd") },
  });

  if (!data || loading) return <CircularProgress />;
  return (
    <>
      <Typography variant={"h4"}>
        Vandaag staan volgende afspraken op het programma:
      </Typography>
      <Grid container spacing={2}>
        {data.appointmentsOnDate.map((app) => {
          return (
            <Grid item>
              <AppointmentCard
                // TODO color based on whether the appointment is in the past or the future
                upperSmallTitle={`${dateFormat(
                  app.start,
                  "HH:MM"
                )} - ${dateFormat(app.end, "HH:MM")}`}
                id={app.id}
                title={`${app.patient.person.fname} ${app.patient.person.name}`}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default TodaysAppointments;
