import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import PeopleIcon from "@material-ui/icons/People";
import { Link, LinkProps, useHistory } from "react-router-dom";
import SearchPatientAutoComplete from "../SearchPatientAutoComplete";
import { Button, Grid } from "@material-ui/core";
import LogoutBtn from "../LogoutButton";
import FullScreenDialog from "../FullScreenDialog";
import FastNewPatientAppointment from "../Patient/FastNewPatientAppointment";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      minWidth: "20%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputPaddingRoot: {
      color: "inherit",
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    },
    inputRoot: {
      color: "inherit",
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      color: "inherit",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "20ch",
        "&:focus": {
          width: "30ch",
        },
      },
    },
  })
);
const LinkBehavior = (to: string) =>
  React.forwardRef<any, Omit<LinkProps, "to">>((props, ref) => (
    <Link ref={ref} to={to} {...props} />
  ));
export const Header: React.FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [diagOpen, setDiagOpen] = React.useState(false);
  const history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            Orthodontie Heidbuchel admin
          </Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <SearchPatientAutoComplete
              classes={{
                root: classes.inputPaddingRoot,
                input: classes.inputInput,
                inputRoot: classes.inputRoot,
              }}
              onChange={(id) => history.push(`/patient/${id}`)}
            />
          </div>
          <Grid container spacing={2} style={{ paddingLeft: 9 }}>
            <Grid item>
              <Typography variant="h6" noWrap>
                <FastNewPatientAppointment
                  open={diagOpen}
                  onClose={() => setDiagOpen(false)}
                />
                <Button variant={"contained"} onClick={() => setDiagOpen(true)}>
                  Snel nieuwe afspraak
                </Button>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" noWrap>
                <Button
                  variant={"contained"}
                  onClick={() => history.push("/patients#new_patient")}
                >
                  Nieuwe patiënt
                </Button>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" noWrap>
                <LogoutBtn />
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            component={LinkBehavior("/home")}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Overzicht"} />
          </ListItem>

          <ListItem
            button
            component={LinkBehavior("/patients")}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"Patiënten"} />
          </ListItem>
          <ListItem
            button
            component={LinkBehavior("/calendar")}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary={"Agenda"} />
          </ListItem>
          <ListItem
            button
            component={LinkBehavior("/variables")}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon>
              <KeyboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Variabelen"} />
          </ListItem>
        </List>
        <Divider />
        {/*<List>*/}
        {/*  {["All mail", "Trash", "Spam"].map((text, index) => (*/}
        {/*    <ListItem button key={text}>*/}
        {/*      <ListItemIcon>*/}
        {/*        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary={text} />*/}
        {/*    </ListItem>*/}
        {/*  ))}*/}
        {/*</List>*/}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div style={{ padding: "4em" }}>{props.children}</div>
      </main>
    </div>
  );
};
