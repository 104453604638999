import React, { useEffect, useMemo, useState } from "react";
import MaterialTable from "../MaterialTable";
import { Column } from "material-table";
import {
  useAppointmentsForPatientQuery,
  useUpdateAppointmentByIdMutation,
} from "../../generated/graphql";
import dateFormat from "dateformat";
import { billsPaidState } from "../../App";
import { useSetRecoilState } from "recoil";
import { undefinedOrSetInput } from "../Forms/FormSchemas/PatientSchema";
import { Link } from "react-router-dom";

function calculatePrice(array?: number[]): number | undefined {
  if (!array || array.length < 1) return undefined;
  else return array.reduce((a, b) => a + b, 0);
}
const OverviewForPatient = ({ patientId }: { patientId: string }) => {
  const columns: Column<any>[] = useMemo(() => {
    return [
      {
        title: "Datum afspraak",
        field: "date",
        editable: "never",
        defaultSort: "asc",
        render: ({ date, appointmentId }) => {
          return (
            <Link
              to={`/appointment/${appointmentId}`}
              style={{ textDecoration: "none" }}
            >
              {dateFormat(date, "dd/mm/yy")}
            </Link>
          );
        },
      },
      { title: "Volgnr", field: "counter", type: "numeric" },
      { title: "Risiv/Type", field: "name", editable: "never" },
      { title: "Prijs", field: "price", type: "numeric", editable: "never" },
      {
        title: "Briefnummer",
        field: "paymentId",
        editable: (columnDef, rowData) => rowData.invoiceId,
      },
      {
        title: "Betaald op",
        field: "paid",
        type: "date",
        dateSetting: { format: "dd/MM/yy" },
        editable: (columnDef, rowData) => rowData.invoiceId,
        render: ({ paid }) => (paid ? dateFormat(paid, "dd/mm/yy") : undefined),
      },
    ];
  }, []);
  const setBillsPaid = useSetRecoilState(billsPaidState);
  const { data, loading } = useAppointmentsForPatientQuery({
    variables: { patientId },
  });
  const [updateAppointment, updateResult] = useUpdateAppointmentByIdMutation();
  const [rows, setRows] = useState<any[]>([]);
  useEffect(() => {}, [patientId]);
  useEffect(() => {
    if (data?.appointmentsForPatient) {
      setRows(
        data.appointmentsForPatient
          .filter((app) => !app.ignore && !app.cancelled)
          .map((app) => {
            return {
              appointmentId: app.id,
              invoiceId: app.invoice?.id,
              counter: app.counter,
              date: app.start,
              name: app.invoice?.options?.join(", ") ?? undefined,
              price: calculatePrice(app.invoice?.prices),
              paymentId: app.invoice?.paymentId ?? undefined,
              paid: app.invoice?.paid ?? undefined,
            };
          })
      );
    }
  }, [data]);

  useEffect(() => {
    const firstNotPaid = rows.find((o) => {
      if (o.name) {
        if (!o.paid) {
          return true;
        }
      }
      return false;
    });
    setBillsPaid(!firstNotPaid);
  }, [rows, setBillsPaid]);

  async function handleUpdate(newData: any, oldData: any | undefined) {
    // update invoice information
    console.log(newData);
    let invoice = {};
    if (newData.invoiceId) {
      invoice = {
        invoice: {
          update: {
            paid: undefinedOrSetInput(newData.paid),
            paymentId: undefinedOrSetInput(newData.paymentId),
          },
        },
      };
    }
    await updateAppointment({
      variables: {
        id: oldData.appointmentId,
        data: {
          counter: { set: Number(newData.counter) },
          ...invoice,
        },
      },
    });
    const newRows = rows.filter(
      (o) => o.appointmentId !== oldData.appointmentId
    );
    setRows([...newRows, newData]);
  }

  return (
    <>
      <MaterialTable
        isLoading={loading}
        title={"Overzicht"}
        actions={[]}
        editable={{
          onRowUpdate: async (newData, oldData) =>
            await handleUpdate(newData, oldData),
        }}
        columns={columns}
        data={rows}
      />
    </>
  );
};

export default OverviewForPatient;
