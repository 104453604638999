import React from "react";
export const useAsyncError = () => {
  const [, setError] = React.useState<any>();
  return React.useCallback(
    (e) => {
      setError(() => {
        console.log("Throwing error!");
        throw e;
      });
    },
    [setError]
  );
};
