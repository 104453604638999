import { UiSchema, WidgetProps } from "@rjsf/core";

import { JSONSchema7 } from "json-schema";
import AutoCompleteSelectAndCreateNewDialogWidget from "../JsonSchemaFormWidgets/AutoCompleteSelectAndCreateNewDialogWidget";
import {
  Country,
  CreateSchoolMutationVariables,
  School,
  SchoolsQuery,
  useCreateSchoolMutation,
  useSchoolsQuery,
} from "../../../generated/graphql";
import { haveMatchingIds, selectId } from "./Utils";

export const schoolUiSchema: UiSchema = {};

export interface SchoolFormData {
  name: string;
}
export const schoolSchema: JSONSchema7 = {
  title: "School form",
  description: "A simple form example.",
  type: "object",
  required: ["name"],
  properties: {
    name: {
      type: "string",
      title: "Naam",
    },
  },
};

function mapSchoolFormToCreate(
  formData: SchoolFormData
): CreateSchoolMutationVariables {
  return {
    data: { name: formData.name },
  };
}

export const schoolAutoComplete = (props: WidgetProps) =>
  AutoCompleteSelectAndCreateNewDialogWidget<School, SchoolsQuery>(props, {
    schema: schoolSchema,
    uiSchema: schoolUiSchema,
    queryHook: useSchoolsQuery,
    mutationHook: useCreateSchoolMutation,
    getDataFromQryResult: (data: SchoolsQuery) => data.schools,
    getOptionsDoMatch: haveMatchingIds,
    getValueToSubmit: selectId,
    getTextToShowOptionInList: (option: School) => option.name,
    defaultValueFieldName: "name",
    convertFormDataToMutationVariables: mapSchoolFormToCreate,
  });
