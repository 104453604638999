import { UiSchema } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import {
  mapFormDataToCreatePersonInput,
  mapFormDataToUpdatePersonInput,
  mapPersonQueryToFormData,
  PersonFormData,
  PersonSchema,
  PersonUiSchema,
} from "./PersonSchema";
import { Nullable } from "../../../BasicTypes/Nullable";
import ObjectFieldTemplate from "../Layout/ObjectFieldTemplate";
import {
  CreatePatientMutationOptions,
  PatientQuery,
  UpdatePatientMutationOptions,
} from "../../../generated/graphql";
import { DentistUiSchema } from "./DentistSchema";
import { schoolAutoComplete } from "./SchoolSchema";

export const patientUiSchema: UiSchema = {
  "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  disease: {
    "ui:widget": "textarea",
  },
  schoolId: {
    "ui:widget": schoolAutoComplete,
  },
  ...PersonUiSchema,
  ...DentistUiSchema,
};

export interface PatientFormData extends PersonFormData {
  patientId?: string;
  insRefund?: Nullable<boolean>;
  disease?: string;
  dentistId?: Nullable<string>;
  schoolId?: Nullable<string>;
}

export const patientSchema: JSONSchema7 = {
  type: "object",
  required: PersonSchema.required,
  properties: {
    ...PersonSchema.properties,
    dentistId: {
      type: ["string", "null"],
      title: "Tandarts",
    },
    insRefund: { type: ["boolean", "null"], title: "Terugbetaling nodig" },
    disease: { type: ["string"], title: "Ziektes", default: "" },
    schoolId: { type: ["string", "null"], title: "School" },
  },
};
export function undefinedOrSetInput<T>(
  value?: T | null
): undefined | { set: T } {
  if (!value) return undefined;
  else return { set: value };
}
export function mapFormDataToUpdatePatientInput(
  formData: PatientFormData,
  id: string
): UpdatePatientMutationOptions {
  return {
    variables: {
      id,
      data: {
        insRefund: { set: formData.insRefund ?? undefined },
        disease: undefinedOrSetInput(formData.disease),
        dentist: formData.dentistId
          ? {
              connect: {
                id: formData.dentistId,
              },
            }
          : undefined,
        school: formData.schoolId
          ? { connect: { id: formData.schoolId } }
          : undefined,
        ...mapFormDataToUpdatePersonInput(formData),
      },
    },
  };
}

export function mapFormDataToCreatePatientInput(
  formData: PatientFormData
): CreatePatientMutationOptions {
  return {
    variables: {
      data: {
        insRefund: formData.insRefund ?? undefined,
        disease: formData.disease,
        dentist: formData.dentistId
          ? {
              connect: {
                id: formData.dentistId,
              },
            }
          : undefined,
        school: formData.schoolId
          ? { connect: { id: formData.schoolId } }
          : undefined,
        ...mapFormDataToCreatePersonInput(formData),
      },
    },
  };
}

export function mapPatientQueryToFormData(
  data: PatientQuery
): PatientFormData | null {
  const { patient } = data;
  if (!patient) return null;
  const { person, insRefund, disease, dentist, id, school } = patient;
  // const {person, id, dentist, disease, insRefund} = patient;
  return {
    patientId: id,
    dentistId: dentist?.id,
    // Textarea field for disease cannot be null
    disease: disease ?? undefined,
    insRefund,
    schoolId: school?.id,
    ...mapPersonQueryToFormData(person),
  };
}
