import React from "react";
import { useHistory } from "react-router-dom";
import SearchPatientAutoComplete from "../../components/SearchPatientAutoComplete";
import NewPatient from "../../components/Patient/NewPatient";

const Patients = () => {
  const history = useHistory();
  return (
    <>
      <h1>Patiënten</h1>
      <SearchPatientAutoComplete
        // redirect to patient's page
        onChange={(id) => history.push(`/patient/${id}`)}
      />
      <NewPatient />
    </>
  );
};
export default Patients;
