import React, { useEffect, useMemo, useState } from "react";
import {
  useCreateVariableMutation,
  useDeleteVariablesMutation,
  useUpdateVariableMutation,
  useVariablesQuery,
} from "../../generated/graphql";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import MaterialTable from "../../components/MaterialTable";
import { Column, EditComponentProps } from "material-table";
import Form from "../../components/Forms/Form";
import {
  DIAGNOSIS_QUERY,
  TREATMENT_QUERY,
  VARIABLES_QUERY,
} from "../../queries";

interface MyRow {
  id: string;
  name: string;
  partOf: string;
  values: string[];
}

export const TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX = "Afblijven-"
const ArrayEditPopup = (props: EditComponentProps<any>) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {!open && (
        <Button
          color={"primary"}
          variant={"outlined"}
          onClick={() => setOpen(true)}
          style={{ marginRight: "1em" }}
        >
          Open
        </Button>
      )}

      {!open ? props.value?.join(", ") ?? "" : undefined}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Pas waarden aan</DialogTitle>
        <DialogContent>
          <Form<{ values: string[] }>
            formData={{ values: props.value }}
            onSubmit={({ formData }) => {
              props.onChange(formData.values);
              setOpen(false);
            }}
            schema={{
              type: "object",
              properties: {
                values: {
                  type: "array",
                  title: "Waarden",
                  items: {
                    type: "string",
                  },
                },
              },
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const Variables = () => {
  const { data, loading, refetch } = useVariablesQuery();
  const [updateVariable, updateResult] = useUpdateVariableMutation({
    refetchQueries: [{ query: VARIABLES_QUERY }],
  });
  const [createVariable, createResult] = useCreateVariableMutation({
    refetchQueries: [{ query: VARIABLES_QUERY }],
  });
  const [deleteVariables, deleteResult] = useDeleteVariablesMutation({
    refetchQueries: [{ query: VARIABLES_QUERY }],
  });
  const [debug, setDebug] = useState<any>();
  const columns: Column<any>[] = useMemo(() => {
    return [
      { title: "Naam", field: "name" },
      {
        title: "Waarden",
        field: "values",
        render: (data1: MyRow) => {
          if (!data1.values) return "";
          return data1.values.join(", ");
        },
        editComponent: (props) => <ArrayEditPopup {...props} />,
      },
      {
        title: "Onderdeel van",
        field: "partOf",
        editComponent: (props) => (
          <FormControl
            error={
              !props.value ||
              !["Behandelingsplan", "Diagnose"].includes(props.value)
            }
            style={{ minWidth: "100%" }}
          >
            <InputLabel>Name</InputLabel>
            <Select
              value={props.value}
              onChange={(event) => props.onChange(event.target.value)}
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Behandelingsplan"}>Behandelingsplan</MenuItem>
              <MenuItem value={"Diagnose"}>Diagnose</MenuItem>
            </Select>
            {(!props.value ||
              !["Behandelingsplan", "Diagnose"].includes(props.value)) && (
              <FormHelperText>Error: duid een optie aan</FormHelperText>
            )}
          </FormControl>
        ),
      },
    ];
  }, []);
  const [options, setOptions] = useState<MyRow[]>([]);
  useEffect(() => {
    if (data?.variables) {
      //console.log(data.variables);

      setOptions(
        data.variables.map((o) => ({
          id: o.id,
          values: o.values,
          name: o.name,
          partOf: o.partOf,
        }))
      );
    }
  }, [data]);
  const handleCreate = async (newData: MyRow) => {
    setDebug(newData);
    await createVariable({
      variables: newData,
    });
    await refetch();
  };
  const handleUpdate = async (newData: MyRow, oldData: MyRow) => {
    await updateVariable({
      variables: { ...newData },
    });
    const newVars = options.filter((o) => o.id !== oldData.id);
    setOptions([...newVars, newData]);
  };
  const handleDelete = async (oldData: MyRow) => {
    await deleteVariables({
      variables: { vars: { AND: [{ id: { equals: oldData.id } }] } },
    });
    const filteredVars = options.filter((o) => o.id !== oldData.id);
    setOptions(filteredVars);
  };
  const variableOrdering = Array.from(new Set(options.filter(v=>!v.name.startsWith(TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX)).map(a=>a.partOf))).map(v=>{return {partOf: v, var: options.filter(a=>a.partOf == v).map(a=>{return {id: a.id, name: a.name}})}});
  const uiScheme = Object.fromEntries(variableOrdering.map(a=> {
    return [a.partOf, {
      "ui:options": {
        "addable": false,
        "orderable": true,
        "removable": false,
      }}]
  },))

  const scheme = Object.fromEntries(variableOrdering.map(a => {
    return [a.partOf, {
        "title": a.partOf,
        "type": "array",
        "items": {
          "type": "string",
      },
    }]
    }))

  function formData() {
    //console.log("Executing formadata")
    const ordening = options.filter(o=>o.name.startsWith(TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX)).map(o=>({partOf: o.name.slice(TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX.length), names: o.values}));

    // Find for every Id the corresponding name
    const dataFromVariables = Object.fromEntries(variableOrdering.map(a=>[a.partOf, a.var]))
    //console.log("DataFromVar", dataFromVariables)
    const res = Object.fromEntries(ordening.map(o => {
      const d = dataFromVariables[o.partOf]
      if (!d) return []
      return [o.partOf, o.names.map((id: string)=> {
        const element = d.find(s => s.id == id)
        return element?.name}
      )]
    }));
    // console.log("Result of getOrdening", res)
    return res;
  };
  return (
    <div>
      <h1>Variabelen</h1>
      {/*<p>{JSON.stringify(debug)}</p>*/}
      <MaterialTable
        isLoading={loading}
        title={"Variabelen"}
        columns={columns}
        data={options.filter(v=>!v.name.startsWith(TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX))}
        editable={{
          onRowAdd: async (newData) => await handleCreate(newData),
          onRowUpdate: async (newData, oldData) =>
            await handleUpdate(newData, oldData),
          onRowDelete: (oldData) => handleDelete(oldData),
        }}
      />
      <h3>Springen de variabelen hieronder terug naar de initiële plaats, herlaad even de pagina, want het is waarschijnlijk wel in orde. </h3>
      <Form uiSchema={uiScheme}
            // Make sure to show parts if not already present in array
            // Object.fromEntries(variableOrdering.map(a=>[a.partOf, a.var.map(v=>v.name)]))
            formData={formData()}
            onSubmit={async ({formData})=>{

                  Object.entries(formData).map(([k,v]: [string, any])=>([k, v.map((n:any)=>variableOrdering.find(o=>o.partOf == k)!.var.find(e=>e.name == n)!.id)])).forEach(
                      ([k, v]) => {
                        const data = {
                          name: TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX.concat(k),
                          values: v,
                          partOf: "Extra",
                          id: options.find(o => o.name == TEMPORARY_FIX_ORDENING_VAR_NAME_PREFIX.concat(k))!.id
                        }
                        updateVariable({variables: data})
                      })}}
            schema={{
              'title': "Volgorde van variabelen bijregelen",
              "description": "Opgelet, het is niet mogelijk de naam aan te passen op deze manier. Indien dit toch gebeurt, zal dit geen effect hebben alsook de aanpassing in de volgorde.",
  "type": "object",
              // @ts-ignore
  "properties": {
    ...scheme
  }
}}/>
    </div>
  );
};

export default Variables;
