import React, { useEffect } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { WidgetProps, Widget } from "@rjsf/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import moment from "moment";
const DatePickerWidget: Widget = (props: WidgetProps) => {
  const [
    selectedDate,
    setSelectedDate,
  ] = React.useState<null | MaterialUiPickersDate>(null);

  useEffect(() => {
    // This is necessary to reset field when form submits; otherwise value does not disappear!
    // if (selectedDate?.toISOString() !== props.value) {
    //   setSelectedDate(props.value);
    //   return;
    // }
    if (props.value) {
      if (selectedDate?.toISOString() === props.value) {
        // No difference from props ==> no change
        return;
      }
      // selected date is null or difference
      setSelectedDate(moment(props.value));
      return;
    }
    // Prop is null or undefined
    if (props.value === undefined) {
      setSelectedDate(null);
    }
  }, [props.value, selectedDate]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);
    console.log("handledatechange", date);
    // MUST BE A STRING
    props.onChange(date?.toISOString());
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        // disableToolbar
        required={props.required}
        // variant="inline"
        format={props.uiSchema["ui:dateformat"] ?? "DD/MM/yyyy"}
        id={props.id}
        label={props.label}
        value={selectedDate}
        onChange={handleDateChange}
        helperText={""}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
export default DatePickerWidget;
