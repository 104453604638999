import { WidgetProps } from "@rjsf/core";
import React, { useEffect } from "react";
import {
  PatientsMinimalInfoQuery,
  usePatientsMinimalInfoQuery,
} from "../../../generated/graphql";
import Autocomplete from "@material-ui/lab/Autocomplete";
import formatDate from "dateformat";
import { CircularProgress, TextField } from "@material-ui/core";

export const AutoCompleteSearchPatientWidget = (props: WidgetProps) => {
  // const throwError = useAsyncError();
  const [options, setOptions] = React.useState<
    PatientsMinimalInfoQuery["patients"]
  >();
  const { data, loading, error } = usePatientsMinimalInfoQuery();

  // useEffect(() => {
  //   if (error) {
  //     throwError(error);
  //   }
  // }, [error]);

  useEffect(() => {
    if (data) {
      setOptions(data.patients);
    }
  }, [data]);
  return (
    <Autocomplete
      id={`autocomplete-search-patient-${props.id}`}
      placeholder={props.placeholder}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return `${formatDate(option.person.bdate, "dd/mm/yyyy")} ${
          option.person.fname
        } ${option.person.name}`;
      }}
      onChange={(e, value) => {
        if (value) props.onChange(value.id);
      }}
      value={options?.find((opt) => opt.id === props.value) || null}
      options={options ?? []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          variant={"standard"}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
