import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
    GetAppointmentsBetweenTwoDatesQuery,
    useCancelAppointmentMutation, useGetAppointmentProposalQuery, useRemoveAppointmentProposalMutation,
    useSelectAppointmentFromProposalMutation,
    useSendProposalMailMutation,
} from "../../generated/graphql";
import { ArrayElement } from "../../BasicTypes/basics";
import { Link } from "react-router-dom";
import SendConfirmationButton from "./SendConfirmationButton";
import {APPOINTMENT_PROPOSALS, APPOINTMENTS_BETWEEN_TWO_DATES} from "../../queries";
import { endDate, startDate } from "../Calendar";

export const CalendarEventModal = (props: {
  event?: ArrayElement<
    GetAppointmentsBetweenTwoDatesQuery["appointmentsBetweenTwoDates"]
  >;
  open: boolean;
  handleClose: () => void;
}) => {
  const { event, open } = props;
  const [sendProposalMail, proposalMailResult] = useSendProposalMailMutation();
  const [cancelAppointment, cancellationResult] = useCancelAppointmentMutation({
    refetchQueries: [
      {
        query: APPOINTMENTS_BETWEEN_TWO_DATES,
        variables: { startDate: startDate.toDate(), endDate: endDate.toDate() },
      },
    ],
  });
  const [removeAppProposal] = useRemoveAppointmentProposalMutation({
      refetchQueries: [
          {query: APPOINTMENT_PROPOSALS},
          {
              query: APPOINTMENTS_BETWEEN_TWO_DATES,
              variables: { startDate: startDate.toDate(), endDate: endDate.toDate() },
          },

      ]
  });
  const [selectAppointment] = useSelectAppointmentFromProposalMutation({
    refetchQueries: [
      {
        query: APPOINTMENTS_BETWEEN_TWO_DATES,
        variables: { startDate: startDate.toDate(), endDate: endDate.toDate() },
      },
    ],
  });
  const appointmentProposalQry = useGetAppointmentProposalQuery({variables: {id: event?.appointmentProposal?.id?? ""}});
  let body = event?.id ? (
    <SendConfirmationButton appointmentId={event?.id} />
  ) : (
    "Geen gegevens"
  );
  if (event?.appointmentProposal) {
    body = (
      <>
        <Typography>Voorstel</Typography>
        <Typography>
          {(appointmentProposalQry.data?.appointmentProposal?.appointments?? []).map(item => <>{`${new Date(item.start).toLocaleString()}`}<br/></>)}
        </Typography>
        <Typography>
          Wil je dit voorstel ({new Date(event.start).toLocaleString()}) selecteren als permanente afspraak?
        </Typography>
        <Button
          color="primary"
          onClick={async () => {
            if (!event) return;
            await selectAppointment({ variables: { appointmentId: event.id } });
            props.handleClose();
          }}
        >
          Selecteer
        </Button>
        <Button
          onClick={async (e) => {
            e.preventDefault();
            if (event?.appointmentProposal) {
              await sendProposalMail({
                variables: { proposalId: event.appointmentProposal.id },
              });
            }
          }}
        >
          {proposalMailResult.loading
            ? "Versturen..."
            : proposalMailResult.data
            ? "Verstuurd!"
            : "Verstuur voorstel"}
        </Button>
      </>
    );
  }
  return (
    <Dialog open={open} onClose={props.handleClose}>
      <DialogTitle>{`${event?.calendarTitle}`}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        {event?.appointmentProposal && (
          <Button onClick={() => {
              removeAppProposal({variables: {proposalId: event!.appointmentProposal!.id}}).then(r => props.handleClose());
          }}>
            Verwijder volledig voorstel
          </Button>
        )}
        <Link to={`/appointment/${event?.id}`}>Ga naar afspraak</Link>
        {!event?.appointmentProposal && (
          <Button
            color={"secondary"}
            onClick={async () => {
              if (event?.id) {
                await cancelAppointment({
                  variables: {
                    id: event.id,
                  },
                });
              }
              props.handleClose();
            }}
          >
            Annuleer deze afspraak!
          </Button>
        )}
        <Button onClick={props.handleClose}>Sluit</Button>
      </DialogActions>
    </Dialog>
  );
};
