import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import DoneIcon from "@material-ui/icons/Done";
import dateFormat from "dateformat";
import { useCreateBoxIdMutation } from "../../generated/graphql";
import { useRecoilState } from "recoil";
import { billsPaidState } from "../../App";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    borderRadius: spacing(2), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    maxWidth: "100%",
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  media: {
    width: "88%",
    paddingBottom: "48%",
  },
  expand: {
    // transform: "rotate(0deg)",
    marginLeft: "auto",
  },
}));

interface Props {
  patientData?: any;
  children?: React.ReactNode;
}

const GridEnd = (props: React.PropsWithChildren<any>) => {
  return (
    <Grid item sm={4} style={{ alignItems: "flex-end" }}>
      {props.children}
    </Grid>
  );
};

export const PersonalCard = ({ patientData, children }: Props) => {
  const [createBoxId, response] = useCreateBoxIdMutation();
  const [boxId, setBoxId] = useState<string | null>(null);
  const [allBillsPaid] = useRecoilState(billsPaidState);
  const classes = useStyles();

  const mailSubjectParents = encodeURIComponent(`Verslag consultatie orthodontie ${dateFormat(new Date(), "dd/mm/yyyy")}`)
  const mailBodyParents = encodeURIComponent(`Beste ouder,\n\nVandaag zag ik uw zoon/dochter op consultatie orthodontie. Ik maakte volgende bevindingen:\n\n\nVriendelijke groet,\n\nRuth Heidbuchel\nSpecialist in de orthodontie\n\nKardinaal Mercierlaan 6/72\n3001 Heverlee`)
  const mailSubject = encodeURIComponent(`Orthodontisch verslag en OPG van ${patientData?.person?.fname} ${patientData?.person?.name} (°${dateFormat(patientData?.person?.bdate, "dd/mm/yyyy")})`)
  useEffect(() => {
    if (patientData?.boxId) {
      setBoxId(patientData.boxId);
    } else {
      setBoxId(null);
    }
    return () => setBoxId(null);
  }, [patientData]);
  return (
    <>
      <h2>Basis info</h2>
      <Card className={classes.root}>
        <Grid container>
          <Grid item sm={5}>
            <CardMedia
              className={classes.media}
              image="/boss.svg"
              title="Contemplative Reptile"
            />
          </Grid>
          <Grid item sm={7}>
            <CardContent>
              {patientData ? (
                <Grid container>
                  <Grid item sm={8}>
                    <strong>Naam:</strong>
                  </Grid>
                  <GridEnd>
                    {`${patientData.person.name} ${patientData.person.fname}`}
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>Geboortedatum:</strong>
                  </Grid>
                  <GridEnd>
                    {dateFormat(patientData.person.bdate, "dd/mm/yyyy")}
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>Taal:</strong>
                  </Grid>
                  <GridEnd>
                    {`${patientData.person.language ?? "Geen informatie"}`}
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>School:</strong>
                  </Grid>
                  <GridEnd>
                    {`${patientData.school?.name ?? "Geen school gevonden"}`}
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>Doosnr:</strong>
                  </Grid>
                  <GridEnd>
                    {!boxId ? (
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        size={"small"}
                        onClick={async () =>
                          await createBoxId({
                            variables: { patientId: patientData.id },
                          })
                        }
                      >
                        Genereer doosnummer
                      </Button>
                    ) : (
                      boxId
                    )}
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>Betalingen:</strong>
                  </Grid>
                  <GridEnd>
                    {allBillsPaid ? (
                      <DoneIcon style={{ color: "green" }} />
                    ) : (
                      <PriorityHighIcon style={{ color: "red" }} />
                    )}
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>Verslag naar tandarts</strong>
                  </Grid>
                  <GridEnd>
                    <a href={`mailto:${(patientData?.dentist?.person?.emails ? patientData.dentist.person.emails[0]?? 'geen-email-opgeslagen': 'geen-tandarts')}?subject=${mailSubject}`}>Stuur mail</a>
                  </GridEnd>
                  <Grid item sm={8}>
                    <strong>Verslag naar ouders</strong>
                  </Grid>
                  <GridEnd>
                    <a href={`mailto:${(patientData?.person?.emails ? patientData.person.emails.join(',')?? 'geen-email-opgeslagen' : 'geen data')}?subject=${mailSubjectParents}&body=${mailBodyParents}`}>Stuur mail</a>
                  </GridEnd>
                </Grid>
              ) : (
                <p>Nog geen gegevens</p>
              )}
            </CardContent>
          </Grid>
        </Grid>
        <CardActions>{children}</CardActions>
      </Card>
    </>
  );
};
