import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGoogleApiQuery, useMeQuery } from "../../generated/graphql";

const Test = (props: RouteComponentProps) => {
  const { data } = useGoogleApiQuery();
  const { data: userData } = useMeQuery();
  const urlParams = new URLSearchParams(props.location.search);
  const code = urlParams.get("code");
  const [info, setInfo] = useState("Wachtend op resultaat...");
  useEffect(() => {
    if (code && userData?.me?.id) {
      fetch(
        `http://localhost:5000/oauthcallback?code=${code}&userId=${userData.me.id}`
      ).then((res) => {
        if (res.status === 400) setInfo("Failed!");
        else setInfo("Koppeling gelukt!");
      });
    }
  }, [userData]);

  if (!code)
    return (
      <div>
        <h1>Should redirect to google api</h1>
        <p>
          <a href={data?.generateGoogleCalendarUrl || ""}>
            {data?.generateGoogleCalendarUrl}
          </a>
        </p>
      </div>
    );
  return <p>{info}</p>;
};
export default Test;
