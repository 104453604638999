import {useState} from "react";

export default function useDownloadFileHook() {
    const [name, setNameAndExt] = useState("unknown")
    function downloadFile(url: string){
        const link = document.createElement('a');
        link.download = name
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return {downloadFile, setNameAndExt}
}