import React, { useEffect, useState } from "react";
import Autocomplete, {
  AutocompleteClassKey,
} from "@material-ui/lab/Autocomplete";
import { CircularProgress, TextField } from "@material-ui/core";
import formatDate from "dateformat";
import {
  PatientsMinimalInfoQuery,
  usePatientsMinimalInfoQuery,
} from "../generated/graphql";

const SearchPatientAutoComplete = (props: {
  onChange: (id: string) => void;
  classes?: Partial<Record<AutocompleteClassKey, string>> | undefined;
}) => {
  // const throwError = useAsyncError();
  const [options, setOptions] = React.useState<
    PatientsMinimalInfoQuery["patients"]
  >();
  const { data, loading, error } = usePatientsMinimalInfoQuery();
  // TODO be able to switch but don't save the value
  const [value, setValue] = useState<any>(null);
  // useEffect(() => {
  //   if (error) {
  //     throwError(error);
  //   }
  // }, [error]);

  useEffect(() => {
    if (data) {
      setOptions(data.patients);
    }
  }, [data]);
  return (
    <Autocomplete
      id="asynchronous-demo"
      classes={props.classes}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        if (!option) return "Geen gegevens";
        return `${formatDate(option.person.bdate, "dd/mm/yyyy")} ${
          option.person.fname
        } ${option.person.name}`;
      }}
      onChange={(e, value) => {
        if (value) props.onChange(value.id);
      }}
      value={value}
      options={options ?? []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"standard"}
          label="Zoek patiënt..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchPatientAutoComplete;
